// ConfigCareer.tsx

export const baseUrlAPI = () => {
  return process.env.NODE_ENV === 'development'
    ? 'https://api.dev.miraeasset.io/v1/career'
    : 'https://api.prd.miraeasset.co.id/v1/career';
};

export const urlWebCareer = () => {
  return process.env.NODE_ENV === 'development'
    ? 'https://career.dev.miraeasset.io/detail-career/'
    : 'https://career.miraeasset.io/detail-career/';
};

  export const apiKeyTinyMCE: string = 'c2y4nyl33tqw53ykycmcrl9o66vx2lt21vgy3u74z4gnyafb';
