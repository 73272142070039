import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './management.scss'
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import { Select as SelectMui } from '@mui/material';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}


export const RoleManagement = (props: IHeaderProps) => {

    const initialFormData = {
        id: "0",
        nameRole: "",
        newRole: ""
    }

    let history = useHistory();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState<any>(10);
    const [totalPage, setTotalPage] = useState(1);
    const [modalRoleAdd, setModalRoleAdd] = useState(false);
    const [modalRoleDelete, setModalRoleDelete] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [dataRoleManagement, setDataRoleManagement] = useState([]);
    const [loading, setLoading] = useState(false)
    const [formEdit, setFormEdit] = useState(false)
    const [nameDelete, setNameDelete] = useState("")
    const [valSearch, setValSearch] = useState<String>("");


    useEffect(() => {
        getData()
    }, [page, size, totalPage, valSearch]);

    // data menu management
    const getData = () => {
        let pageReal = page
        var pageCount = pageReal - 1
        var validateParamSearch = valSearch == "" ? `` : `&search=${valSearch}`
        axios.get(`services/uaadocservice/api/authority/list?page=${pageCount}&limit=${size}${validateParamSearch}`)
            .then(res => {
                setDataRoleManagement(res.data.data.data)
                setTotalPage(res.data.data.totalPage)
            }).catch(err => {
                toast.error(translate(`${err.response.data.message}`));
            })
    }

    const postData = () => {
        setLoading(true)
        let dataFormData = {
            name: formData.nameRole
        }
        axios.post('services/uaadocservice/api/authority/add', dataFormData).then(res => {
            toast.success('Success Add Data Role', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setModalRoleAdd(false)
            setLoading(false)
            getData()
        }).catch(err => {
            setModalRoleAdd(false)
            toast.error(translate(`${err.response.data.message}`));
            setLoading(false)
        })
        setFormData({ ...formData, nameRole: "", newRole: "" })
    }

    const getDetail = (itemName) => {
        axios.get(`services/uaadocservice/api/authority/${itemName}`).then(res => {
            var response = res.data.data;
            setFormData({
                ...formData,
                nameRole: response.name,
            })
            handleModal()
            setFormEdit(!formEdit)
        }).catch(err => {
            console.log(err)
        })
    }

    const postEditData = () => {
        setLoading(true)
        let editFormData = {
            oldName: formData.nameRole,
            newName: formData.newRole,
        }
        axios.put(`services/uaadocservice/api/authority/update`, editFormData).then(res => {
            toast.success('Success Edit Data Role', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            handleModal()
            getData()
            setFormEdit(!formEdit)
            setLoading(false)
        }).catch(err => {
            toast.error('Failed Edit Data Role', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            handleModal()
            getData()
            setFormEdit(!formEdit)
            setLoading(false)
        })
        setFormData({ ...formData, nameRole: "", newRole: "" })
    }

    const deleteData = () => {
        setLoading(true)
        axios.delete(`services/uaadocservice/api/authority/delete?name=${nameDelete}`).then(res => {
            toast.success('Success Delete Role', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setNameDelete("")
            setLoading(false)
            deleteHandleClose()
            getData()
        }).catch(err => {
            toast.error('Failed Delete Menu', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setNameDelete("")
            setLoading(false)
            deleteHandleClose()
            getData()
        })
    }

    const handleSearch = (searchVal) => {
        setPage(1)
        setValSearch(searchVal)
    }

    // modal 
    const handleModal = () => {
        setModalRoleAdd(!modalRoleAdd);
    }

    const handleClose = () => {
        setModalRoleAdd(false)
        setFormEdit(false)
        setFormData({ ...formData, nameRole: "", newRole: "" })
    }

    const deleteHandleModal = () => {
        setModalRoleDelete(!modalRoleDelete);
    }

    const deleteHandleClose = () => {
        setModalRoleDelete(false)
    };

    // pagination table
    const handleChange = (event) => {
        setPage(1)
        setSize(parseInt(event.target.value));
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <div className="px-5 pt-4 font-family-spoqa" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="mb-0 font-size-28">Role Management</h1>
                    </div>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="mb-0 font-size-14">Mirae Asset Sekuritas Role Management</h1>
                    </div>
                </div>
            </div>
            <div className="row my-3">
                <div className="col-12 col-md-5 col-lg-5 px-2">
                    <div className="d-flex" style={{ flexGrow: 10 }}>
                        <div className="app-search w-100 py-0 bg-white">
                            <div className="position-relative">
                                <input type="text"
                                    className="form-control bg-white py-3"
                                    placeholder="Search Role Management"
                                    onChange={(e) => {
                                        handleSearch(e.target.value)
                                    }} onKeyPress={(e) => {
                                        console.log('e.key', e.key)
                                    }}
                                />
                                <span className="bx bx-search-alt my-0"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-5 col-lg-5 px-2">
                </div>
                <div className="col-12 col-md-5 col-lg-2 px-2">
                    <div className='btn btn-primary w-100 py-2'
                        style={{ background: '#F37F26' }}
                        onClick={(data) => handleModal()}>
                        <i className="bx bx-plus"></i>
                        <span>Add New Role</span>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive mt-4">
                        <table className="table table-striped table-centered  mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th>Role Name</th>
                                    <th className='fix'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataRoleManagement.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ wordBreak: 'break-all' }}>{item?.name}</td>
                                                <td style={{ wordBreak: 'break-all', width: '15%' }} className='fix'>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                        <button
                                                            className='btn mr-2'
                                                            style={{ backgroundColor: '#B1C5F6', borderColor: '#B1C5F6', color: '#3267E3' }}
                                                            onClick={() => getDetail(item?.name)}
                                                        >
                                                            <FontAwesomeIcon icon="pencil-alt" />{' '}Edit
                                                        </button>
                                                        <button
                                                            className='btn mr-2'
                                                            style={{ backgroundColor: '#EEB4B0', borderColor: '#EEB4B0', color: '#CB3A31' }}
                                                            onClick={() => {
                                                                deleteHandleModal(),
                                                                    setNameDelete(item?.name)
                                                            }}>
                                                            <FontAwesomeIcon icon="trash" />{' '}Delete
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                    <div style={{ display: 'flex' }}>
                        <span className="mt-3 mx-2">
                            Show
                        </span>
                        <span>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>

                                <SelectMui
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={size}
                                    onChange={handleChange}
                                    label="Limit"
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                </SelectMui>
                            </FormControl>
                        </span>
                        <span className="mt-3 mx-2">
                            Entries
                        </span>
                    </div>
                </div>
                <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
                    <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
                </div>
            </div>
            <Modal className="font-family-spoqa" isOpen={modalRoleAdd} toggle={handleClose} size="md" centered={true}>
                <ModalHeader toggle={handleClose}>Form Role Management</ModalHeader>
                <ModalBody>
                    <div className="m-3">
                        <div className="mb-2">
                            {!formEdit ? 'Role Name' : 'Old Role'}
                        </div>
                        <FormGroup>
                            <input
                                className="form-control"
                                value={formData.nameRole}
                                placeholder="Type Role Name Here"
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        nameRole: e.target.value
                                    })}
                                disabled={!formEdit ? false : true}
                            />
                        </FormGroup>
                        <div className="mb-2">
                            {formEdit ? 'New Role' : ''}
                        </div>
                        <FormGroup>
                            {!formEdit ? <></> :
                                <input
                                    className="form-control"
                                    value={formData.newRole}
                                    placeholder="Type Role Name Here"
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            newRole: e.target.value
                                        })}
                                    disabled={false}
                                />
                            }
                        </FormGroup>
                    </div>
                </ModalBody >
                <ModalFooter>
                    <Button
                        style={{ background: '#F37F26', borderColor: '#F37F26' }}
                        disabled={!formData.nameRole || loading}
                        onClick={() => !formEdit ? postData() : postEditData()}
                    >
                        <span className='mr-1'>
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                            {!formEdit ? 'Save' : 'Update'}
                        </span>
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal className="font-family-spoqa" isOpen={modalRoleDelete} toggle={deleteHandleClose} size="md" style={{ marginTop: '15%' }}>
                <ModalHeader toggle={deleteHandleClose}>
                    <div className="w-100">
                        <h2 className="">Delete Confirmation</h2>
                        <h3 style={{ fontSize: '12px' }}>{`Are you sure to delete ?`}</h3>
                    </div>
                </ModalHeader>
                <div className="my-4">
                    <Row className='w-100'>
                        <Col lg={12} className="text-right">
                            <Button color="secondary" onClick={deleteHandleClose} className="mr-2">
                                <Translate contentKey="entity.action.cancel">Cancel</Translate>
                            </Button>
                            <Button color="success px-4"
                                onClick={() => deleteData()}
                                disabled={loading}>
                                {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>} Yes
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});


const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(RoleManagement);
