import React, { useEffect, useLayoutEffect, useState } from 'react';
import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';
// import useForm from "./useForm";
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Label, Spinner } from 'reactstrap';
import moment from 'moment-timezone';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { translate } from 'react-jhipster';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from "formik";
import Resizer from "react-image-file-resizer";

const categoryActive = { padding: "5px", marginRight: "10px", color: "#F37F26", borderBottom: "2px solid #F37F26" };
const categoryInactive = { padding: "5px", marginRight: "10px", color: "#9E9E9E" };

export interface Props {
  match?: any
}

type StatFilterProps = {
    cState: string;
    ownState: string;
    label: string;
}

export const LinktreeDetail = (props: Props) => {

  let history = useHistory();
  const [category, setCategory] = useState<any>(0)
  const [listDocumentHistoriDetail, setListDocumentHistoriDetail] = useState<any>([])

  const [tabIcon, setTabIcon] = useState("")
  const [titleIcon, setTitleIcon] = useState("")
  const [bgIcon, setBgIcon] = useState("")
  const [contentIcon, setContentIcon] = useState("")

  const [tabIconFile, setTabIconFile] = useState(null)
  const [titleIconFile, setTitleIconFile] = useState(null)
  const [bgIconFile, setBgIconFile] = useState(null)
  const [contentIconFile, setContentIconFile] = useState(null)

  const [isDraftStat, setDraftStat] = useState(false)

  const [borderRad, setBorderRad] = useState("3rem");

  const [isLoadingExisting, setLoadingExisting] = useState(false);
  const [isLoadingDrafting, setLoadingDrafting] = useState(false)
  const [isLoadingPublish, setLoadingPublish] = useState(false);

  const [basicInfo, setBasicInfo] = useState<any>({
    originId: props.match.params.id,
    id: null,
    title: null,
    subTitle: null,
    tabTitle: null,
    shareCaption: null,
    borderRad: null,
    bgColor: null,
    titleColor: null,
    linkTextColor: null,
    linkColor: null,
    links: [],
    isDraft: null
  });

  useLayoutEffect(() => {
    getPageDetail(props.match.params.id)
  }, []);

  // useEffect(() => { }, [basicInfo]);

  const urltoFile = (dataurl, filename) => {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
  }

  // const urltoFile = (url, filename, mimeType) => {
  //     return (fetch(url)
  //         .then(function(res){return res.arrayBuffer();})
  //         .then(function(buf){return new File([buf], filename,{type:mimeType});})
  //     );
  // }
    
  const getPageDetail = async (id) => {
    try {
      setLoadingExisting(true);
      let res = await axios.get(`/services/linkservice/api/v1/linktree/page-data?pageId=${id}`)
      let resObj = await JSON.parse(res.data?.texts);

      // basicInfo.id = resObj["id"];
      // basicInfo.title = resObj["title"];
      // basicInfo.subTitle = resObj["subTitle"];
      // basicInfo.tabTitle = resObj["tabTitle"];
      // basicInfo.shareCaption = resObj["shareCaption"];

      // basicInfo.bgColor = resObj["bgColor"];
      // basicInfo.linkColor = resObj["linkColor"];
      // basicInfo.titleColor = resObj["titleColor"];
      // basicInfo.linkTextColor = resObj["linkTextColor"];

      [
        "id", "title", "subTitle", "tabTitle", "shareCaption", 
        "bgColor", "linkColor", "titleColor", "linkTextColor"
      ].map(v => {
        basicInfo[v] = (((resObj[v] == null) || (resObj[v] == "null")) ? "" : resObj[v]);
      })

      basicInfo.borderRad = resObj["borderRad"];
      setBorderRad(resObj["borderRad"]);

      basicInfo.links = JSON.parse(resObj["links"]);
      basicInfo.isDraft = resObj["isDraft"];
      setDraftStat(resObj["isDraft"]=='t');
      
      // 'data:image/png;base64,'
      setTabIcon(res.data?.icon_tab == null ? "" : ("data:image/png;base64," + res.data?.icon_tab));
      setTitleIcon(res.data?.icon_title == null ? "" : ("data:image/png;base64," + res.data?.icon_title));
      setBgIcon(res.data?.icon_bg == null ? "" : ("data:image/png;base64," + res.data?.icon_bg));

      setContentIcon(res.data?.icon_content_img == null ? "" : ("data:image/png;base64," + res.data?.icon_content_img))

      setLoadingExisting(false);
    } catch(err) {
      console.log(err)
      setLoadingExisting(false);
    } 
  }

  const draftingLinktreePage = (pid, stat) => {
    setLoadingDrafting(true);

    axios.get(`/services/linkservice/api/v1/linktree/drafting?pageId=` + pid + "&isDraft=" + ((stat=="t") ? "f" : "t"))
      .then(res => {
        toast.success("Saved!");
        setLoadingDrafting(false);
      }).catch(err => {
        toast.error(err?.response?.data);
        setLoadingDrafting(false);
      })
  }

  const blobToBase64 = (url) => {
    return new Promise(async (resolve, _) => {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blob);
      fileReader.onloadend = function(){
        resolve(fileReader.result); // Here is the base64 string
      }
    });
  };

  function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
  }

  const resizeFile = (file, size, isPng) => new Promise(resolve => {
  Resizer.imageFileResizer(file, size, size, isPng ? 'PNG' : 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      }, 'base64' );
  });

  const saveLinktreePageDetail = (values, isDraft) => {
    if (isDraft == 't') { setLoadingDrafting(true); } else { setLoadingPublish(true); }
    let data = new FormData();
    Object.keys(values).map(k => {
      if((k != 'links') && (k != 'borderRad') && (k != 'isDraft')) { data.append(k, values[k]); }      
    });

    data.append('borderRad', borderRad);

    data.append('isDraft', isDraft);

    if (tabIconFile != null) {data.append("tabIcon", tabIconFile); }
    if (titleIconFile != null) {data.append("titleIcon", titleIconFile); }
    if (bgIconFile != null) {data.append("bgIcon", bgIconFile); }
    if (contentIconFile != null) {data.append("contentIcon", contentIconFile); }

    data.append("links", JSON.stringify(values['links']));

    axios.post(`/services/linkservice/api/v1/linktree/insert`, data, {headers: {"Content-Type": "application/json"}})
      .then(res => {
        toast.success("Saved!");
        if ((props.match.params.id == "new") || (props.match.params.id != values['id'])) {
          window.location.href = ("/linktree/detail/" + values['id']);
        }
        setLoadingDrafting(false); setLoadingPublish(false);
        setDraftStat(isDraft=='t');
      }).catch(err => {
        toast.error(err?.response?.data);
        setLoadingDrafting(false); setLoadingPublish(false);
      })

  }

  const StatFilterNode = (props: StatFilterProps) => (
      <>
          {React.createElement('input',{
              type: 'radio',
              name: 'stat_filter',
              checked: (props.cState == props.ownState),
              onClick: () => { if (props.cState != props.ownState) {
                  setBorderRad(props.ownState);
              } }
          })}
          <div style={{width: '5px'}} />
          <span>{props.label}</span>
          <div style={{width: '10px'}} />
      </>
  )

  const ImageHolder = (props) => {
    return <>
      <div style={{marginBottom: "12px"}} className={"d-show"}>
          <Col className={"boxUpload uploadPlaceHolder d-flex align-items-center"}
               style={{position: "relative"}}>
              <Row style={{margin: "auto"}}>
                  <div className={"text-center"}><b>{'Upload ' + props.type + ' Image'}</b></div>
                  <div className={"text-center"}>PNG and JPG</div>
              </Row>
              <Input 
                name={props.type + "Image"} className={"uploadField"} 
                type="file" accept="image/*"
                onChange={async (e) => {
                  let rawFile = await e.target.files[0];
                  let isPng = await (rawFile?.type == "image/png");
                  let cFile = await dataURLtoFile(await resizeFile(rawFile, ((props.type == "Tab") ? 300 : 1100), isPng), "img_" + props.type + (isPng ? ".png" : ".jpg"));
                  // let cFile = e.target.files[0];

                  if (cFile.size > 1000000) {
                    toast.error("Exceed maximum file size 1MB");
                    return
                  }

                  let b64img = await (await blobToBase64(URL.createObjectURL(cFile)) as string);

                  if (props.type == "Tab") {
                    setTabIcon(b64img)
                    setTabIconFile(cFile)
                  } else if (props.type == "Title") {
                    setTitleIcon(b64img);
                    setTitleIconFile(cFile);
                  } else if (props.type == "Background") {
                    setBgIcon(b64img);
                    setBgIconFile(cFile);
                  } else {
                    setContentIcon(b64img);
                    setContentIconFile(cFile);
                  }

                 }}
                 onClick={(event)=> {}}/>
          </Col>
          {false &&
              <div style={{marginTop: "8px"}}><span
                  style={{color: "#D8382E"}}>{"error"}</span></div>
          }
      </div>
    </>
  }

  return (
    <Formik
      initialValues={basicInfo}
      onSubmit={async (values) => {
        // saveLinktreePageDetail(values);
      }}
    >{(formikProps) => (<Form>

    <div className="px-5 pt-4 font-family-spoqa scrollbar" style={{ height: '100%', width: '100%' }}>
      <button className="btn px-0 mb-4" onClick={() => {window.location.href = "/linktree"; }}>
        <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
      </button>
      <div className="row mb-4">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between p-1">
            <div style={{display: 'flex'}} >
              <h1 className="mb-0 font-size-28">{((props.match.params.id == 'new') ? 'Create' : 'Edit') + ' Linktree Page'}</h1>
              {isLoadingExisting && <span style={{marginLeft: '20px'}} >
                <Spinner color='orange' size="sm" className='mr-1' />
                {'loading existing data...'}
              </span>}
              {isDraftStat && <span style={{marginLeft: '20px', color: 'red'}} >
                {'*DRAFT'}
              </span>}
            </div>
            
            <div>
              <button className="btn btn-info ml-3 mr-1" onClick={async (e) => {
                if (isDraftStat) {
                  let resp = await axios.get(("https://dev.masi.id/" + formikProps.values["id"]), {
                    headers: {
                      "Content-Type": "application/json",
                      "isDraftHeader": 't'
                    },
                  });

                  let blob = await new Blob([resp.data], { type: "text/html" });
                  let respUrl = await window.URL.createObjectURL(blob);
                  window.location.href = respUrl;
                  // window.open(, '_blank');
                } else {                  
                  window.open(("https://dev.masi.id/" + formikProps.values["id"]), '_blank');
                }
              }} disabled={isLoadingExisting}>
                {'View Page'}
              </button>


              <button className="btn btn-primary ml-1" onClick={(e) => {
                saveLinktreePageDetail(formikProps.values, 't');
              }} disabled={isLoadingDrafting || !/^[A-Za-z0-9\-]+$/.test(formikProps.values.id) || (formikProps.values.id == null)}>
                {isLoadingDrafting && <Spinner color='#fff' size="sm" className='mr-1' />}<><i className="uil uil-backward" style={{marginRight: '5px'}} ></i>{'Save to Draft'}</>
              </button>


              <button className="btn btn-primary ml-1" onClick={(e) => {
                saveLinktreePageDetail(formikProps.values, 'f');
              }} disabled={isLoadingPublish || !/^[A-Za-z0-9\-]+$/.test(formikProps.values.id) || (formikProps.values.id == null)}>
                {isLoadingPublish && <Spinner color='#fff' size="sm" className='mr-1' />}<><i className="uil uil-play" style={{marginRight: '5px'}} ></i>{'Publish'}</>
              </button>

            </div>
          </div>

          {/*<div className="bg-white rounded shadow my-4 px-4 py-4">
            <h5>
              Preview
            </h5>

          </div>*/}

          <div className="bg-white rounded shadow my-4 px-4 py-4">
            <h5>
              Basic Info
            </h5>
            
              {[
                {id: 'id', title: 'Page ID', info: 'used as URI path for the page', isColor: null},
                {id: 'title', title: 'Title', info: null, isColor: null},
                {id: 'subTitle', title: 'Subtitle', info: null, isColor: null},
                {id: 'tabTitle', title: 'Tab Title', info: null, isColor: null},
                {id: 'shareCaption', title: 'Share Caption', info: "will embbed as default caption on social media post", isColor: null},
              ].map(v => 
                <div className={"row mt-3"}>
                  <div className="col-12 col-lg-2 my-2">
                    {v.title}
                  </div>
                  <div className="col-lg-6 col-12 col-sm-12 col-xs-12">
                    <div className="d-flex">
                      <Field id={v.id} name={v.id} className="form-control" maxLength={200} component={(v.id == 'shareCaption') ? "textarea" : null} />
                    </div>

                    {(v.info != null) && <div className="my-2 d-flex flex-column">
                      {(!/^[A-Za-z0-9\-]+$/.test(formikProps.values.id) && (v.id == 'id')) && <span style={{color: 'red', fontSize: '11px'}}>{'Page ID must contains alphanumeric character only'}</span>}
                      <i style={{ color: '#757575' }}>{v.info}</i>
                    </div>}

                  </div>
                </div>
              )}

          </div>

          <div className="bg-white rounded shadow my-4 px-4 py-4 d-flex justify-content-between">
            <div>
              <div style={{display: 'flex'}} >
                <h5>
                  Styling
                </h5>
                <i className="ml-3" >
                  {'(get more color reference from '}
                  <span
                    style={{
                      color: 'blue',
                      cursor: 'pointer'
                    }} 
                    onClick={() => {window.open("https://imagecolorpicker.com/color-code/043b72");}}>
                    {'here)'}
                  </span>
                </i>
              </div>

              <div className={"mt-2 d-flex align-items-center"}>

                {/*<div className="col-lg-6 col-12 col-sm-12 col-xs-12">*/}
                <div className="" style={{maxWidth: '700px', alignItems: 'center'}} >
                  <span style={{marginBottom: '10px'}} >{'List shape'}</span>
                  <div className="d-flex mb-1 mt-2 ml-2" style={{maxWidth: '700px', alignItems: 'center'}} >
                    <StatFilterNode cState={borderRad} label={"right angle"} ownState={"0"}/>
                    <StatFilterNode cState={borderRad} label={"button-like"} ownState={"0.25rem"}/>
                    <StatFilterNode cState={borderRad} label={"quarter-round"} ownState={"0.5rem"}/>
                  </div>
                  <div className="d-flex ml-2" style={{maxWidth: '700px', alignItems: 'center'}} >
                    <StatFilterNode cState={borderRad} label={"half-round"} ownState={"1rem"} />
                    <StatFilterNode cState={borderRad} label={"full-rounded"} ownState={"3rem"} />
                  </div>
                </div>
                {/*</div>*/}
              </div>

              <div className="d-flex">
                <div>
                  {[
                    {id: 'bgColor', title: 'Background Color'},
                    {id: 'linkColor', title: 'Link Color'}
                  ].map(v => 
                    <div className={"mt-3"}>
                      <div className="">
                        <span>{v.title}</span>
                        <div className="d-flex mt-2">
                          <Field id={v.id} name={v.id} className="form-control" maxLength={16} />
                          <div 
                            style={{
                              width: '40px', margin: '2px', marginLeft: '10px', 
                              border: '1px solid gray',
                              borderRadius: '3px', backgroundColor: formikProps.values[v.id]
                            }}
                            onClick={() => console.log(formikProps.values[v.id])}
                          >{' '}</div>
                        </div>
                        <div className="my-2 d-flex flex-column">
                          <div style={{display: 'flex', alignItems: 'center'}} >
                            <span style={{fontSize: '11px', marginRight: '3px', marginTop: '0'}}>{'preset: '}</span>
                            {['#F58220', '#043B72', '#48535B', '#ECEFF4', '#E52017', '#4880EE', '#54803F', '#E57D00', 'white', 'black'].map(cPresetColor => 
                              <div 
                                style={{cursor: 'pointer', width: '16px', height: '8px', backgroundColor: cPresetColor, border: '1px solid gray', marginRight: '3px'}} 
                                onClick={() => {
                                  formikProps.setFieldValue(v.id, cPresetColor);
                                }}
                              />
                            )}
                          </div>
                        </div>

                      </div>
                    </div>
                  )}
                </div>

                <div style={{width: '20px'}} ></div>

                <div>
                  {[
                    {id: 'titleColor', title: 'Text Title Color'},
                    {id: 'linkTextColor', title: 'Text Link Color'}
                  ].map(v => 
                    <div className={"ml-2 mt-3"}>
                      <div className="">
                        <span>{v.title}</span>
                        <div className="d-flex mt-2">
                          <Field id={v.id} name={v.id} className="form-control" maxLength={16} />
                          <div 
                            style={{
                              width: '40px', margin: '2px', marginLeft: '10px', 
                              border: '1px solid gray',
                              borderRadius: '3px', backgroundColor: formikProps.values[v.id]
                            }}
                            onClick={() => console.log(formikProps.values[v.id])}
                          >{' '}</div>
                        </div>
                        <div className="my-2 d-flex flex-column">
                          <div style={{display: 'flex', alignItems: 'center'}} >
                            <span style={{fontSize: '11px', marginRight: '3px', marginTop: '0'}}>{'preset: '}</span>
                            {['#F58220', '#043B72', '#48535B', '#ECEFF4', '#E52017', '#4880EE', '#54803F', '#E57D00', 'white', 'black'].map(cPresetColor => 
                              <div 
                                style={{cursor: 'pointer', width: '16px', height: '8px', backgroundColor: cPresetColor, border: '1px solid gray', marginRight: '3px'}} 
                                onClick={() => {
                                  formikProps.setFieldValue(v.id, cPresetColor);
                                }}
                              />
                            )}
                          </div>
                        </div>

                      </div>
                    </div>
                  )}
                </div>
              </div>

              

            </div>

            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}} >
              <span style={{marginBottom: '10px'}} >Styling Preview</span>
              <div style={{
                  border: '2px solid black',
                  borderRadius: '15px',
                  width: '250px', height: '280px', 
                  backgroundColor: formikProps?.values?.bgColor,
                  color: formikProps?.values?.titleColor,
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                }} 
              >
                <span style={{fontSize: '24px'}} >Title</span>
                <span style={{fontSize: '14px'}} >Sub Title</span>
                {['1', '2'].map(vLink => <div style={{
                    width: '200px', height: '48px', marginTop: '16px',
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                    backgroundColor: formikProps?.values?.linkColor,
                    color: formikProps?.values?.linkTextColor,
                    borderRadius: `${borderRad}`,
                    fontSize: '16px'
                  }} 
                >
                  <b>{'Link ' + vLink}</b>
                </div>}

              </div>
            </div>

          </div>

          <div className="bg-white rounded shadow my-4 px-4 py-4">
            <h5>
              Images
            </h5>
            
            <div className={"d-flex mt-3"}>

              <div>
                <div style={{marginBottom: "16px"}}>{'Tab Image'}</div>
                
                {((tabIcon == null) || (tabIcon == ""))
                  ? <ImageHolder type={"Tab"}/>
                  : <>
                      <div className="imageRatioKeeper" style={{position: "relative"}}>
                          <a style={{cursor: "pointer"}} className={"closeButton"} onClick={async () => {
                              setTabIcon("");
                              setTabIconFile(await urltoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','remove.txt'));
                          }}></a>
                          <img className={"previewImageContainer"} src={tabIcon} width={204} height={204}/>
                      </div>
                  </>
                }
              </div>

              <div style={{width: "32px"}} />

              <div>
                <div style={{marginBottom: "16px"}}>{'Header Image'}</div>

                {((titleIcon == null) || (titleIcon == ""))
                  ? <ImageHolder type={"Title"} />
                  : <>
                      <div className="imageRatioKeeper" style={{position: "relative"}}>
                          <a style={{cursor: "pointer"}} className={"closeButton"} onClick={async () => {
                              setTitleIcon("");
                              setTitleIconFile(await urltoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','remove.txt'));
                          }}></a>
                          <img className={"previewImageContainer"} src={titleIcon} width={204} height={204}/>
                      </div>
                  </>

                }
              </div>

              <div style={{width: "32px"}} />

              <div>
                <div style={{marginBottom: "16px"}}>{'Background Image'}</div>

                {((bgIcon == null) || (bgIcon == ""))
                  ? <ImageHolder type={"Background"} />
                  : <>
                      <div className="imageRatioKeeper" style={{position: "relative"}}>
                          <a style={{cursor: "pointer"}} className={"closeButton"} onClick={async () => {
                              setBgIcon("");
                              setBgIconFile(await urltoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','remove.txt'));
                          }}></a>
                          <img className={"previewImageContainer"} src={bgIcon} width={204} height={204}/>
                      </div>
                  </>

                }
              </div>

              <div style={{width: "32px"}} />

              <div>
                <div style={{marginBottom: "16px"}}>{'Content Image'}</div>

                {((contentIcon == null) || (contentIcon == ""))
                    ? <ImageHolder type={"Content"} />
                    : <>
                        <div className="imageRatioKeeper" style={{position: "relative"}}>
                            <a style={{cursor: "pointer"}} className={"closeButton"} onClick={async () => {
                                setContentIcon("");
                                setContentIconFile(await urltoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','remove.txt'));
                            }}></a>
                            <img className={"previewImageContainer"} src={contentIcon} width={204} height={204}/>
                        </div>
                    </>

                }
              </div>

            </div>
          </div>

          <div className="bg-white rounded shadow my-4 px-4 py-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <h5>Links</h5>
                <i className="ml-3" >
                  {'(get icons reference from '}
                  <span
                    style={{
                      color: 'blue',
                      cursor: 'pointer'
                    }} 
                    onClick={() => {window.open("https://iconscout.com/unicons/explore/line");}}>
                    {'here)'}
                  </span>
                </i>
              </div>
              <button className="btn btn-primary" onClick={(e) => {
                // setLinks([...links, { text: null, url: null, icon: null }]);
                formikProps.values.links = [...formikProps.values.links, {color: "no", isDraft: "f"}];
              }} disabled={false}>
                Add links
              </button>
            </div>


            { formikProps.values.links.map((v, i) => {
              return <div className="bg-light rounded shadow mt-2 px-4 py-3" style={{color: (formikProps?.values?.links[i]?.isDraft == "t") ? 'grey' : 'black'}} >
                <div className="d-flex justify-content-between">
                  
                  <div className="d-flex align-items-center">
                    
                    <h6 style={{marginRight: '16px'}} ><b>Link {(i + 1)?.toString()}</b></h6>

                    <div className="d-flex">
                      {React.createElement('input',{
                        type: 'checkbox',
                        checked: (formikProps?.values?.links[i]?.color != "no"),
                        onClick: () => {
                          if (formikProps?.values?.links[i]?.color == "no") {
                            formikProps.setFieldValue("links[" + i + "].color", "");
                          } else {
                            formikProps.setFieldValue("links[" + i + "].color", "no");
                          } 
                        }
                      })} <span style={{marginLeft: '5px', marginRight: '16px'}} >Customize background</span>
                    </div>
                    <div className="d-flex">
                      {React.createElement('input',{
                        type: 'checkbox',
                        checked: (formikProps?.values?.links[i]?.isDraft == "t"),
                        onClick: () => { 
                          if (formikProps?.values?.links[i]?.isDraft == "f") {
                            formikProps.setFieldValue("links[" + i + "].isDraft", "t");
                          } else {
                            formikProps.setFieldValue("links[" + i + "].isDraft", "f");
                          }
                        }
                      })} <span style={{marginLeft: '5px'}} >Draft only</span>
                    </div>
                  </div>

                  <button className="btn btn-danger" onClick={(e) => {
                    if (formikProps.values.links.length <= 1) {
                      formikProps.values.links = [];
                    } else {
                      formikProps.values.links = formikProps.values.links.filter((vv, ii) => (ii != i));            
                    }
                  }} disabled={false}>
                    {false && <Spinner color='#fff' size="sm" className='mr-1' />}Remove
                  </button>
                </div>
                
                <div className={"row mt-3"}>
                  {[
                    {id: 'text', title: 'Text'},
                    {id: 'url', title: 'URL'},
                    {id: 'icon', title: 'Icon'}
                  ].map(lField => <>
                      <div className="col-3 col-lg-1 my-1 d-flex">
                        {lField.title}
                        {(lField.id == "icon") && <i style={{fontSize: '24px', marginLeft: '10px'}} className={"uil uil-" + formikProps?.values?.links[i]?.icon}></i> }
                      </div>
                      <div className="col-lg-3 col-6 col-sm-6 col-xs-6">
                        <div className="d-flex">
                          <Field name={"links[" + i + "]." + lField.id} className="form-control" />
                          <div style={{width: '20px'}} >
                          </div>
                        </div>
                      </div>
                  </>)}
                </div>

                {(formikProps?.values?.links[i]?.color != "no") && <div className={"row mt-3"}>
                  <div className="col-12 col-lg-2 my-2">
                    {'Background color'}
                  </div>
                  <div className="col-5">
                    <div className="d-flex">
                      <Field name={"links[" + i + "].color"} className="form-control" />
                      <div 
                        style={{
                          width: '40px', margin: '2px', marginLeft: '10px', 
                          border: '1px solid gray', 
                          borderRadius: '3px', backgroundColor: formikProps.values?.links[i]?.color
                        }}
                        onClick={() => console.log(formikProps.values?.links[i]?.color)}
                      >{' '}</div>
                    </div>
                    <div className="my-2 d-flex flex-column">
                      <div style={{display: 'flex', alignItems: 'center'}} >
                        <span style={{fontSize: '11px', marginRight: '3px', marginTop: '0'}}>{'preset: '}</span>
                        {['#F58220', '#043B72', '#48535B', '#ECEFF4', '#E52017', '#4880EE', '#54803F', '#E57D00', 'white', 'black'].map(cPresetColor => 
                          <div 
                            style={{cursor: 'pointer', width: '16px', height: '8px', backgroundColor: cPresetColor, border: '1px solid gray', marginRight: '3px'}} 
                            onClick={() => {
                              formikProps.setFieldValue("links[" + i + "].color", cPresetColor);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>}

              </div>
            }) }

          </div>

        </div>
      </div>
      
    </div>
    
    </Form>)}</Formik>

  );
};

const mapStateToProps = ({ document }: IRootState) => ({
});

const mapDispatchToProps = {
};

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(LinktreeDetail);