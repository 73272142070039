import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { login } from 'app/shared/reducers/authentication';
import { Spinner } from 'reactstrap';
import HeaderPublic from 'app/shared/layout/header-public/header';
import useWindowDimensions from 'app/utils/ScreenDimesions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EyeSmallIconDark from 'app/component/eye-small-icon-dark';
import EyeSmallIconDarkHide from 'app/component/eye-small-icon-dark-hide';
import axios from 'axios';
import { toast } from 'react-toastify';
import "./styles.scss";
import { apiChangePassword } from 'app/config/api-collection';
import { translate } from 'react-jhipster';
import { Storage } from 'react-jhipster';

export interface IChangePasswordProps {
    isProps?: any;
}

export const ChangePassword = (props: IChangePasswordProps) => {
    const location = useLocation();
    const history = useHistory();
    const { height, width } = useWindowDimensions();
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("Qwerty123")
    const [confirmNewPassword, setConfirmNewPassword] = useState<any>("Qwerty123")
    const [loading, setLoading] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { username, isAuthenticated, needChangePassword } = props.isProps;

    useEffect(() => {

    }, []);

    const pressGoBack = () => {
        Storage.session.remove(`change_password`);
        Storage.session.remove(`change_password_username`);
        history.push('/')
        window.location.reload();
    }

    const validationTnc = (valueInput) => {
        var reg = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9]).{8,12}$');
        return reg.test(valueInput)
    }

    const containsSpecialChars = (str) => {
        const specialChars =
            /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str);
    }

    const pressChangePassword = async () => {
        if (oldPassword == "" || newPassword == "" || confirmNewPassword == "") {
            toast.error(`Please check field doesn't empty`);
        } else if (!validationTnc(newPassword) || !validationTnc(confirmNewPassword)) {
            toast.error(`Please check field and term and conditions`);
        } else if (newPassword != confirmNewPassword) {
            toast.error(`Please check new password and confirm password`);
        } else if (containsSpecialChars(newPassword) || containsSpecialChars(confirmNewPassword)) {
            toast.error(`Please check field and term and conditions`);
        } else {
            setLoading(true)
            var change_password_username = Storage.session.get('change_password_username')
            let dataFormData = {
                userName: change_password_username,
                currentPassword: oldPassword,
                newPassword: newPassword,
                confirmNewPassword: confirmNewPassword
            }
            axios.post(`${apiChangePassword}`, dataFormData)
                .then(res => {
                    console.log("pressChangePassword", res)
                    if (res.data.code == 200) {
                        Storage.session.remove(`change_password`);
                        Storage.session.remove(`change_password_username`);
                        props.isProps.login(username, newPassword, false)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    toast.error(translate(`${err.response.data.message}`));
                })
        }
    }

    const { from } = (location.state as any) || { from: { pathname: '/', search: location.search, username: username } };

    if (isAuthenticated) {
        return <Redirect to={from} />;
    }

    return (
        <>
            <HeaderPublic />
            <div style={width < 425 ? { height: '90vh', background: '#fff', overflowY: 'scroll', display: "flex", justifyContent: "center" } : { height: '90vh' }} className="mt-5 py-5 container-lg font-family-spoqa">
                <div className="account-pages">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="card overflow-hidden">
                                    <div className="card-body pt-0">
                                        <div className="p-2">
                                            {/* <form className="form-horizontal"> */}
                                            <div className="div-container">
                                                <div className="col-1 mr-2" style={{ display: "flex", alignItems: "center" }}>
                                                    <button className="btn px-0" onClick={() => pressGoBack()}>
                                                        <FontAwesomeIcon size='2x' icon="arrow-left" color="#0A0A0A" />{' '}
                                                    </button>
                                                </div>
                                                <div className="col-md-10" style={{ display: "flex", alignItems: "center" }}>
                                                    <div className="font-size-18">Change Password</div>
                                                </div>
                                            </div>
                                            <div className="row mt-4 ml-1">
                                                <div className="col-12">
                                                    <div className="">
                                                        <h5 className="">Welcome, to Mirae Asset DMS</h5>
                                                        <p>Silakan ubah password DMS untuk keamanan data Anda</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="div-container-column">
                                                <div className="col-6 mt-2">
                                                    Old Password
                                                </div>
                                                <div className="pass-wrapper mt-2">
                                                    <input
                                                        type={showOldPassword ? 'text' : 'password'}
                                                        className="form-control bg-white col-12 ml-2 mr-2"
                                                        placeholder="Input Old Password"
                                                        onChange={(e) => { setOldPassword(e.target.value) }}
                                                    />
                                                    <i className="eyechangepassowrdtagi">
                                                        {
                                                            !showOldPassword ?
                                                                <EyeSmallIconDark onClick={() => setShowOldPassword(!showOldPassword)} />
                                                                :
                                                                <EyeSmallIconDarkHide onClick={() => setShowOldPassword(!showOldPassword)} />
                                                        }
                                                    </i>
                                                    <i className="ml-2 font-size-11">(Enter old password from email, example: <b>7RJ3K4VA1P</b>)</i>
                                                </div>
                                            </div>
                                            <div className="div-container-column">
                                                <div className="col-6 mt-4">
                                                    New Password
                                                </div>
                                                <div className="pass-wrapper mt-2">
                                                    <input
                                                        type={showNewPassword ? 'text' : 'password'}
                                                        className="form-control bg-white col-12 ml-2 mr-2"
                                                        placeholder="Input New Password"
                                                        minLength={8}
                                                        maxLength={12}
                                                        onChange={(e) => { setNewPassword(e.target.value) }}
                                                    />
                                                    <i className="eyechangepassowrdtagi">
                                                        {
                                                            !showNewPassword ?
                                                                <EyeSmallIconDark onClick={() => setShowNewPassword(!showNewPassword)} />
                                                                :
                                                                <EyeSmallIconDarkHide onClick={() => setShowNewPassword(!showNewPassword)} />
                                                        }
                                                    </i>
                                                </div>
                                            </div>
                                            <div className="div-container-column">
                                                <div className="col-6 mt-4">
                                                    Confirm New Password
                                                </div>
                                                <div className="pass-wrapper mt-2">
                                                    <input
                                                        type={showConfirmPassword ? 'text' : 'password'}
                                                        className="form-control bg-white col-12 ml-2 mr-2"
                                                        placeholder="Input Confirm New Password"
                                                        minLength={8}
                                                        maxLength={12}
                                                        onChange={(e) => { setConfirmNewPassword(e.target.value) }} //("confirmNewPassword", e.target.value)
                                                    />
                                                    <i className="eyechangepassowrdtagi">
                                                        {
                                                            !showConfirmPassword ?
                                                                <EyeSmallIconDark onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                                                :
                                                                <EyeSmallIconDarkHide onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                                        }
                                                    </i>
                                                </div>
                                            </div>
                                            <div className="div-container-column mt-4">
                                                <ul style={{ marginTop: "-10px" }}>
                                                    <li>Password must be at least min 8 characters max 12 characters</li>
                                                    <li>Must include at least one upper case letter, one lower case letter, and one numeric digit</li>
                                                    <li>Password doesn't include special character</li>
                                                </ul>
                                            </div>
                                            <div className="mt-2 d-grid ml-2 " style={{ width: "100%" }}>
                                                <button className="btn btn-primary waves-effect waves-light" disabled={loading} type="submit" onClick={() => pressChangePassword()}>
                                                    {loading ? <Spinner size="sm" color="#fff" className='mr-1' /> : <></>}
                                                    <span style={{ color: '#F5F5F5', textAlign: 'center' }}>Submit</span>
                                                </button>
                                            </div>
                                            {/* </form> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    // isAuthenticated: authentication.isAuthenticated,
    // loginError: authentication.loginError,
    // showModal: authentication.showModalLogin,
    // needChangePassword: authentication.needChangePassword,
    // username: authentication.username,
});

const mapDispatchToProps = {
    // login 
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
