import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Row, Col, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Input} from 'reactstrap';
import {AvFeedback, AvForm, AvGroup, AvInput, AvField} from 'availity-reactstrap-validation';
import {Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {v4 as uuidv4} from 'uuid';
import Lottie from 'react-lottie';
import animationData from "app/component/loading.json";
import {
    getEntity,
    updateEntity,
    createEntity,
    reset,
    getDocumentDetail,
    getUserList,
    getApprovalList,
    getAcknowledgeList,
    getDeptList,
    getListDocumentCode,
    sendNewDocument,
    saveDocumentToDraft
} from './document.reducer';
import documentPreview from '../preview/document-preview';
import {Editor} from '@tinymce/tinymce-react';
import IconSaveAsTemplate from 'app/component/save-as-template';
import IconUseAsTemplate from 'app/component/use-as-template';
import ArrowRightIconWhite from 'app/component/arrow-right-icon-white';
import SaveDraftIcon from 'app/component/save-draft-icon';
import InfoIcon from 'app/component/InfoIcon';
import UploadFileIcon from 'app/component/upload-icon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import {toast} from 'react-toastify';
import './document-update.scss';
import {Box, CircularProgress, Tooltip} from '@mui/material';
import {documentSecurity, priorityDocument, typeDocument} from './dataMock';
import {b64toBlob, bytesToSize, isValidFileUploaded, replaceP, validateSize, validateUrl} from './utils';
import {DocumentForm} from './type';
import Select from 'react-select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {Select as SelectMui} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import {
    apiGetDetailDocument,
    apiGetListAttachement,
    apiGetListGroupNo,
    apiGetDepartmentList,
    apiGenerateDocumentNumberByGroup,
    apiDeleteFileUpload,
    apiValidasiURL,
    apiGetDataSendTo,
    apiGetAllLevelOrganization,
    apiGetGroup,
    apiGetApprovers,
    apiGetAllLevelOrganizationScreener,
    apiGetOrganizationScreener,
    apiGetApproversScreener,
    apiSaveAsDraft,
    apiNextStep,
    apiTemplateDocumentSave
} from 'app/config/api-collection';
import {UploadConstants} from 'app/shared/util/upload.constants';
import DuplicateIllustration from 'app/component/duplicate-illustration';
import ReplaceIcon from 'app/component/replace-icon';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import SuccessReplaceIllustration from 'app/component/success-replace-illustration';
import RadioSelectIcon from "app/component/radio-select-icon";
import RadioUnselectIcon from "app/component/radio-unselect-icon";
import MergeIllustration from "app/component/merge-illustration";

export interface Props {
    getDocumentDetail?: any,
    detaildocument?: any,
    location: {
        state?: {
            file: any,
            data: any,
            link: any
        }
    }
}

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    isClickToPauseDisabled:true,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export interface IDocumentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const DocumentCreate = (props: any, editMode = true) => {

    const {
        documentEntity,
        loading,
        updating,
        deptList,
        userList,
        detaildocument,
        loadingDepartement,
        loadingDetail,
        approvalList,
        documentListCode,
        acknowledgeList
    } = props;

    const initialFormData = {
        id: "0",
        type: "IMO",
        priority: "",
        documentSecurity: "",
        sendTo: "",
        date: new Date(),
        expDate: new Date(),
        regarding: "",
        fileContent: "",
        to: [],
        cc: [],
        effluent: [],
        screener: [],
        // acknowledge: [],
        // approval: []
    }

    const initialFormDataEdit = {
        id:"0",
        type: "IMO",
        priority: "",
        documentSecurity: "",
        sendTo: "",
        date: new Date(),
        expDate: new Date(),
        regarding: "",
        fileContent: "",
        to: [],
        cc: [],
        effluent: [],
        screener: [],

        title: "",
        content: ""
    }

    const initialDocumentNo = {
        documentCode: null,
        documentNumber: null,
        groupId: null
    }

    const tooltipMessage = {
        to: "The party who gave approval for the submitted documents/Pihak yang memberikan persetujuan atas dokumen yang disampaikan",
        cc: "The party who acknowledges the submitted documents/Pihak yang mengakui atas dokumen yang disampaikan",
        effulent: "The party that confirms the submitted documents/Pihak yang memberikan konfirmasi atas dokumen yang disampaikan",
        screener: "The party that checks and confirms the submitted documents (eg Head of Division/Department/Team)/Pihak yang memeriksa dan memberikan konfirmasi atas dokumen yang disampaikan (contoh: Kepala Divisi/Departemen/Tim)"
    }

    const editorRef = useRef(null);
    const [listAttachment, setListAttachments] = useState([])
    const [listFile, setListFile] = useState([])
    const [inputValue, setInputValue] = useState("")
    const [formData, setFormData] = useState(initialFormData)
    const [formDataEdit, setFormDataEdit] = useState(initialFormDataEdit)
    const [formDocumentNo, setFormDocumentNo] = useState<DocumentForm>(initialDocumentNo)
    const [formDocumentNoEdit, setFormDocumentNoEdit] = useState<DocumentForm>(initialDocumentNo)
    const [groupListDocument, setGroupListDocument] = useState([])
    const [departmentList, setDepartmentList] = useState([])
    const [listApproval, setListApproval] = useState([])
    const [listAcknowledge, setListAcknowledge] = useState([])
    const [listSendTo, setListSendTo] = useState<any>([])
    const [listSendToEdit, setListSendToEdit] = useState<any>([])
    const [listSendToReplace, setListSendToReplace] = useState<any>([])
    const [initialValue, setInitialValue] = useState(undefined);
    const [fileStatus, setFileStatus] = useState("")
    const [loadingLink, setLoadingLink] = useState(false)
    const [loadingSaveAsDraft, setLoadingSaveAsDraft] = useState(false)
    const [loadingNextStep, setLoadingNextStep] = useState(false)
    const [loadingDraft, setLoadingDraft] = useState(false)
    const [loadingDraftFile, setLoadingDraftFile] = useState(false)
    const [dataLevelOrganization, setDataLevelOrganization] = useState<any>([])
    /* to */
    const [addFieldTo, setAddFieldTo] = useState<any>([{"levelOrganization": 0, "levelOrganizationName": "", "groupId": 0, "groupName":"", "approvers": "", "approversName": ""}])
    const [addFieldToEdit, setAddFieldToEdit] = useState<any>([{"levelOrganization": 0, "levelOrganizationName": "", "groupId": 0, "groupName":"", "approvers": "", "approversName": ""}])
    const [dataOrganizationTo, setDataOrganizationTo] = useState<any>([])
    const [dataOrganizationToEdit, setDataOrganizationToEdit] = useState<any>([])
    const [dataApproversTo, setDataApproversTo] = useState<any>([])
    const [dataApproversToEdit, setDataApproversToEdit] = useState<any>([])

    /* cc */
    const [addFieldCC, setAddFieldCC] = useState<any>([{"levelOrganization": 0, "levelOrganizationName": "", "groupId": 0, "groupName":"", "approvers": "", "approversName": ""}])
    const [addFieldCCEdit, setAddFieldCCEdit] = useState<any>([{"levelOrganization": 0, "levelOrganizationName": "", "groupId": 0, "groupName":"", "approvers": "", "approversName": ""}])
    const [dataOrganizationCC, setDataOrganizationCC] = useState<any>([])
    const [dataOrganizationCCEdit, setDataOrganizationCCEdit] = useState<any>([])
    const [dataOrganizationCCSelected, setDataOrganizationCCSelected] = useState<any>([])
    const [dataOrganizationCCSelectedEdit, setDataOrganizationCCSelectedEdit] = useState<any>([])
    const [dataApproversCC, setDataApproversCC] = useState<any>([])
    const [dataApproversCCEdit, setDataApproversCCEdit] = useState<any>([])
    const [dataApproversCCEditSelected, setDataApproversCCEditSelected] = useState<any>([])
    const [dataApproversCCEditSelectedEdit, setDataApproversCCEditSelectedEdit] = useState<any>([])

    /* effluent */
    const [addFieldEF, setAddFieldEF] = useState<any>([{"levelOrganization": 0, "levelOrganizationName": "", "groupId": 0, "groupName":"", "approvers": "", "approversName": ""}])
    const [addFieldEFEdit, setAddFieldEFEdit] = useState<any>([{"levelOrganization": 0, "levelOrganizationName": "", "groupId": 0, "groupName":"", "approvers": "", "approversName": ""}])
    const [dataOrganizationEF, setDataOrganizationEF] = useState<any>([])
    const [dataOrganizationEFEdit, setDataOrganizationEFEdit] = useState<any>([])
    const [dataOrganizationEFSelected, setDataOrganizationEFSelected] = useState<any>([])
    const [dataOrganizationEFSelectedEdit, setDataOrganizationEFSelectedEdit] = useState<any>([])
    const [dataApproversEF, setDataApproversEF] = useState<any>([])
    const [dataApproversEFEdit, setDataApproversEFEdit] = useState<any>([])
    const [dataApproversEFEditSelected, setDataApproversEFEditSelected] = useState<any>([])
    const [dataApproversEFEditSelectedEdit, setDataApproversEFEditSelectedEdit] = useState<any>([])
    /* screener */
    const [addFieldScreener, setAddFieldScreener] = useState<any>([{
        "levelOrganization": 0,
        "groupId": 0,
        "approvers": ""
    }])
    const [addFieldScreenerEdit, setAddFieldScreenerEdit] = useState<any>([{
        "levelOrganization": 0,
        "groupId": 0,
        "approvers": ""
    }])
    const [dataLOScreener, setDataLOScreener] = useState<any>([])
    const [dataLOScreenerEdit, setDataLOScreenerEdit] = useState<any>([])

    const [dataOrganizationScreener, setDataOrganizationScreener] = useState<any>([])
    const [dataOrganizationScreenerEdit, setDataOrganizationScreenerEdit] = useState<any>([])
    const [dataOrganizationScreenerSelected, setDataOrganizationScreenerSelected] = useState<any>([])
    const [dataOrganizationScreenerSelectedEdit, setDataOrganizationScreenerSelectedEdit] = useState<any>([])
    const [dataApproversScreener, setDataApproversScreener] = useState<any>([])
    const [dataApproversScreenerEdit, setDataApproversScreenerEdit] = useState<any>([])
    const [dataApproversScreenerSelected, setDataApproversScreenerSelected] = useState<any>([])
    const [dataApproversScreenerSelectedEdit, setDataApproversScreenerSelectedEdit] = useState<any>([])

    const [pageTotalTemplate, setPageTotalTemplate] = useState(1)
    const [sizeTemplate, setSizeTemplate] = useState(10)
    const [pageTemplate, setPageTemplate] = useState(1)
    const [dataTemplate, setDataTemplate] = useState<any>([])

    const [modalSaveAsTemplate, setModalSaveAsTemplate] = useState(false)
    const [isShowModalTemplateExist, showModalTemplateExist] = useState(false)
    const [modalUseAsTemplate, setModalUseAsTemplate] = useState(false)
    const [modalSuccessTemplate, setModalSuccessTemplate] = useState(false)
    const [modalEditTemplate, setModalEditTemplate] = useState(false)
    const [modalListReplace, setModalListReplace] = useState(false)
    const [isShowCollapsing, setShowCollapsing] = useState([{no: "", isShow: false}])
    const [isSelectedTemplate, setSelectedTemplate] = useState([{no: "", isSelected: 0, id: null, title: ""}])

    const [modalMerge, setModalMerge] = useState(false)

    const [loadingTemplate, setLoadingTemplate] = useState(false)
    const [isLoadingCheckTitle, setLoadingCheckTitle] = useState(false)

    const [idTemplate, setIdTemplate] = useState(0)
    const [titleTemplate, setTitleTemplate] = useState("")
    const [contentTemplate, setContentTemplate] = useState("")

    const [idToReplace, setIdToReplace] = useState(null)
    const [titleToReplace, setTitleToReplace] = useState(null)
    const [duplicatedId, setDuplicatedId] = useState(null)


    const [idTemplateRep, setIdTemplateRep] = useState(0)
    const [titleTemplateRep, setTitleTemplateRep] = useState("")
    const [contentTemplateRep, setContentTemplateRep] = useState("")

    const [toTemplate, setToTemplate] = useState("")
    const [ccTemplate, setCCTemplate] = useState("")
    const [regarding, setRegarding] = useState("")

    //view
    const [rbAccessLevelId, setRbAccessLevelId] = useState(false)
    const [rbAccessLevelValue, setRbAccessLevelValue] = useState("PRIVATE")

    const [isAdmin, setIsAdmin] = useState(false)

    const [isLoadingMerge, setLoadingMerge] = useState(false)

    let dataFormData: DocumentForm = formData

    useEffect(() => {
        getDataTemplate()
        setPageTemplate(1)
    }, [rbAccessLevelId]);

  useEffect(() => {
      getLevelOrganization()
      getDataTemplate()
  }, [formData.to, formData.cc, formDocumentNo.groupId, dataOrganizationCC, pageTemplate, sizeTemplate, pageTotalTemplate]);

  useEffect(() => {
    console.log("1 a")
    getListGroupNo()
    getDepartmentList()
    if (props.match.params.id) {
      console.log("1 b")
      getListAttachment()
      getDetailDocument(props.match.params.id)
    } else if (props.location.state) {

      console.log("1 c")

      if(props.location.state.from == "home"){
          setIsAdmin(props.location.state.isAdmin)
          let currentTime = new Date().getTime();
          let updatedTIme = new Date(currentTime + 2 * 60 * 60 * 1000);
          setFormData({ ...formData, expDate: updatedTIme });
      } else {

          setListFile(props.location.state.file)
          setListAttachments(props.location.state.link)
          let data = JSON.parse(props.location.state.data)
          setTimeout(() => {
              setFormData({
                  ...formData,
                  id: data.id,
                  type: data.type,
                  priority: data.priority,
                  documentSecurity: data.documentSecurity,
                  sendTo: data.sendTo,
                  regarding: data.regarding,
                  fileContent: data.fileContent,
                  date: subtractHours(-7, new Date(data.date)),
                  expDate: subtractHours(-7, new Date(data.expDate)),
              })
              setAddFieldTo(JSON.parse(props.location.state.toParse))
              setAddFieldCC(JSON.parse(props.location.state.ccParse))
              setAddFieldEF(JSON.parse(props.location.state.effluentParse))
              setAddFieldScreener(JSON.parse(props.location.state.screenerParse))
              setInitialValue(data.fileContent)
              setFormDocumentNo({
                  documentNumber: data.documentNumber,
                  documentCode: data.documentCode,
                  groupId: data.groupId
              })
              getEditOrganizationTo(JSON.parse(props.location.state.toParse))
              getEditOrganizationCC(JSON.parse(props.location.state.ccParse))
              getEditOrganizationEF(JSON.parse(props.location.state.effluentParse))
              getLevelOrganizationScreener(data.groupId)
              // getEditOrganizationScreener(data.groupId, JSON.parse(props.location.state.screenerParse))
              getDataSendTo(data.documentSecurity)
              setIsAdmin(props.location.state.isAdmin)


              try {

                  const directSupervisor = JSON.parse(props.location.state.screenerParse)

                  const fetchCarouselPromises = directSupervisor.map(item => axios.get(`${apiGetOrganizationScreener}?documentGroupId=${data.groupId}&levelOrganizationId=${item.levelOrganization}`))

                  Promise.all(fetchCarouselPromises).then(values => {

                      const dataGroupScreener = values.map((object: any, index) => (
                          object.data.map(item => {
                              return {
                                  label: item.groupName,
                                  value: item.groupId
                              };
                          })
                      ))
                      setDataOrganizationScreener(dataGroupScreener)

                      const fetchCarouselPromises2 = directSupervisor.map(item => axios.get(`${apiGetApproversScreener}?documentGroupId=${data.groupId}&groupId=${item.groupId}`))

                      Promise.all(fetchCarouselPromises2).then(values2 => {
                          const dataApprovers = values2.map((object: any, index) => (
                              object.data.map(item => {
                                  return {
                                      label: item.fullName,
                                      value: item.employeeNo
                                  };
                              })
                          ))
                          setDataApproversScreener(dataApprovers);
                      }).catch((err) => {
                          throw new Error('getApproversScreener')
                      })

                  }).catch((err) => {
                      throw new Error('getOrganizationScreener')
                  })


              } catch (err) {
                  console.log(err);
              }

          }, 1000)
      }
    } else {
      let currentTime = new Date().getTime();
      let updatedTIme = new Date(currentTime + 2 * 60 * 60 * 1000);
      setFormData({ ...formData, expDate: updatedTIme });
    }
  }, []);

    /* get data */
    const getDetailDocument = (id) => {
        setLoadingDraft(true)
        axios.get(`${apiGetDetailDocument}${id}`)
            .then(res => {
                console.log("getDetailDocument", res)
                handleEditByStatus(res.data.data)
                setLoadingDraft(false)
            }).catch(err => {
            throw new Error('getDetailDocument')
        })
    }

    const getListAttachment = () => {
        setLoadingDraftFile(true)
        axios.get(`${apiGetListAttachement}${props.match.params.id}`)
            .then(result => {
                setListFile(result.data.data.content)
                setLoadingDraftFile(false)
            }).catch(err => {
            setLoadingDraftFile(false)
        })
    }

    const getListGroupNo = () => {
        axios.get(`${apiGetListGroupNo}`)
            .then(res => {
                setGroupListDocument(res.data)
            }).catch(err => {
            toast.error(translate(`${err.response.data.message}`));
            // setGroupListDocument([])
        })
    }

    const getDepartmentList = () => {
        axios.get(`${apiGetDepartmentList}`)
            .then(res => {
                if (res.data.length > 1) {
                    res.data.map((data, i) => {
                        setDepartmentList(oldArray => [...oldArray, {label: data.groupName, value: data.id}]);
                    })
                }
            }).catch(err => {
            throw new Error('getDepartmentList')
        })
    }

    const generateDocumentNumberByGroup = (id) => {
        axios.get(`${apiGenerateDocumentNumberByGroup}${id}&type=${formData.type}`)
            .then(res => {
                setFormDocumentNo({
                    groupId: id,
                    documentNumber: res.data.data.documentNumber,
                    documentCode: res.data.data.documentCode
                })
            }).catch(err => {
            // toast.error(translate(`${err.response.data.message}`));
        })
    }

    /* modify */
    const objectToArray = (array, name) => {
        let data = []
        array.map((item) => {
            data.push({[name]: item.value})
        })
        return data
    }

    function subtractHours(numOfHours, date = new Date()) {
        date.setHours(date.getHours() + numOfHours);
        return date;
    }

    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const deleteLink = (key) => {
        let arr = listAttachment
        var filteredArray = arr.filter(function (e, i) {
            return e.id !== key
        })
        setListAttachments(filteredArray)
    }

    const deleteFile = (key) => {
        let arr = listFile
        var filteredArray = arr.filter(function (e, i) {
            return i !== key
        })
        setListFile(filteredArray)
    }

    const handleUpdate = (newValue, editor) => {
        setFormData({
            ...formData,
            fileContent: newValue
        })
    }

    const openFilePreview = (base64, filetype) => {
        const blob = b64toBlob(base64, filetype);
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl)
    }

    /* action */
    const handleEditByStatus = (data) => {
        setFileStatus(data.fileStatus)
        if (data.fileStatus == 'DRAFT' || data.fileStatus == 'RECALLED' || data.fileStatus == 'REJECTED' || data.fileStatus == 'REJECTED_BY_REJECTOR') {
            let toRespon = []
            let ccRespon = []
            let effluentRespon = []
            let screenerRespon = []
            let attachments = []
            data.to.map((item) => {
                toRespon.push({
                    levelOrganization: item.levelOrganizationId,
                    groupId: item.branchCode,
                    approvers: item.employeeNo
                })
            })
            data.cc.map((item) => {
                ccRespon.push({
                    levelOrganization: item.levelOrganizationId,
                    groupId: item.branchCode,
                    approvers: item.employeeNo
                })
            })
            data.effluent.map((item) => {
                effluentRespon.push({
                    levelOrganization: item.levelOrganizationId,
                    groupId: item.branchCode,
                    approvers: item.employeeNo
                })
            })
            data.screener.map((item) => {
                screenerRespon.push({
                    levelOrganization: item.levelOrganizationId,
                    groupId: item.branchCode,
                    approvers: item.employeeNo
                })
            })
            data.fileAttachment.map((item) => {
                attachments.push({id: item.attachmentId, value: item.attachmentName})
            })
            setFormData({
                ...formData,
                id: data.id,
                type: data.type,
                priority: data.priority,
                documentSecurity: data.documentSecurity,
                sendTo: data.sendTo,
                regarding: data.regarding,
                fileContent: data.fileContent,
                date: (data.fileStatus == 'RECALLED' || data.fileStatus == 'REJECTED' || data.fileStatus == 'REJECTED_BY_REJECTOR') ? subtractHours(-7, new Date(data.date)) : new Date(),
                expDate: subtractHours(-7, new Date(data.expDate)),
            })
            setAddFieldTo(toRespon.length > 0 ? toRespon : addFieldTo)
            setAddFieldCC(ccRespon.length > 0 ? ccRespon : addFieldCC)
            setAddFieldEF(effluentRespon.length > 0 ? effluentRespon : addFieldEF)
            setAddFieldScreener(screenerRespon.length > 0 ? screenerRespon : addFieldScreener)
            setFormDocumentNo({
                documentNumber: data.documentNumber,
                documentCode: data.documentCode,
                groupId: data.groupId
            })
            getEditOrganizationTo(toRespon.reverse())
            getEditOrganizationCC(ccRespon.reverse())
            getEditOrganizationEF(effluentRespon.reverse())
            getLevelOrganizationScreener(data.groupId)
            // getEditOrganizationScreener(screenerRespon.reverse())
            getDataSendTo(data.documentSecurity)

            try {

                const fetchCarouselPromises = screenerRespon.map(item => axios.get(`${apiGetOrganizationScreener}?documentGroupId=${data.groupId}&levelOrganizationId=${item.levelOrganization}`))

                Promise.all(fetchCarouselPromises).then(values => {

                    const dataGroupScreener = values.map((object: any, index) => (
                        object.data.map(item => {
                            return {
                                label: item.groupName,
                                value: item.groupId
                            };
                        })
                    ))
                    setDataOrganizationScreener(dataGroupScreener)

                    const fetchCarouselPromises2 = screenerRespon.map(item => axios.get(`${apiGetApproversScreener}?documentGroupId=${data.groupId}&groupId=${item.groupId}`))

                    Promise.all(fetchCarouselPromises2).then(values2 => {
                        const dataApprovers = values2.map((object: any, index) => (
                            object.data.map(item => {
                                return {
                                    label: item.fullName,
                                    value: item.employeeNo
                                };
                            })
                        ))
                        setDataApproversScreener(dataApprovers);
                    }).catch((err) => {
                        throw new Error('getApproversScreener')
                    })

                }).catch((err) => {
                    throw new Error('getOrganizationScreener')
                })

            } catch (err) {
                console.log(err);
            }
        } else {
            let toRespon = []
            let ccRespon = []
            let effluentRespon = []
            let screenerRespon = []
            let attachments = []
            data.to.map((item) => {
                toRespon.push({
                    levelOrganization: item.levelOrganizationId,
                    groupId: item.branchCode,
                    approvers: item.employeeNo
                })
            })
            data.cc.map((item) => {
                ccRespon.push({
                    levelOrganization: item.levelOrganizationId,
                    groupId: item.branchCode,
                    approvers: item.employeeNo
                })
            })
            data.effluent.map((item) => {
                effluentRespon.push({
                    levelOrganization: item.levelOrganizationId,
                    groupId: item.branchCode,
                    approvers: item.employeeNo
                })
            })
            data.screener.map((item) => {
                screenerRespon.push({
                    levelOrganization: item.levelOrganizationId,
                    groupId: item.branchCode,
                    approvers: item.employeeNo
                })
            })
            data.fileAttachment.map((item) => {
                attachments.push({id: item.attachmentId, value: item.attachmentName})
            })
            setFormData({
                ...formData,
                id: data.id,
                type: data.type,
                priority: data.priority,
                documentSecurity: data.documentSecurity,
                sendTo: data.sendTo,
                regarding: data.regarding,
                fileContent: data.fileContent,
                date: new Date(),
                expDate: subtractHours(-7, new Date(data.expDate)),
            })

            setAddFieldTo(toRespon.length > 0 ? toRespon : addFieldTo)
            setAddFieldCC(ccRespon.length > 0 ? ccRespon : addFieldCC)
            setAddFieldEF(effluentRespon.length > 0 ? effluentRespon : addFieldEF)

            setAddFieldScreener(screenerRespon.length > 0 ? screenerRespon : addFieldScreener)

            setInitialValue(data.fileContent)
            setFormDocumentNo({
                documentNumber: data.documentNumber,
                documentCode: data.documentCode,
                groupId: data.groupId
            })
            getEditOrganizationTo(toRespon.reverse())
            getEditOrganizationCC(ccRespon.reverse())
            getEditOrganizationEF(effluentRespon.reverse())
            getLevelOrganizationScreener(data.groupId)
            // getEditOrganizationScreener(screenerRespon.reverse())
            getDataSendTo(data.documentSecurity)

            try {

                const fetchCarouselPromises = screenerRespon.map(item => axios.get(`${apiGetOrganizationScreener}?documentGroupId=${data.groupId}&levelOrganizationId=${item.levelOrganization}`))

                Promise.all(fetchCarouselPromises).then(values => {

                    const dataGroupScreener = values.map((object: any, index) => (
                        object.data.map(item => {
                            return {
                                label: item.groupName,
                                value: item.groupId
                            };
                        })
                    ))
                    setDataOrganizationScreener(dataGroupScreener)

                    const fetchCarouselPromises2 = screenerRespon.map(item => axios.get(`${apiGetApproversScreener}?documentGroupId=${data.groupId}&groupId=${item.groupId}`))

                    Promise.all(fetchCarouselPromises2).then(values2 => {
                        const dataApprovers = values2.map((object: any, index) => (
                            object.data.map(item => {
                                return {
                                    label: item.fullName,
                                    value: item.employeeNo
                                };
                            })
                        ))
                        setDataApproversScreener(dataApprovers);
                    }).catch((err) => {
                        throw new Error('getApproversScreener')
                    })

                }).catch((err) => {
                    throw new Error('getOrganizationScreener')
                })

            } catch (err) {
                console.log(err);
            }
        }
    }

    const modifyData = (array, branchName, approversName) => {
        let data = []
        array.map((item) => {
            data.push({[branchName]: !item.groupId ? 0 : item.groupId, [approversName]: item.approvers})
        })
        return data
    }

    const lastObjectVal = (valObject) => {
        let dataVal = []
        valObject.map((item) => {
            if (item.levelOrganization != 0 && item.groupId != 0 && item.approvers != '') {
                dataVal.push(item)
            }
        })
        return dataVal
    }

    const handleSaveAsDraft = () => {
        setLoadingSaveAsDraft(true)
        const dataFieldTo = lastObjectVal(addFieldTo)
        const dataFieldCC = lastObjectVal(addFieldCC)
        const dataFieldEF = lastObjectVal(addFieldEF)
        const dataFieldScreener = lastObjectVal(addFieldScreener)
        const formDataFinal = new FormData();
        let dataFormData: DocumentForm = formData
        dataFormData = {
            ...dataFormData,
            date: subtractHours(7, formData.date),
            expDate: subtractHours(7, formData.expDate),
            groupId: formDocumentNo.groupId,
            documentNumber: formDocumentNo.documentNumber,
            documentCode: formDocumentNo.documentCode,
            to: modifyData(dataFieldTo, 'branchCode', 'employeeNo'),
            cc: modifyData(dataFieldCC, 'branchCode', 'employeeNo'),
            effluent: modifyData(dataFieldEF, 'branchCode', 'employeeNo'),
            screener: modifyData(dataFieldScreener, 'branchCode', 'employeeNo'),
            isMerge: "false"
        }
        formDataFinal.append("data", JSON.stringify(dataFormData));
        listFile.map((item, index) => {
            if (!item.fileAttachmentId) {
                formDataFinal.append('fileAttachment', dataURLtoFile(item.file, item.fileName))
            }
        })
        // link gdrive
        listAttachment.map((data, i) => {
            formDataFinal.append("fileAttachmentLinks", data.value);
        })
        axios.put(`${apiSaveAsDraft}`, formDataFinal)
            .then(res => {
                setLoadingSaveAsDraft(false)
                // props.history.goBack()
                /*if (props.history?.location?.state?.from == "draft") {
                    props.history.push("/draft")
                } else {
                    props.history.push("/?tab=6")
                }*/
                // props.history.push("/draft")

                if(props.history?.location?.state?.from == "home"){
                    props.history.push("/draft")
                } else {
                    props.history.goBack()
                }

            }).catch(err => {
                dataFormData = {
                    ...dataFormData,
                    date: subtractHours(-7, formData.date),
                    expDate: subtractHours(-7, formData.expDate)
                }
                toast.error(translate(`${err.response.data.message}`));
                setLoadingSaveAsDraft(false)
        })
    }

    const handleNextStep = () => {
        setLoadingNextStep(true)
        const dataFieldTo = lastObjectVal(addFieldTo)
        const dataFieldCC = lastObjectVal(addFieldCC)
        const dataFieldEF = lastObjectVal(addFieldEF)
        const dataFieldScreener = lastObjectVal(addFieldScreener)
        dataFormData = {
            ...dataFormData,
            date: subtractHours(7, formData.date),
            expDate: subtractHours(7, formData.expDate),
            groupId: formDocumentNo.groupId,
            documentNumber: formDocumentNo.documentNumber,
            documentCode: formDocumentNo.documentCode,
            fileContent: replaceP(dataFormData.fileContent),
            to: modifyData(dataFieldTo, 'branchCode', 'employeeNo'),
            cc: modifyData(dataFieldCC, 'branchCode', 'employeeNo'),
            effluent: modifyData(dataFieldEF, 'branchCode', 'employeeNo'),
            screener: modifyData(dataFieldScreener, 'branchCode', 'employeeNo'),
        }
        setLoadingNextStep(false)

        axios.post(`${apiNextStep}`, dataFormData)
            .then(res => {
                setLoadingNextStep(false)
                setLoadingMerge(false)

                /*if(addFieldScreener[0].approvers == "" || addFieldScreener[0].approvers == null){
                    toast.error("Direct Supervisor is required");
                    return
                }*/

                props.history.push('/preview/document/pdf', {
                    data: JSON.stringify(dataFormData),
                    file: JSON.stringify(listFile),
                    link: JSON.stringify(listAttachment),
                    toParse: JSON.stringify(addFieldTo),
                    ccParse: JSON.stringify(addFieldCC),
                    effluentParse: JSON.stringify(addFieldEF),
                    screenerParse: JSON.stringify(addFieldScreener),
                    isAdmin: isAdmin,
                })
            }).catch(err => {
            setLoadingMerge(false)
            dataFormData = {
                ...dataFormData,
                date: subtractHours(-7, formData.date),
                expDate: subtractHours(-7, formData.expDate),
                groupId: formDocumentNo.groupId,
                documentNumber: formDocumentNo.documentNumber,
                documentCode: formDocumentNo.documentCode,
                fileContent: replaceP(dataFormData.fileContent),
                to: modifyData(addFieldTo, 'branchCode', 'employeeNo'),
                cc: modifyData(addFieldCC, 'branchCode', 'employeeNo'),
                effluent: modifyData(addFieldEF, 'branchCode', 'employeeNo'),
                screener: modifyData(addFieldScreener, 'branchCode', 'employeeNo'),
            }
            toast.error(translate(`${err.response.data.message}`));
            setLoadingNextStep(false)
        })
    }

    const deleteFileUpload = (fileAttachmentId) => {
        axios.delete(`${apiDeleteFileUpload}?fileAttachmentId=${fileAttachmentId}&fileId=${props.match.params.id}`)
            .then(result => {
                let arr = listFile
                var filteredArray = arr.filter(function (e, i) {
                    return e.fileAttachmentId !== fileAttachmentId
                })
                setListFile(filteredArray)
                toast.success('Berhasil Menghapus File', {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).catch(err => {
            toast.error('Gagal Menghapus File', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    const handleFileInput = async (e) => {
        if (e.target.files.length < 1) {
            return;
        }
        const file = e.target.files[0];
        let filesSelect = e.target.files
        Array.from(filesSelect).forEach(async (item: any) => {
            if (!isValidFileUploaded(item)) {
                toast.error('File type is not supported. Only PDF file is allowed', {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                if (!validateSize(item)) {
                    toast.error('Ukuran file Tidak Boleh Lebih Dari 20MB', {
                        position: "top-left",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    let data = {
                        fileAttachmentId: null,
                        fileName: item.name,
                        file: await toBase64(item),
                        fileContentType: item.type,
                        fileSize: item.size
                    }
                    setListFile(oldArray => [...oldArray, data]);
                }
            }
        });
    }

    const checkValidasiURL = (value) => {
        const formData = new FormData();
        formData.append("fileAttachmentLinks", value);
        axios.post(`${apiValidasiURL}`, formData)
            .then(res => {
                setListAttachments([...listAttachment, {id: uuidv4(), value: value}])
                setInputValue("")
                setLoadingLink(false)
            }).catch(err => {
            setLoadingLink(false)
            toast.error(translate(`${err.response.data.message}`), {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    const _handleKeyDown = (e) => {
        setLoadingLink(true);
        if (validateUrl(inputValue)) {
            checkValidasiURL(inputValue)
        } else {
            setLoadingLink(false)
            toast.error('Link yang dimasukkan kurang tepat', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleInput = (name, value) => {
        if (name == "documentSecurity") {
            getDataSendTo(value)
            setFormData({
                ...formData,
                sendTo: "",
                [name]: value.value
            })
        } else {
            setFormData({
                ...formData,
                [name]: value.value
            })
        }
    }

    const handleInputDocumentNo = (name, value: any) => {
        setFormDocumentNo({
            ...formDocumentNo,
            [name]: value
        })
    }

    /* new function */
    const getDataSendTo = (val) => {
        setListSendTo([])
        axios.get(`${apiGetDataSendTo}${val?.value ? val?.value : val}`)
            .then(result => {
                result.data.map((data, i) => {
                    setListSendTo(oldArray => [...oldArray, {label: data.parameterName, value: data.parameterCode}]);
                })
            }).catch(err => {
            throw new Error('getDataSendTo')
        })
    }

    /* new function */
    const getDataSendToEdit = (val) => {
        setListSendToEdit([])
        axios.get(`${apiGetDataSendTo}${val?.value ? val?.value : val}`)
            .then(result => {
                result.data.map((data, i) => {
                    setListSendToEdit(oldArray => [...oldArray, {label: data.parameterName, value: data.parameterCode}]);
                })
            }).catch(err => {
            throw new Error('getDataSendToEdit')
        })
    }

    const getLevelOrganization = () => {
        axios.get(`${apiGetAllLevelOrganization}`)
            .then((res) => {
                const response = res.data
                const newLevel = response.map((obj, i) => ({label: obj.levelOrganizationName, value: obj.id}));
                setDataLevelOrganization(newLevel)
            }).catch((err) => {
            throw new Error('getLevelOrganization')
        })
    }

    /* to */
    const getOrganizationTo = (i, valLevelOrganization) => {
        axios.get(`${apiGetGroup}${valLevelOrganization}`)
            .then((res) => {
                const response = res.data
                const newOrganization = response.map((obj, index) => ({
                    label: obj.parentGroupName,
                    value: obj.parentGroupId
                }));
                const newArray = [...dataOrganizationTo];
                newArray.splice(i, 1, newOrganization);
                setDataOrganizationTo(newArray);
            }).catch((err) => {
            throw new Error('getOrganizationTo')
        })
    }

    /* to */
    const getOrganizationToEdit = (i, valLevelOrganization) => {
        axios.get(`${apiGetGroup}${valLevelOrganization}`)
            .then((res) => {
                const response = res.data
                const newOrganization = response.map((obj, index) => ({
                    label: obj.parentGroupName,
                    value: obj.parentGroupId
                }));
                const newArray = [...dataOrganizationToEdit];
                newArray.splice(i, 1, newOrganization);
                setDataOrganizationToEdit(newArray);
            }).catch((err) => {
            throw new Error('getOrganizationToEdit')
        })
    }

    const getApproversTo = (i, valGroup) => {
        axios.get(`${apiGetApprovers}${valGroup}`).then((res) => {
            const response = res.data
            const newApprovers = response.map((obj, index) => ({label: obj.fullName, value: obj.employeeNo}));
            const newArrayApprovers = [...dataApproversTo];
            newArrayApprovers.splice(i, 1, newApprovers);
            setDataApproversTo(newArrayApprovers);
        }).catch((err) => {
            throw new Error('getApproversTo')
        })
    }

    const handleSelectTo = async (type, i, e) => {
        let data = [...addFieldTo];
        if (type == 'levelOrganization') {
            data[i].levelOrganization = e.value;
            data[i].levelOrganizationName = e.label;
            getOrganizationTo(i, e.value)
        }
        if (type == 'groupId') {
            data[i].groupId = e.value;
            data[i].groupName = e.label;
            getApproversTo(i, e.value)
        }
        if (type == 'approvers') {
            data[i].approvers = e.value;
            data[i].approversName = e.label;
        }
        setAddFieldTo(data)
    }

    const addFormFieldsTo = () => {
        setAddFieldTo([...addFieldTo, {"levelOrganization": 0, "groupId": 0, "approvers": ""}])
    }

    const removeFormFieldsTo = (i) => {
        let newFormValues = [...addFieldTo];
        newFormValues.splice(i, 1);
        setAddFieldTo(newFormValues)
        removeOrganizationTo(i)
        removeApproversTo(i)
    }

    const removeOrganizationTo = (i) => {
        let newDataOrganization = [...dataOrganizationTo];
        newDataOrganization.splice(i, 1);
        setDataOrganizationTo(newDataOrganization)
    }

    const removeApproversTo = (i) => {
        let newDataApprovers = [...dataApproversTo];
        newDataApprovers.splice(i, 1);
        setDataApproversTo(newDataApprovers)
    }

    const getEditOrganizationTo = (dataMemberGroups) => {
        try {
            const fetchCarouselPromises = dataMemberGroups.map(value =>
                axios.get(`${apiGetGroup}${value.levelOrganization}`)
            )
            Promise.all(fetchCarouselPromises).then((values) => {
                const dataEditGroupTo = values.map((object: any, index) => (
                    object.data.map(item => {
                        return {
                            label: item.parentGroupName,
                            value: item.parentGroupId
                        };
                    })
                ))
                setDataOrganizationTo(dataEditGroupTo)
                getEditApproversTo(dataMemberGroups)
            });
        } catch (err) {
            console.log(err);
        }
    }

    const getEditApproversTo = (dataMemberGroups) => {
        try {
            const fetchCarouselPromises = dataMemberGroups.map(value =>
                axios.get(`${apiGetApprovers}${value.groupId}`)
            )
            Promise.all(fetchCarouselPromises).then((values) => {
                const dataEditApproversTo = values.map((object: any, index) => (
                    object.data.map(item => {
                        return {
                            label: item.fullName,
                            value: item.employeeNo
                        };
                    })
                ))
                setDataApproversTo(dataEditApproversTo)
            });
        } catch (err) {
            console.log(err);
        }
    }

    /* cc */
    const getOrganizationCC = (i, valLevelOrganization) => {
        axios.get(`${apiGetGroup}${valLevelOrganization}`).then((res) => {
            const response = res.data
            const newOrganization = response.map((obj, index) => ({
                label: obj.parentGroupName,
                value: obj.parentGroupId
            }));
            const newArray = [...dataOrganizationCC];
            newArray.splice(i, 1, newOrganization);
            setDataOrganizationCC(newArray);
        }).catch((err) => {
            throw new Error('getOrganizationCC')
        })
    }

    /* cc */
    const getOrganizationCCEdit = (i, valLevelOrganization) => {
        axios.get(`${apiGetGroup}${valLevelOrganization}`).then((res) => {
            const response = res.data
            const newOrganization = response.map((obj, index) => ({
                label: obj.parentGroupName,
                value: obj.parentGroupId
            }));
            const newArray = [...dataOrganizationCCEdit];
            newArray.splice(i, 1, newOrganization);
            setDataOrganizationCCEdit(newArray);
        }).catch((err) => {
            throw new Error('getOrganizationCCEdit')
        })
    }

    const getApproversCC = (i, valGroup) => {
        axios.get(`${apiGetApprovers}${valGroup}`).then((res) => {
            const response = res.data
            const newApprovers = response.map((obj, index) => ({label: obj.fullName, value: obj.employeeNo}));
            const newArrayApprovers = [...dataApproversCC];
            newArrayApprovers.splice(i, 1, newApprovers);
            setDataApproversCC(newArrayApprovers);
        }).catch((err) => {
            throw new Error('getApproversCC')
        })
    }

    const getApproversCCEdit = (i, valGroup) => {
        axios.get(`${apiGetApprovers}${valGroup}`).then((res) => {
            const response = res.data
            const newApprovers = response.map((obj, index) => ({label: obj.fullName, value: obj.employeeNo}));
            const newArrayApprovers = [...dataApproversCCEdit];
            newArrayApprovers.splice(i, 1, newApprovers);
            setDataApproversCCEdit(newArrayApprovers);
        }).catch((err) => {
            throw new Error('getApproversCCEdit')
        })
    }

    const handleSelectCC = async (type, i, e) => {
        let data = [...addFieldCC];
        if (type == 'levelOrganization') {
            data[i].levelOrganization = e.value;
            data[i].levelOrganizationName = e.label;
            getOrganizationCC(i, e.value)
        }
        if (type == 'groupId') {
            data[i].groupId = e.value;
            data[i].groupName = e.label;
            getApproversCC(i, e.value)
        }
        if (type == 'approvers') {
            data[i].approvers = e.value;
            data[i].approversName = e.label;
        }
        setAddFieldCC(data)
    }

    const handleSelectCCEdit = async (type, i, e) => {
        let data = [...addFieldCC];
        if (type == 'levelOrganization') {
            data[i].levelOrganization = e.value;
            data[i].levelOrganizationName = e.label;
            getOrganizationCCEdit(i, e.value)
        }
        if (type == 'groupId') {
            data[i].groupId = e.value;
            data[i].groupName = e.label;
            getApproversCCEdit(i, e.value)
        }
        if (type == 'approvers') {
            data[i].approvers = e.value;
            data[i].approversName = e.label;
        }
        setAddFieldCCEdit(data)
    }

    const addFormFieldsCC = () => {
        setAddFieldCC([...addFieldCC, {"levelOrganization": 0, "groupId": 0, "approvers": ""}])
    }

    const removeFormFieldsCC = (i) => {
        let newFormValues = [...addFieldCC];
        newFormValues.splice(i, 1);
        setAddFieldCC(newFormValues)
        removeOrganizationCC(i)
        removeApproversCC(i)
    }

    const removeOrganizationCC = (i) => {
        let newDataOrganization = [...dataOrganizationCC];
        newDataOrganization.splice(i, 1);
        setDataOrganizationCC(newDataOrganization)
    }

    const removeApproversCC = (i) => {
        let newDataApprovers = [...dataApproversCC];
        newDataApprovers.splice(i, 1);
        setDataApproversCC(newDataApprovers)
    }

    const getEditOrganizationCC = (dataMemberGroups) => {
        try {
            const fetchCarouselPromises = dataMemberGroups.map(value =>
                axios.get(`${apiGetGroup}${value.levelOrganization}`)
            )
            Promise.all(fetchCarouselPromises).then((values) => {
                const dataEditGroupCC = values.map((object: any, index) => (
                    object.data.map(item => {
                        return {
                            label: item.parentGroupName,
                            value: item.parentGroupId
                        };
                    })
                ))
                setDataOrganizationCC(dataEditGroupCC)
                getEditApproversCC(dataMemberGroups)
            });
        } catch (err) {
            console.log(err);
        }
    }

    const getEditApproversCC = (dataMemberGroups) => {
        try {
            const fetchCarouselPromises = dataMemberGroups.map(value =>
                axios.get(`${apiGetApprovers}${value.groupId}`)
            )
            Promise.all(fetchCarouselPromises).then((values) => {
                const dataEditApproversCC = values.map((object: any, index) => (
                    object.data.map(item => {
                        return {
                            label: item.fullName,
                            value: item.employeeNo
                        };
                    })
                ))
                setDataApproversCC(dataEditApproversCC)
            });
        } catch (err) {
            console.log(err);
        }
    }

    /* Ef */
    const getOrganizationEF = (i, valLevelOrganization) => {
        axios.get(`${apiGetGroup}${valLevelOrganization}`).then((res) => {
            const response = res.data
            const newOrganization = response.map((obj, index) => ({
                label: obj.parentGroupName,
                value: obj.parentGroupId
            }));
            const newArray = [...dataOrganizationEF];
            newArray.splice(i, 1, newOrganization);
            setDataOrganizationEF(newArray);
        }).catch((err) => {
            throw new Error('getOrganizationEF')
        })
    }
    const getOrganizationEFEdit = (i, valLevelOrganization) => {
        axios.get(`${apiGetGroup}${valLevelOrganization}`).then((res) => {
            const response = res.data
            const newOrganization = response.map((obj, index) => ({
                label: obj.parentGroupName,
                value: obj.parentGroupId
            }));
            const newArray = [...dataOrganizationEFEdit];
            newArray.splice(i, 1, newOrganization);
            setDataOrganizationEFEdit(newArray);
        }).catch((err) => {
            throw new Error('getOrganizationEFEdit')
        })
    }

    const getApproversEF = (i, valGroup) => {
        axios.get(`${apiGetApprovers}${valGroup}`).then((res) => {
            const response = res.data
            const newApprovers = response.map((obj, index) => ({label: obj.fullName, value: obj.employeeNo}));
            const newArrayApprovers = [...dataApproversEF];
            newArrayApprovers.splice(i, 1, newApprovers);
            setDataApproversEF(newArrayApprovers);
        }).catch((err) => {
            throw new Error('getApproversEF')
        })
    }

    const getApproversEFEdit = (i, valGroup) => {
        axios.get(`${apiGetApprovers}${valGroup}`).then((res) => {
            const response = res.data
            const newApprovers = response.map((obj, index) => ({label: obj.fullName, value: obj.employeeNo}));
            const newArrayApprovers = [...dataApproversEFEdit];
            newArrayApprovers.splice(i, 1, newApprovers);
            setDataApproversEFEdit(newArrayApprovers);
        }).catch((err) => {
            throw new Error('getApproversEFEdit')
        })
    }

    const handleSelectEF = async (type, i, e) => {
        let data = [...addFieldEF];
        if (type == 'levelOrganization') {
            data[i].levelOrganization = e.value;
            data[i].levelOrganizationName = e.label;
            getOrganizationEF(i, e.value)
        }
        if (type == 'groupId') {
            data[i].groupId = e.value;
            data[i].groupName = e.label;
            getApproversEF(i, e.value)
        }
        if (type == 'approvers') {
            data[i].approvers = e.value;
            data[i].approversName = e.label;
        }
        setAddFieldEF(data)
    }

    const handleSelectEFEdit = async (type, i, e) => {
        let data = [...addFieldEFEdit];
        if (type == 'levelOrganization') {
            data[i].levelOrganization = e.value;
            data[i].levelOrganizationName = e.label;
            getOrganizationEFEdit(i, e.value)
        }
        if (type == 'groupId') {
            data[i].groupId = e.value;
            data[i].groupName = e.label;
            getApproversEFEdit(i, e.value)
        }
        if (type == 'approvers') {
            data[i].approvers = e.value;
            data[i].approversName = e.label;
        }
        setAddFieldEFEdit(data)
    }

    const addFormFieldsEF = () => {
        setAddFieldEF([...addFieldEF, {"levelOrganization": 0, "groupId": 0, "approvers": ""}])
    }

    const removeFormFieldsEF = (i) => {
        let newFormValues = [...addFieldEF];
        newFormValues.splice(i, 1);
        setAddFieldEF(newFormValues)
        removeOrganizationEF(i)
        removeApproversEF(i)
    }

    const removeOrganizationEF = (i) => {
        let newDataOrganization = [...dataOrganizationEF];
        newDataOrganization.splice(i, 1);
        setDataOrganizationEF(newDataOrganization)
    }

    const removeApproversEF = (i) => {
        let newDataApprovers = [...dataApproversEF];
        newDataApprovers.splice(i, 1);
        setDataApproversEF(newDataApprovers)
    }

    const getEditOrganizationEF = (dataMemberGroups) => {
        try {
            const fetchCarouselPromises = dataMemberGroups.map(value =>
                axios.get(`${apiGetGroup}${value.levelOrganization}`)
            )
            Promise.all(fetchCarouselPromises).then((values) => {
                const dataEditGroupEF = values.map((object: any, index) => (
                    object.data.map(item => {
                        return {
                            label: item.parentGroupName,
                            value: item.parentGroupId
                        };
                    })
                ))
                setDataOrganizationEF(dataEditGroupEF)
                getEditApproversEF(dataMemberGroups)
            });
        } catch (err) {
            console.log(err);
        }
    }

    const getEditApproversEF = (dataMemberGroups) => {
        try {
            const fetchCarouselPromises = dataMemberGroups.map(value =>
                axios.get(`${apiGetApprovers}${value.groupId}`)
            )
            Promise.all(fetchCarouselPromises).then((values) => {
                const dataEditApproversEF = values.map((object: any, index) => (
                    object.data.map(item => {
                        return {
                            label: item.fullName,
                            value: item.employeeNo
                        };
                    })
                ))
                setDataApproversEF(dataEditApproversEF)
            });
        } catch (err) {
            console.log(err);
        }
    }

    /* screener */
    const getLevelOrganizationScreener = (idDocGroup) => {
        axios.get(`${apiGetAllLevelOrganizationScreener}${idDocGroup}`)
            .then((res) => {
                const response = res.data

                if(response.length == 0){
                    setDataLOScreener([])

                    setDataOrganizationScreener([])

                    setDataApproversScreener([])

                    setAddFieldScreener([{
                        "levelOrganization": 0,
                        "groupId": 0,
                        "approvers": ""
                    }])
                } else {
                    const newLevOrgscreener = response.map((obj, i) => ({
                        label: obj.levelOrganizationName,
                        value: obj.levelOrganizationId
                    }));
                    setDataLOScreener(newLevOrgscreener)
                }

            }).catch((err) => {

            setDataLOScreener([])

            setDataOrganizationScreener([])

            setDataApproversScreener([])
            throw new Error('getLevelOrganizationScreener')
        })
    }

    const getOrganizationScreener = (i, valLOScreener) => {
        axios.get(`${apiGetOrganizationScreener}?documentGroupId=${formDocumentNo.groupId}&levelOrganizationId=${valLOScreener}`)
            .then((res) => {
                const response = res.data
                const newOrganization = response.map((obj, index) => ({label: obj.groupName, value: obj.groupId}));
                const newArray = [...dataOrganizationScreener];
                newArray.splice(i, 1, newOrganization);
                setDataOrganizationScreener(newArray);
            }).catch((err) => {
            throw new Error('getOrganizationScreener')
        })
    }

    const getOrganizationScreenerEdit = (i, valLOScreener) => {
        axios.get(`${apiGetOrganizationScreener}?documentGroupId=${formDocumentNo.groupId}&levelOrganizationId=${valLOScreener}`)
            .then((res) => {
                const response = res.data
                const newOrganization = response.map((obj, index) => ({label: obj.groupName, value: obj.groupId}));
                const newArray = [...dataOrganizationScreenerEdit];
                newArray.splice(i, 1, newOrganization);
                setDataOrganizationScreenerEdit(newArray);
            }).catch((err) => {
            throw new Error('getOrganizationScreener')
        })
    }

    const getApproversScreener = (i, valGroup) => {
        axios.get(`${apiGetApproversScreener}?documentGroupId=${formDocumentNo.groupId}&groupId=${valGroup}`)
            .then((res) => {
                const response = res.data;
                const newApprovers = response.map((obj, index) => ({label: obj.fullName, value: obj.employeeNo}));
                const newArrayApprovers = [...dataApproversScreener];
                newArrayApprovers.splice(i, 1, newApprovers);
                setDataApproversScreener(newArrayApprovers);
            }).catch((err) => {
            throw new Error('getApproversScreener')
        })
    }

    const getApproversScreenerEdit = (i, valGroup) => {
        axios.get(`${apiGetApproversScreener}?documentGroupId=${formDocumentNo.groupId}&groupId=${valGroup}`)
            .then((res) => {
                const response = res.data;
                const newApprovers = response.map((obj, index) => ({label: obj.fullName, value: obj.employeeNo}));
                const newArrayApprovers = [...dataApproversScreenerEdit];
                newArrayApprovers.splice(i, 1, newApprovers);
                setDataApproversScreenerEdit(newArrayApprovers);
            }).catch((err) => {
            throw new Error('getApproversScreener')
        })
    }

    const handleSelectScreener = async (type, i, e) => {
        let data = [...addFieldScreener];
        if (type == 'levelOrganization') {
            data[i].levelOrganization = e.value;
            data[i].levelOrganizationName = e.label;
            getOrganizationScreener(i, e.value)
        }
        if (type == 'groupId') {
            data[i].groupId = e.value;
            data[i].groupName = e.label;
            getApproversScreener(i, e.value)
        }
        if (type == 'approvers') {
            data[i].approvers = e.value;
            data[i].approversName = e.label;
        }

        setAddFieldScreener(data)
    }

    const handleSelectScreenerEdit = async (type, i, e) => {
        let data = [...addFieldScreenerEdit];
        if (type == 'levelOrganization') {
            data[i].levelOrganization = e.value;
            data[i].levelOrganizationName = e.label;
            getOrganizationScreenerEdit(i, e.value)
        }
        if (type == 'groupId') {
            data[i].groupId = e.value;
            data[i].groupName = e.label;
            getApproversScreenerEdit(i, e.value)
        }
        if (type == 'approvers') {
            data[i].approvers = e.value;
            data[i].approversName = e.label;
        }
        setAddFieldScreenerEdit(data)
    }

    const addFormFieldsScreener = () => {
        setAddFieldScreener([...addFieldScreener, {"levelOrganization": 0, "levelOrganizationName": "", "groupId": 0, "groupName": "", "approvers": "", "approversName": ""}])
    }

    const removeFormFieldsScreener = (i) => {
        let newFormValues = [...addFieldScreener];
        newFormValues.splice(i, 1);
        setAddFieldScreener(newFormValues)
        removeOrganizationScreener(i)
        removeApproversScreener(i)
    }

    const removeOrganizationScreener = (i) => {
        let newDataOrganization = [...dataOrganizationScreener];
        newDataOrganization.splice(i, 1);
        setDataOrganizationScreener(newDataOrganization)
    }

    const removeApproversScreener = (i) => {
        let newDataApprovers = [...dataApproversScreener];
        newDataApprovers.splice(i, 1);
        setDataApproversScreener(newDataApprovers)
    }

    const getEditOrganizationScreener = (dataMemberGroups) => {
        try {
            const fetchCarouselPromises = dataMemberGroups.map(value =>
                    axios.get(`${apiGetOrganizationScreener}?documentGroupId=${value.groupId}&levelOrganizationId=${value.levelOrganization}`)
                // axios.get(`${apiGetGroup}${value.levelOrganization}`)
            )
            Promise.all(fetchCarouselPromises).then((values) => {
                const dataEditGroupScreener = values.map((object: any, index) => (
                    object.data.map(item => {
                        return {
                            label: item.groupName,
                            value: item.groupId
                        };
                    })
                ))
                setDataOrganizationScreenerEdit(dataEditGroupScreener)
                getEditApproversScreener(dataMemberGroups)
            });
        } catch (err) {
            console.log(err);
        }
    }

    const getEditApproversScreener = (dataMemberGroups) => {
        try {
            const fetchCarouselPromises = dataMemberGroups.map(value =>
                axios.get(`${apiGetApproversScreener}?documentGroupId=${value.levelOrganization}&groupId=${value.groupId}`)
            )
            Promise.all(fetchCarouselPromises).then((values) => {
                const dataEditApproversScreener = values.map((object: any, index) => (
                    object.data.map(item => {
                        return {
                            label: item.fullName,
                            value: item.employeeNo
                        };
                    })
                ))
                setDataApproversScreener(dataEditApproversScreener)
            });
        } catch (err) {
            console.log(err);
        }
    }

    /* feature template */
    const handleChange = (event) => {
        setPageTemplate(1)
        setSizeTemplate(parseInt(event.target.value));
    }

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageTemplate(value);
    }

    const pressSaveAsTemplate = () => {
        setRegarding(formData.regarding)
        setContentTemplate(formData.fileContent)
        setModalSaveAsTemplate(!modalSaveAsTemplate)
    }

    const pressUseAsTemplate = () => {
        if(!isAdmin && rbAccessLevelValue == "PUBLIC"){
            setRbAccessLevelId(!rbAccessLevelId)
            setRbAccessLevelValue("PRIVATE")
        }
        setModalUseAsTemplate(!modalUseAsTemplate)
    }
    const pressEditTemplate = () => {
        setModalEditTemplate(!modalEditTemplate)
    }

    const getDataTemplate = () => {
        axios.get(`/services/documentservice/api/template-document/getListTemplateDocumentByAccessLevel?accessLevel=${rbAccessLevelValue}&page=${pageTemplate}&size=${sizeTemplate}`)
            .then((res) => {

                setDataTemplate(res.data.data.content)
                let tempCollapsingData = []
                let tempSelectedData = []

                for (let i = 0; i < res.data.data.content.length; i++) {
                    tempCollapsingData.push({no: res.data.data.content[i].no, isShow: false})
                }

                for (let i = 0; i < res.data.data.content.length; i++) {
                    tempSelectedData.push({no: res.data.data.content[i].no, isSelected: 0, id: res.data.data.content[i].id, title: res.data.data.content[i].title})
                }

                setShowCollapsing(tempCollapsingData)
                setSelectedTemplate(tempSelectedData)
                const listSendToReplaceTemp = []
                try {

                    const item: any = res.data.data.content
                    const fetchCarouselPromises = item?.map(val =>
                        axios.get(`${apiGetDataSendTo}${val.documentSecurity}`)
                    )

                    Promise.all(fetchCarouselPromises).then((values) => {
                        const dataSendTo = values.map((object: any, index) => (
                            object.data.map(item => {
                                return {
                                    label: item.parameterName,
                                    value: item.parameterCode
                                };
                            })
                        ))

                        setListSendToReplace(dataSendTo)
                    });


                } catch (err) {
                    console.log(err);
                }

                setListSendToReplace(listSendToReplaceTemp)

                setPageTotalTemplate(res.data.data.totalPages)
            }).catch((err) => {
            toast.error(translate(`${err.response.data.message}`));
        })
    }

    const getEditTemplate = (item) => {

        setIdTemplate(item.id);
        setTitleTemplate(item.title);
        setContentTemplate(item.content);
        setFormDocumentNoEdit(
            {
                ...formDocumentNoEdit,
                documentNumber: item.documentNumber,
                documentCode: item.documentCode,
                groupId: item.groupId
            }
        )

        setFormDataEdit({
            ...formDataEdit,
            priority : item.priority,
            documentSecurity : item.documentSecurity,
            regarding : item.regarding,
            id: item.id,
            title: item.title,
            type: item.type,
            sendTo: item.sendTo,
            fileContent: item.content,
        })

        let dataRow = dataTemplate.filter(it => it.title == item.title)

        let to = []
        let cc = []
        let lineApproval = []
        let directSupervisor = []

        getDataSendToEdit(dataRow[0].documentSecurity)

        dataRow[0]?.to?.map(item => {
            to.push({
                levelOrganization:item.levelOrganizationId,
                levelOrganizationName:item.levelOrganizationName,
                approvers: item.employeeNo,
                approversName: item.employeeName,
                groupId: item.groupId,
                groupName: item.groupName
            })
        })

        dataRow[0]?.cc?.map(item => {
            cc.push({
                levelOrganization: item.levelOrganizationId,
                levelOrganizationName: item.levelOrganizationName,
                approvers: item.employeeNo,
                approversName: item.employeeName,
                groupId: item.groupId,
                groupName: item.groupName

            })
        })

        dataRow[0]?.lineApproval?.map(item => {
            lineApproval.push({
                levelOrganization: item.levelOrganizationId,
                levelOrganizationName: item.levelOrganizationName,
                approvers: item.employeeNo,
                approversName: item.employeeName,
                groupId: item.groupId,
                groupName: item.groupName

            })
        })

        if(rbAccessLevelValue == "PRIVATE") {
            dataRow[0]?.directSupervisor?.map(item => {
                directSupervisor.push({
                    levelOrganization: item.levelOrganizationId,
                    levelOrganizationName: item.levelOrganizationName,
                    approvers: item.employeeNo,
                    approversName: item.employeeName,
                    groupId: item.groupId,
                    groupName: item.groupName

                })
            })
        }

        setAddFieldToEdit(to)
        setAddFieldCCEdit(cc)
        setAddFieldEFEdit(lineApproval)

        if(rbAccessLevelValue == "PRIVATE") {
            setAddFieldScreenerEdit(directSupervisor)
            getLevelOrganizationScreener(dataRow[0].groupId)
        }


        if(rbAccessLevelValue == "PRIVATE") {
            try {

                const fetchCarouselPromises = directSupervisor.map(item => axios.get(`${apiGetOrganizationScreener}?documentGroupId=${dataRow[0].groupId}&levelOrganizationId=${item.levelOrganization}`))

                Promise.all(fetchCarouselPromises).then(values => {

                    const dataGroupScreener = values.map((object: any, index) => (
                        object.data.map(item => {
                            return {
                                label: item.groupName,
                                value: item.groupId
                            };
                        })
                    ))
                    setDataOrganizationScreener(dataGroupScreener)

                    const fetchCarouselPromises2 = directSupervisor.map(item => axios.get(`${apiGetApproversScreener}?documentGroupId=${dataRow[0].groupId}&groupId=${item.groupId}`))

                    Promise.all(fetchCarouselPromises2).then(values2 => {
                        const dataApprovers = values2.map((object: any, index) => (
                            object.data.map(item => {
                                return {
                                    label: item.fullName,
                                    value: item.employeeNo
                                };
                            })
                        ))
                        setDataApproversScreener(dataApprovers);
                    }).catch((err) => {
                        throw new Error('getApproversScreener')
                    })


                }).catch((err) => {
                    throw new Error('getOrganizationScreener')
                })

            } catch (err) {
                console.log(err);
            }
        }


        setModalEditTemplate(true)
    }

    const openDialogReplaceList = () => {
        setModalListReplace(true)
    }

    const pressReplaceTemplate = () => {

        let tempSelectedDatas = []

        for (let i = 0; i < isSelectedTemplate.length; i++) {
            tempSelectedDatas.push({no: isSelectedTemplate[i].no, isSelected: 0, id: isSelectedTemplate[i].id, title: isSelectedTemplate[i].title})
        }

        setSelectedTemplate(tempSelectedDatas)

        openDialogReplaceList()
    }

    const pressSaveTemplate = () => {

        setLoadingCheckTitle(true)

        if (titleTemplate == '' || contentTemplate == '') {
            toast.error('Title and Content cannot be empty');
            return
        }

        let titleFormData = new FormData()
        titleFormData.append("templateDocumentTitle", titleTemplate)
        titleFormData.append("accessLevel", rbAccessLevelValue)

        axios.post(`services/documentservice/api/template-document/is-exist-template-document-title`, titleFormData)
            .then(res1 => {
                if (res1.data.data.isExistTemplateDocument) {
                    setDuplicatedId(res1.data.data.id)
                    setLoadingCheckTitle(false)
                    showModalTemplateExist(true)
                } else {
                    if(idToReplace != null){
                        saveReplaceTemplate()
                    } else {
                        saveTemplate()
                    }
                }
            }).catch(err1 => {
            setLoadingCheckTitle(false)
            toast.error(translate(`${err1.response.data.message}`));
        })
    }

    const saveTemplate = () => {

        setLoadingCheckTitle(true)
        setLoadingTemplate(true)

        let toBody = []
        let lineAppBody = []
        let ccBody = []
        let directSupervisorBody = []

        addFieldTo.map(item => {
            toBody.push({
                levelOrganizationId:item.levelOrganization,
                levelOrganizationName:item.levelOrganizationName,
                groupId: item.groupId,
                groupName: item.groupName,
                employeeNo: item.approvers,
                employeeName: item.approversName
            })
        })
        addFieldCC.map(item => {
            ccBody.push({
                levelOrganizationId:item.levelOrganization,
                levelOrganizationName:item.levelOrganizationName,
                groupId: item.groupId,
                groupName: item.groupName,
                employeeNo: item.approvers,
                employeeName: item.approversName
            })
        })
        addFieldEF.map(item => {
            lineAppBody.push({
                levelOrganizationId:item.levelOrganization,
                levelOrganizationName:item.levelOrganizationName,
                groupId: item.groupId,
                groupName: item.groupName,
                employeeNo: item.approvers,
                employeeName: item.approversName
            })
        })

        if(rbAccessLevelValue == "PRIVATE") {
            addFieldScreener.map(item => {
                directSupervisorBody.push({
                    levelOrganizationId: item.levelOrganization,
                    levelOrganizationName: item.levelOrganizationName,
                    groupId: item.groupId,
                    groupName: item.groupName,
                    employeeNo: item.approvers,
                    employeeName: item.approversName
                })
            })
        }

        let saveTemplateBody =
            {
                "templateDocument": {
                    "id": null,
                    "title": titleTemplate,
                    "content": contentTemplate,
                    "documentSecurity": formData.documentSecurity,
                    "priority": formData.priority,
                    "regarding": formData.regarding,
                    "sendTo": formData.sendTo,
                    "groupId": formDocumentNo.groupId,
                    "accessLevel": isAdmin? rbAccessLevelValue : "PRIVATE"
                },
                "to": toBody,
                "lineApproval": lineAppBody,
                "cc": ccBody,
                "directSupervisor": rbAccessLevelValue == "PRIVATE" ? directSupervisorBody : null
            }

        axios.post(`services/documentservice/api/template-document/save`, saveTemplateBody)
            .then(res2 => {
                setTitleTemplate("")
                setContentTemplate("")
                showModalTemplateExist(false)
                setLoadingCheckTitle(false)
                setLoadingTemplate(false)
                toast.success('Success Save Template')
                setModalSaveAsTemplate(false)
                getDataTemplate()
                setIdToReplace(null)
                setDuplicatedId(null)
            })
            .catch(err2 => {
                showModalTemplateExist(false)
                setLoadingCheckTitle(false)
                setLoadingTemplate(false)
                toast.error(translate(`${err2.response.data.message}`));
            })
    }

    const pressSaveReplaceTemplate = () => {

        if (titleTemplate == '' || contentTemplate == '') {
            toast.error('Title and Content cannot be empty');
            return
        }

        if(idToReplace != null){
            saveReplaceTemplate()
        } else {
            setLoadingCheckTitle(true)

            let titleFormData = new FormData()
            titleFormData.append("templateDocumentTitle", titleTemplate)
            titleFormData.append("accessLevel", rbAccessLevelValue)

            axios.post(`services/documentservice/api/template-document/is-exist-template-document-title`, titleFormData)
                .then(res1 => {
                    if (res1.data.data.isExistTemplateDocument) {
                        setDuplicatedId(res1.data.data.id)
                        setLoadingCheckTitle(false)
                        showModalTemplateExist(true)
                    } else {
                        saveTemplate()
                        /*if(idToReplace != null){
                            saveReplaceTemplate()
                        } else {
                            saveTemplate()
                        }*/
                    }
                }).catch(err1 => {
                setLoadingCheckTitle(false)
                toast.error(translate(`${err1.response.data.message}`));
            })
        }
    }

    const saveReplaceTemplate = () => {

        let dataEdit = dataTemplate.filter(it => it.id == idToReplace)[0]

        if (titleTemplate == '' || contentTemplate == '') {
            toast.error('Title and Content cannot be empty');
            return;
        }

        let toBody = []
        let lineAppBody = []
        let ccBody = []
        let directSupervisorBody = []

        addFieldTo.map(item => {
            toBody.push({
                levelOrganizationId:item.levelOrganization,
                levelOrganizationName:item.levelOrganizationName,
                groupId: item.groupId,
                groupName: item.groupName,
                employeeNo: item.approvers,
                employeeName: item.approversName
            })
        })
        addFieldCC.map(item => {
            ccBody.push({
                levelOrganizationId:item.levelOrganization,
                levelOrganizationName:item.levelOrganizationName,
                groupId: item.groupId,
                groupName: item.groupName,
                employeeNo: item.approvers,
                employeeName: item.approversName
            })
        })
        addFieldEF.map(item => {
            lineAppBody.push({
                levelOrganizationId:item.levelOrganization,
                levelOrganizationName:item.levelOrganizationName,
                groupId: item.groupId,
                groupName: item.groupName,
                employeeNo: item.approvers,
                employeeName: item.approversName
            })
        })
        if(rbAccessLevelValue == "PRIVATE") {
            addFieldScreener.map(item => {
                directSupervisorBody.push({
                    levelOrganizationId: item.levelOrganization,
                    levelOrganizationName: item.levelOrganizationName,
                    groupId: item.groupId,
                    groupName: item.groupName,
                    employeeNo: item.approvers,
                    employeeName: item.approversName
                })
            })
        }

        let replaceTemplateBody =
            {
                "templateDocument": {
                    "id": idToReplace,
                    "title": titleToReplace,
                    "content": contentTemplate,
                    "documentSecurity": formData.documentSecurity,
                    "priority": formData.priority,
                    "regarding": formData.regarding,
                    "sendTo": formData.sendTo,
                    "groupId": formDocumentNo.groupId,
                    "accessLevel": isAdmin? rbAccessLevelValue : "PRIVATE"
                },
                "to": toBody,
                "lineApproval": lineAppBody,
                "cc": ccBody,
                "directSupervisor": rbAccessLevelValue == "PRIVATE" ? directSupervisorBody : null
            }

        axios.post(`services/documentservice/api/template-document/save`, replaceTemplateBody)
            .then(res2 => {
                setModalListReplace(false)
                toast.success('Success replace template')
                setModalSaveAsTemplate(false)
                setModalListReplace(true)
                getDataTemplate()
                setDuplicatedId(null)
                setIdToReplace(null)
            })
            .catch(err2 => {
                toast.error(translate(`${err2.response.data.message}`));
            })
    }
    const saveDuplicateTemplate = () => {

        let dataEdit = dataTemplate.filter(it => it.id == duplicatedId)[0]

        if (titleTemplate == '' || contentTemplate == '') {
            toast.error('Title and Content cannot be empty');
            return;
        }

        let toBody = []
        let lineAppBody = []
        let ccBody = []
        let directSupervisorBody = []

        addFieldTo.map(item => {
            toBody.push({
                levelOrganizationId:item.levelOrganization,
                levelOrganizationName:item.levelOrganizationName,
                groupId: item.groupId,
                groupName: item.groupName,
                employeeNo: item.approvers,
                employeeName: item.approversName
            })
        })
        addFieldCC.map(item => {
            ccBody.push({
                levelOrganizationId:item.levelOrganization,
                levelOrganizationName:item.levelOrganizationName,
                groupId: item.groupId,
                groupName: item.groupName,
                employeeNo: item.approvers,
                employeeName: item.approversName
            })
        })
        addFieldEF.map(item => {
            lineAppBody.push({
                levelOrganizationId:item.levelOrganization,
                levelOrganizationName:item.levelOrganizationName,
                groupId: item.groupId,
                groupName: item.groupName,
                employeeNo: item.approvers,
                employeeName: item.approversName
            })
        })
        if(rbAccessLevelValue == "PRIVATE") {
            addFieldScreener.map(item => {
                directSupervisorBody.push({
                    levelOrganizationId: item.levelOrganization,
                    levelOrganizationName: item.levelOrganizationName,
                    groupId: item.groupId,
                    groupName: item.groupName,
                    employeeNo: item.approvers,
                    employeeName: item.approversName
                })
            })
        }

        let replaceTemplateBody =
            {
                "templateDocument": {
                    "id": duplicatedId,
                    "title": titleTemplate,
                    "content": contentTemplate,
                    "documentSecurity": formData.documentSecurity,
                    "priority": formData.priority,
                    "regarding": formData.regarding,
                    "sendTo": formData.sendTo,
                    "groupId": formDocumentNo.groupId,
                    "accessLevel": isAdmin? rbAccessLevelValue : "PRIVATE"
                },
                "to": toBody,
                "lineApproval": lineAppBody,
                "cc": ccBody,
                "directSupervisor": rbAccessLevelValue == "PRIVATE" ? directSupervisorBody : null
            }

        axios.post(`services/documentservice/api/template-document/save`, replaceTemplateBody)
            .then(res2 => {
                setModalListReplace(false)
                toast.success('Success replace template')
                showModalTemplateExist(false)
                setModalSaveAsTemplate(false)
                setModalListReplace(true)
                getDataTemplate()
                setDuplicatedId(null)
                setIdToReplace(null)
            })
            .catch(err2 => {
                toast.error(translate(`${err2.response.data.message}`));
            })
    }

    /*const updateTemplate = () => {
        setLoadingCheckTitle(true)
        setLoadingTemplate(true)

        let toBody = []
        let lineAppBody = []
        let ccBody = []
        let directSupervisorBody = []


        addFieldToEdit.map(item => {
            toBody.push({
                levelOrganization:item.levelOrganization,
                levelOrganizationName:item.levelOrganizationName,
                employeeNo: item.approvers,
                employeeName: item.approversName,
                groupId: item.groupId,
                groupName: item.groupName
            })
        })
        addFieldCCEdit.map(item => {
            lineAppBody.push(
            {
                levelOrganization:item.levelOrganization,
                levelOrganizationName:item.levelOrganizationNam2e,
                employeeNo: item.approvers,
                employeeName: item.approversName,
                groupId: item.groupId,
                groupName: item.groupName

            })
        })
        addFieldEFEdit.map(item => {
            ccBody.push({
                levelOrganization: item.levelOrganization,
                levelOrganizationName: item.levelOrganizationName,
                employeeNo: item.approvers,
                employeeName: item.approversName,
                groupId: item.groupId,
                groupName: item.groupName

            })
        })

        addFieldScreenerEdit.map(item => {
            ccBody.push({
                levelOrganization: item.levelOrganization,
                levelOrganizationName: item.levelOrganizationName,
                employeeNo: item.approvers,
                employeeName: item.approversName,
                groupId: item.groupId,
                groupName: item.groupName
            })
        })

        let saveTemplateBody =
            {
                "templateDocument": {
                    "id": formDataEdit.id,
                    "title": formDataEdit.title,
                    "content": contentTemplate,
                    "documentSecurity": formData.documentSecurity,
                    "priority": formData.priority,
                    "regarding": formData.regarding
                },
                "to": toBody,
                "lineApproval": lineAppBody,
                "cc": ccBody,
                "directSupervisor": directSupervisorBody
            }

        axios.post(`services/documentservice/api/template-document/save`, saveTemplateBody)
            .then(res2 => {
                setTitleTemplate("")
                setContentTemplate("")
                showModalTemplateExist(false)
                setLoadingCheckTitle(false)
                setLoadingTemplate(false)
                toast.success('Success Save Template')
                setModalSaveAsTemplate(false)
                getDataTemplate()
            })
            .catch(err2 => {
                showModalTemplateExist(false)
                setLoadingCheckTitle(false)
                setLoadingTemplate(false)
                toast.error(translate(`${err2.response.data.message}`));
            })
    }*/

    /*const pressUpdateTemplate = () => {
        if (titleTemplate == '' || contentTemplate == '') {
            toast.error('Title and Content cannot be empty');
            return;
        }
        setLoadingTemplate(true)

        setLoadingCheckTitle(true)

        if (titleTemplate == '' || contentTemplate == '') {
            toast.error('Title and Content cannot be empty');
            return;
        }

        let titleFormData = new FormData()
        titleFormData.append("templateDocumentTitle", formDataEdit.title)

        axios.post(`services/documentservice/api/template-document/is-exist-template-document-title`, titleFormData)
            .then(res1 => {
                if (res1.data) {
                    setLoadingCheckTitle(false)
                    showModalTemplateExist(true)
                } else {
                    // updateTemplate()
                    setLoadingTemplate(false)
                }
            }).catch(err1 => {
            setLoadingCheckTitle(false)
            toast.error(translate(`${err1.response.data.message}`));
        })
    }*/

    const pressUseTemplate = () => {

        // console.log("use template")
        // return

        if(rbAccessLevelValue == "PRIVATE") {
            setFormData({
                ...formData,
                sendTo: formDataEdit?.sendTo,
                priority: formDataEdit?.priority,
                documentSecurity: formDataEdit?.documentSecurity,
                regarding: formDataEdit?.regarding,
                to: modifyData(addFieldToEdit, "branchCode", "employeeNo"),
                cc: modifyData(addFieldCCEdit, "branchCode", "employeeNo"),
                effluent: modifyData(addFieldEFEdit, "branchCode", "employeeNo"),
                screener: modifyData(addFieldScreenerEdit, "branchCode", "employeeNo"),
                fileContent: formDataEdit?.fileContent
            })
        } else {
            setFormData({
                ...formData,
                sendTo: formDataEdit?.sendTo,
                priority: formDataEdit?.priority,
                documentSecurity: formDataEdit?.documentSecurity,
                regarding: formDataEdit?.regarding,
                to: modifyData(addFieldToEdit, "branchCode", "employeeNo"),
                cc: modifyData(addFieldCCEdit, "branchCode", "employeeNo"),
                effluent: modifyData(addFieldEFEdit, "branchCode", "employeeNo"),
                fileContent: formDataEdit?.fileContent
            })
        }

        setTitleTemplate(formDataEdit.title)
        setContentTemplate(formDataEdit.fileContent)

        if(rbAccessLevelValue == "PRIVATE") {
            if (formDocumentNoEdit.groupId != null) {
                generateDocumentNumberByGroup(formDocumentNoEdit.groupId)
            }

            setFormDocumentNo(formDocumentNoEdit)
        }

        /*else {
            setFormDocumentNo({
                groupId: null,
                documentNumber: null,
                documentCode: null
            })
        }*/

        if(addFieldToEdit.length != 0 ) setAddFieldTo(addFieldToEdit)
        if(addFieldCCEdit.length != 0 )  setAddFieldCC(addFieldCCEdit)
        if(addFieldEFEdit.length != 0 )  setAddFieldEF(addFieldEFEdit)

        if(rbAccessLevelValue == "PRIVATE") {
            if (addFieldScreenerEdit.length != 0) setAddFieldScreener(addFieldScreenerEdit)
        }

        if(addFieldToEdit.length != 0 ) getEditOrganizationTo(addFieldToEdit)
        if(addFieldCCEdit.length != 0 ) getEditOrganizationCC(addFieldCCEdit)
        if(addFieldEFEdit.length != 0 ) getEditOrganizationEF(addFieldEFEdit)

        getDataSendTo(formDataEdit.documentSecurity)
        setModalEditTemplate(false)
        setModalUseAsTemplate(false)

        if(!isAdmin && rbAccessLevelValue == "PUBLIC"){
            setRbAccessLevelId(!rbAccessLevelId)
            setRbAccessLevelValue("PRIVATE")
        }


    }

    const pressDeleteTemplate = (item) => {
        setLoadingTemplate(true)
        axios.delete(`/services/documentservice/api/template-document/delete/${item.id}`)
            .then(res => {
                setIdTemplate(null)
                setTitleTemplate("")
                setContentTemplate("")
                setLoadingTemplate(false)
                toast.success('Success Delete Template')
                getDataTemplate()
            }).catch(err => {
            setIdTemplate(null)
            setTitleTemplate("")
            setContentTemplate("")
            setLoadingTemplate(false)
            toast.error(translate(`${err.response.data.message}`));
        })
    }

    const viewModalLoadingMerge= () => {
        return (<Modal isOpen={isLoadingMerge} centered={true} style={{width: 400}}>
            <ModalBody>
                <Lottie
                    style={{height: 250}}
                    options={defaultOptions}
                />

                <div className={"text-center mb-4 font-family-spoqa mb-0 font-size pl-2 pr-2"}
                     style={{fontSize: "12pt"}}>Your documents and files are being merged
                </div>

            </ModalBody>
        </Modal>)
    }

    const viewSaveAsTemplate = () => {
        return (
            <Modal isOpen={modalSaveAsTemplate} size="lg" centered={true} toggle={pressSaveAsTemplate}>
                <ModalHeader toggle={pressSaveAsTemplate}>
                    <div className="font-size-18 font-family-spoqa">
                        Save As Template
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="ml-2 mr-2 mt-3 font-family-spoqa">

                        <Modal isOpen={isShowModalTemplateExist} size="md" centered={true}>
                            <ModalBody>
                                <Col className={"p-2"}>
                                    <Row>
                                        <div className={"d-flex justify-content-center"}><DuplicateIllustration/></div>
                                    </Row>
                                    <Row className={"d-flex justify-content-center mt-2"}>
                                        <span className="font-family-spoqa"
                                              style={{color: "#0A0A0A", fontSize: "16px", textAlign: "center"}}>Duplicated File</span>
                                        <span className="font-family-spoqa mt-1"
                                              style={{color: "#9E9E9E", textAlign: "center", width: "350px"}}>The Template you created already exists, save as with a new file name or replace it</span>
                                    </Row>
                                    <Row className={"d-flex justify-content-center"}>
                                        <div className={"col d-flex justify-content-center mt-4 mb-4"}>
                                            <button style={{width: "142px", backgroundColor: "#F58220", color: "#FFF"}}
                                                    className={"btn mr-1"} disabled={isLoadingCheckTitle}
                                                    onClick={() => {
                                                        saveDuplicateTemplate()
                                                    }}>{isLoadingCheckTitle &&
                                                <Spinner color='#fff' size="sm" className='mr-1'/>} Replace
                                            </button>
                                            <button style={{width: "142px", backgroundColor: "#043B72", color: "#FFF"}}
                                                    className={"btn ml-1"}
                                                    onClick={() => showModalTemplateExist(false)}>Back
                                            </button>
                                        </div>
                                    </Row>
                                    </Col>
                            </ModalBody>
                        </Modal>

                        <div className="row ml-2 mr-2">
                            <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                                <div className='font-weight-bold'>
                                    Title Template
                                    <span className="required" style={{color: '#ff0000'}}>*</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-10" style={{alignSelf: 'center'}}>
                                <input
                                    className="form-control"
                                    value={titleTemplate}
                                    onChange={(e) => setTitleTemplate(e.target.value)}
                                />
                            </div>

                            {isAdmin && <div className='font-weight-bold d-flex d-inline mt-3'>
                                Template Access Level
                                <span className="required" style={{color: '#ff0000'}}>*</span>
                                <Row className="d-flex d-inline ml-2">
                                    <Col style={{maxWidth:"26px"}}>
                                        {rbAccessLevelId == false? <RadioSelectIcon/> :  <RadioUnselectIcon style={{cursor:"pointer"}} onClick={()=> {
                                            setRbAccessLevelId(!rbAccessLevelId)
                                            setRbAccessLevelValue("PRIVATE")
                                        }}/>}
                                    </Col>
                                    <Col style={{marginRight:"8px"}}>
                                        <div style={
                                            rbAccessLevelValue == "PRIVATE"? {maxWidth:"100px", marginTop:"2px"} : {maxWidth:"100px", marginTop:"2px", color:"#757575"}
                                        }>Private</div>
                                    </Col>
                                </Row>
                                <Row className="d-flex d-inline">
                                    <Col style={{maxWidth:"26px"}}>
                                        {rbAccessLevelId == true? <RadioSelectIcon/> :  <RadioUnselectIcon style={{cursor:"pointer"}} onClick={()=> {
                                            setRbAccessLevelId(!rbAccessLevelId)
                                            setRbAccessLevelValue("PUBLIC")
                                        }}/>}
                                    </Col>
                                    <Col>
                                        <div style={
                                            rbAccessLevelValue == "PUBLIC"? {maxWidth:"100px", marginTop:"2px"} : {maxWidth:"100px", marginTop:"2px", color:"#757575"}
                                        }>Public</div>
                                    </Col>

                                </Row>
                            </div>}
                        </div>

                        <div id="editorStyle" className="card">
                            <div className="card-body">
                                <div className={"col p-0"} style={{border: "1px solid #ced4da", borderRadius: "8px"}}>
                                    <div className={"row p-3"}>
                                        <div className={"mb-1"} style={{display: 'flex', flexDirection: 'row'}}>
                                            <div style={{width: '10%'}}
                                                 className='font-weight-bold font-size-12 d-flex align-items-center'>Priority
                                            </div>
                                            <div style={{width: '98%', paddingBottom: '5px', paddingTop: '5px'}}>
                                                <Select
                                                    isLoading={loadingDraft}
                                                    name="group"
                                                    value={priorityDocument.filter((function (option: any) {
                                                        return option.value == formData.priority
                                                    }))}
                                                    isDisabled={true}
                                                    placeholder=""
                                                    options={priorityDocument}
                                                    onChange={(e) => handleInput("priority", e)}
                                                />
                                            </div>
                                        </div>

                                        <div className={"mb-1"} style={{display: 'flex', flexDirection: 'row'}}>
                                            <div style={{width: '10%'}}
                                                 className='font-weight-bold font-size-12 d-flex align-items-center'>Document
                                                Security
                                            </div>
                                            <div style={{width: '98%', paddingBottom: '5px', paddingTop: '5px'}}>
                                                <Select
                                                    isLoading={loadingDraft}
                                                    name="group"
                                                    value={documentSecurity.filter((function (option: any) {
                                                        return option.value == formData.documentSecurity
                                                    }))}
                                                    isDisabled={true}
                                                    placeholder=""
                                                    options={documentSecurity}
                                                    onChange={(e) => handleInput("documentSecurity", e)}
                                                />
                                            </div>
                                        </div>

                                        <div className={"mb-1"} style={{display: 'flex', flexDirection: 'row'}}>
                                            <div style={{width: '10%'}}
                                                 className='font-weight-bold font-size-12 d-flex align-items-center'>Send To
                                            </div>
                                            <div style={{width: '98%', paddingBottom: '5px', paddingTop: '5px'}}>
                                                <Select
                                                    isLoading={loadingDraft}
                                                    name="group"
                                                    value={listSendTo.filter((function (option: any) {
                                                        return option.value == formData.sendTo
                                                    }))}
                                                    isDisabled={true}
                                                    placeholder=""
                                                    options={listSendTo}
                                                    onChange={(e) => handleInput("documentSecurity", e)}
                                                />
                                            </div>
                                        </div>

                                        {addFieldTo.map((toElement, index) => {
                                            let valLength = addFieldTo.length - 1;
                                            return (
                                                <div key={index} className='mb-1'
                                                     style={{display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{width: '10%'}}
                                                         className='font-weight-bold font-size-12 d-flex align-items-center'>{index == 0 ? "To" : ""}</div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={dataLevelOrganization.filter((function (option: any) {
                                                                return option.value == toElement.levelOrganization
                                                            }))}
                                                            placeholder=""
                                                            options={dataLevelOrganization}
                                                            onChange={(e) => handleSelectTo('levelOrganization', index, e)}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={dataOrganizationTo[index] == undefined ? null : dataOrganizationTo[index]?.filter((function (option: any) {
                                                                return option.value == toElement.groupId
                                                            }))}
                                                            placeholder=""
                                                            options={dataOrganizationTo[index]}
                                                            onChange={(e) => handleSelectTo('groupId', index, e)}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{
                                                        width: '35%',
                                                        paddingLeft: '5px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    }}>
                                                        <Select
                                                            name="group"
                                                            value={dataApproversTo[index] == undefined ? null : dataApproversTo[index]?.filter((function (option: any) {
                                                                return option.value == toElement.approvers
                                                            }))}
                                                            placeholder=""
                                                            options={dataApproversTo[index]}
                                                            onChange={(e) => handleSelectTo('approvers', index, e)}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        {addFieldEF.map((efElement, index) => {
                                            let valLength = addFieldCC.length - 1;
                                            return (
                                                <div key={index} className='mb-1'
                                                     style={{display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{width: '10%'}}
                                                         className='font-weight-bold font-size-12 d-flex align-items-center'>{index == 0 ? "Line Approval" : ""}</div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={dataLevelOrganization.filter((function (option: any) {
                                                                return option.value == efElement.levelOrganization
                                                            }))}
                                                            placeholder=""
                                                            options={dataLevelOrganization}
                                                            onChange={(e) => handleSelectEF('levelOrganization', index, e)}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={dataOrganizationEF[index] == undefined ? null : dataOrganizationEF[index]?.filter((function (option: any) {
                                                                return option.value == efElement.groupId
                                                            }))}
                                                            placeholder=""
                                                            options={dataOrganizationEF[index]}
                                                            onChange={(e) => handleSelectEF('groupId', index, e)}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{
                                                        width: '35%',
                                                        paddingLeft: '5px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    }}>
                                                        <Select
                                                            name="group"
                                                            value={dataApproversEF[index] == undefined ? null : dataApproversEF[index]?.filter((function (option: any) {
                                                                return option.value == efElement.approvers
                                                            }))}
                                                            placeholder=""
                                                            options={dataApproversEF[index]}
                                                            onChange={(e) => handleSelectEF('approvers', index, e)}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        {addFieldCC.map((ccElement, index) => {
                                            let valLength = addFieldCC.length - 1;
                                            return (
                                                <div key={index} className='mb-1'
                                                     style={{display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{width: '10%'}}
                                                         className='font-weight-bold font-size-12 d-flex align-items-center'>{index == 0 ? "CC" : ""}</div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={dataLevelOrganization.filter((function (option: any) {
                                                                return option.value == ccElement.levelOrganization
                                                            }))}
                                                            placeholder=""
                                                            options={dataLevelOrganization}
                                                            onChange={(e) => handleSelectCC('levelOrganization', index, e)}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={dataOrganizationCC[index] == undefined ? null : dataOrganizationCC[index]?.filter((function (option: any) {
                                                                return option.value == ccElement.groupId
                                                            }))}
                                                            placeholder=""
                                                            options={dataOrganizationCC[index]}
                                                            onChange={(e) => handleSelectCC('groupId', index, e)}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{
                                                        width: '35%',
                                                        paddingLeft: '5px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    }}>
                                                        <Select
                                                            name="group"
                                                            value={dataApproversCC[index] == undefined ? null : dataApproversCC[index]?.filter((function (option: any) {
                                                                return option.value == ccElement.approvers
                                                            }))}
                                                            placeholder=""
                                                            options={dataApproversCC[index]}
                                                            onChange={(e) => handleSelectCC('approvers', index, e)}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        {addFieldScreener.map((screenerElement, index) => {
                                            if(rbAccessLevelValue == "PUBLIC"){
                                                return
                                            }
                                            let valLength = addFieldScreener.length - 1;
                                            return (
                                                <div key={index} className='mb-1'
                                                     style={{display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{width: '10%'}}
                                                         className='font-weight-bold font-size-12 d-flex align-items-center'>{index == 0 ? "Direct Supervisor" : ""}</div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={dataLOScreener.filter((function (option: any) {
                                                                return option.value == screenerElement.levelOrganization
                                                            }))}
                                                            placeholder=""
                                                            options={dataLOScreener}
                                                            onChange={(e) => handleSelectScreener('levelOrganization', index, e)}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={dataOrganizationScreener[index] == undefined ? null : dataOrganizationScreener[index]?.filter((function (option: any) {
                                                                return option.value == screenerElement.groupId
                                                            }))}
                                                            placeholder=""
                                                            options={dataOrganizationScreener[index]}
                                                            onChange={(e) => handleSelectScreener('groupId', index, e)}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{
                                                        width: '35%',
                                                        paddingLeft: '5px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    }}>
                                                        <Select
                                                            name="group"
                                                            value={dataApproversScreener[index] == undefined ? null : dataApproversScreener[index]?.filter((function (option: any) {
                                                                return option.value == screenerElement.approvers
                                                            }))}
                                                            placeholder=""
                                                            options={dataApproversScreener[index]}
                                                            onChange={(e) => handleSelectScreener('approvers', index, e)}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>

                                    <div className={"row pl-3 pr-3"}>
                                        <div className={"col-12 d-inline-flex align-items-center"}>
                                            <span
                                                className={"mr-2 font-weight-bold font-size-12 d-flex align-items-center"}>Regarding</span>
                                            <input
                                                disabled={true}
                                                className="form-control"
                                                value={regarding}
                                                onChange={(e) => setRegarding(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className={"row"} style={{
                                        paddingLeft: "28px",
                                        paddingRight: "28px",
                                        paddingBottom: "16px",
                                        paddingTop: "16px"
                                    }}>
                                        <Editor
                                            apiKey="ofyv4jj2oacut7go4bv4c112ro9uuupgfqcuj3docuzk1ib1"
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            // initialValue={initialValue}
                                            value={contentTemplate}
                                            onEditorChange={(e) => setContentTemplate(e)}
                                            // disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                                            disabled={true}
                                            init={{
                                                height: 400,
                                                menubar: true,
                                                paste_data_images: true,
                                                forced_root_block_attrs: {
                                                    'style': 'font-size: 10pt;'
                                                },
                                                forced_root_block: 'div',
                                                force_br_newlines: true,
                                                force_p_newlines: false,
                                                force_div_newlines: false,
                                                table_row_advtab: true,
                                                table_cell_advtab: true,
                                                formats: {
                                                    bold: {inline: 'b'},
                                                    italic: {inline: 'i'},
                                                    underline: {inline: 'u'}
                                                },
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor ',
                                                    'searchreplace visualblocks fullscreen ',
                                                    'insertdatetime media table paste code help wordcount hr ',
                                                    'anchor autolink charmap codesample ',
                                                    'emoticons image link lists media table visualblocks ',
                                                    'wordcount ',
                                                    'autosave tablesplitcells tablemergecells tablecellborderstyle ',
                                                    'tablecellprops tablerowprops'
                                                ],
                                                toolbar: 'undo redo | fontsizeselect |  formatselect | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    ' help hr' +
                                                    'blocks fontfamily fontsize | underline strikethrough | link image media table mergetags | ' +
                                                    'align lineheight | checklist numlist | ' +
                                                    'emoticons charmap | removeformat | tablesplitcells tablemergecells tablecellborderstyle tablecellprops tablerowprops',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12pt }',
                                                table_border_styles: [
                                                    {title: 'Solid', value: 'solid'},
                                                    {title: 'Dotted', value: 'dotted'},
                                                    {title: 'Dashed', value: 'dashed'},
                                                    {title: 'Double', value: 'double'},
                                                    {title: 'Groove', value: 'groove'},
                                                    {title: 'Ridge', value: 'ridge'},
                                                    {title: 'Inset', value: 'inset'},
                                                    {title: 'Outset', value: 'outset'},
                                                    {title: 'None', value: 'none'},
                                                    {title: 'Hidden', value: 'hidden'}
                                                ]
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {/*<Button
                id="save-as-template" type="submit"
                style={{ backgroundColor: "#002F48", color: "#fff" }}
                disabled={loadingTemplate}
                onClick={() => pressSaveAsTemplate()}>
              <span>Back</span>
            </Button>*/}

                    <Button type="submit"
                            style={{height: "37px", width: "142px", backgroundColor: "#043B72", color: "#FFF"}}
                            className={"btn ml-1"} onClick={() => {
                        pressReplaceTemplate()
                    }}><ReplaceIcon/> Replace</Button>
                    <Button
                        id="use-a-template" type="submit"
                        style={{borderColor: "#F37F26", backgroundColor: "#F37F26", color: "#fff"}}
                        disabled={loadingTemplate}
                        onClick={() =>
                            pressSaveTemplate()
                        }>
            <span>
              {loadingTemplate ? <Spinner color='#fff' size="sm" className='mr-1'/> : ""}
                Save Template
            </span>
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    const viewListReplaceTemplate = () => {
        return (
            <Modal isOpen={modalListReplace} size="lg" centered={true}>
                <ModalHeader toggle={() => setModalListReplace(false)}>
                    <div className="font-size-18 font-family-spoqa">Replace</div>
                    <div className="font-size-12 font-family-spoqa">Select template document you want to replace</div>
                </ModalHeader>
                <ModalBody>
                    <div className="font-family-spoqa">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-centered mb-0">
                                        <thead style={{background: "#043B72", color: "#FFFFFF"}}>
                                        <tr>
                                            <th>No.</th>
                                            <th>List Template</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            dataTemplate.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td style={{
                                                                wordBreak: 'break-all',
                                                                minWidth: '80px'
                                                            }}>{item.no}</td>
                                                            <td style={{
                                                                wordBreak: 'break-all',
                                                                minWidth: '250px',
                                                                maxWidth: '250px'
                                                            }}>{item.title}</td>
                                                            <td style={{wordBreak: 'break-all', width: '200px'}}>
                                      <span style={{cursor: "pointer"}} className={"mr-4"} onClick={() => {
                                          let tempCollapsingDatas = []
                                          for (let i = 0; i < isShowCollapsing.length; i++) {
                                              tempCollapsingDatas.push({
                                                  no: isShowCollapsing[i].no,
                                                  isShow: isShowCollapsing[index].isShow
                                              })
                                          }

                                          for (let i = 0; i < isShowCollapsing.length; i++) {
                                              if (tempCollapsingDatas[i].no == isShowCollapsing[index].no) {
                                                  tempCollapsingDatas[i].isShow = !isShowCollapsing[index].isShow
                                              } else {
                                                  tempCollapsingDatas[i].isShow = false
                                              }
                                          }

                                          setShowCollapsing(tempCollapsingDatas)
                                      }}>
                                          <FontAwesomeIcon icon={isShowCollapsing[index]?.isShow ? faEye : faEyeSlash}/>
                                      </span>

                                                                {isSelectedTemplate[index]?.isSelected == 0 &&
                                                                    <button
                                                                        className='btn mr-2'
                                                                        style={{
                                                                            backgroundColor: '#F37F26',
                                                                            color: '#FFFFFF'
                                                                        }}
                                                                        onClick={() => {
                                                                            let tempSelectedDatas = []

                                                                            for (let i = 0; i < isSelectedTemplate.length; i++) {
                                                                                tempSelectedDatas.push({
                                                                                    no: isSelectedTemplate[i].no,
                                                                                    isSelected: 1,
                                                                                    id: isSelectedTemplate[i].id,
                                                                                    title: isSelectedTemplate[i].title
                                                                                })
                                                                            }

                                                                            for (let i = 0; i < tempSelectedDatas.length; i++) {
                                                                                if (tempSelectedDatas[i].no == isSelectedTemplate[index].no) {
                                                                                    tempSelectedDatas[i].isSelected = 1
                                                                                    setIdToReplace(isSelectedTemplate[i].id)
                                                                                    setTitleToReplace(isSelectedTemplate[i].title)
                                                                                } else {
                                                                                    tempSelectedDatas[i].isSelected = 2
                                                                                }
                                                                            }

                                                                            setSelectedTemplate(tempSelectedDatas)
                                                                        }}>
                                                                        Select
                                                                    </button>}

                                                                {isSelectedTemplate[index]?.isSelected == 1 &&
                                                                    <button
                                                                        className='btn mr-2'
                                                                        style={{
                                                                            background: '#043B72',
                                                                            color: '#FFFFFF'
                                                                        }}
                                                                        onClick={() => {
                                                                            let tempSelectedDatas = []

                                                                            for (let i = 0; i < isSelectedTemplate.length; i++) {
                                                                                tempSelectedDatas.push({
                                                                                    no: isSelectedTemplate[i].no,
                                                                                    isSelected: 1,
                                                                                    id: isSelectedTemplate[i].id,
                                                                                    id: isSelectedTemplate[i].title
                                                                                })
                                                                            }

                                                                            for (let i = 0; i < tempSelectedDatas.length; i++) {
                                                                                if (tempSelectedDatas[i].no == isSelectedTemplate[index].no) {
                                                                                    tempSelectedDatas[i].isSelected = 1
                                                                                    setIdToReplace(isSelectedTemplate[i].id)
                                                                                } else {
                                                                                    tempSelectedDatas[i].isSelected = 2
                                                                                }
                                                                            }

                                                                            setSelectedTemplate(tempSelectedDatas)
                                                                        }}>
                                                                        Select
                                                                    </button>}

                                                                {isSelectedTemplate[index]?.isSelected == 2 &&
                                                                    <button
                                                                        className='btn mr-2'
                                                                        style={{
                                                                            backgroundColor: '#C2C2C2',
                                                                            color: '#FFFFFF'
                                                                        }}
                                                                        onClick={() => {
                                                                            let tempSelectedDatas = []

                                                                            for (let i = 0; i < isSelectedTemplate.length; i++) {
                                                                                tempSelectedDatas.push({
                                                                                    no: isSelectedTemplate[i].no,
                                                                                    isSelected: 1,
                                                                                    id: isSelectedTemplate[i].id,
                                                                                    title: isSelectedTemplate[i].title
                                                                                })
                                                                            }

                                                                            for (let i = 0; i < tempSelectedDatas.length; i++) {
                                                                                if (tempSelectedDatas[i].no == isSelectedTemplate[index].no) {
                                                                                    tempSelectedDatas[i].isSelected = 1
                                                                                    setIdToReplace(isSelectedTemplate[i].id)
                                                                                } else {
                                                                                    tempSelectedDatas[i].isSelected = 2
                                                                                }
                                                                            }

                                                                            setSelectedTemplate(tempSelectedDatas)
                                                                        }}>
                                                                        Select
                                                                    </button>}
                                                            </td>
                                                        </tr>
                                                        <tr className={isShowCollapsing[index]?.isShow ? "d-show" : "d-none"}>
                                                            <td colspan={"3"}
                                                                style={{display: isShowCollapsing[index]?.isShow ? "table-cell" : "table-row-group"}}>
                                                                {/*<Collapse
                                        isOpen={isShowCollapsing[index]?.isShow}>*/}

                                                                <div
                                                                    className={"col d-inline-flex align-items-center p-0 mb-3"}>
                                                                    <span className={"mr-2"}>Title Template</span>
                                                                    <input
                                                                        className="form-control"
                                                                        value={item.title}
                                                                        disabled={true}
                                                                        // onChange={(e) => setTitleTemplate(e.target.value)}
                                                                    />
                                                                </div>

                                                                <div className={"col p-0"} style={{
                                                                    border: "1px solid #ced4da",
                                                                    borderRadius: "8px"
                                                                }}>
                                                                    <div className={"row p-3"}>
                                                                        <div className={"mb-1"} style={{display: 'flex', flexDirection: 'row'}}>
                                                                            <div style={{width: '15%'}}
                                                                                 className='font-weight-bold font-size-12 d-flex align-items-center'>Priority
                                                                            </div>
                                                                            <div style={{width: '98%', paddingBottom: '5px', paddingTop: '5px'}}>
                                                                                <Select
                                                                                    isLoading={loadingDraft}
                                                                                    name="group"
                                                                                    value={priorityDocument.filter((function (option: any) {
                                                                                        return option.value == item.priority
                                                                                    }))}
                                                                                    isDisabled={true}
                                                                                    placeholder="Select Priority Document"
                                                                                    options={priorityDocument}
                                                                                    onChange={(e) => handleInput("priority", e)}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className={"mb-1"} style={{display: 'flex', flexDirection: 'row'}}>
                                                                            <div style={{width: '15%'}}
                                                                                 className='font-weight-bold font-size-12 d-flex align-items-center'>Document
                                                                                Security
                                                                            </div>
                                                                            <div style={{width: '98%', paddingBottom: '5px', paddingTop: '5px'}}>
                                                                                <Select
                                                                                    isLoading={loadingDraft}
                                                                                    name="group"
                                                                                    value={documentSecurity.filter((function (option: any) {
                                                                                        return option.value == item.documentSecurity
                                                                                    }))}
                                                                                    isDisabled={true}
                                                                                    placeholder="Select Document Security"
                                                                                    options={documentSecurity}
                                                                                    onChange={(e) => handleInput("documentSecurity", e)}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className={"mb-1"} style={{display: 'flex', flexDirection: 'row'}}>
                                                                            <div style={{width: '15%'}}
                                                                                 className='font-weight-bold font-size-12 d-flex align-items-center'>Send To
                                                                            </div>
                                                                            <div style={{width: '98%', paddingBottom: '5px', paddingTop: '5px'}}>
                                                                                <Select
                                                                                    isLoading={loadingDraft}
                                                                                    name="group"
                                                                                    value={listSendToReplace[index]?.filter((function (option: any) {
                                                                                        return option.value == item.sendTo
                                                                                    }))}
                                                                                    isDisabled={true}
                                                                                    placeholder="Select Document Security"
                                                                                    options={listSendToReplace[index]}
                                                                                    onChange={(e) => handleInput("documentSecurity", e)}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        {item?.to?.map((toElement, index) => {
                                                                            let valLength = addFieldTo.length - 1;
                                                                            return (
                                                                                <div key={index} className='mb-1'
                                                                                     style={{display: 'flex', flexDirection: 'row'}}>
                                                                                    <div style={{width: '15%'}}
                                                                                         className='font-weight-bold font-size-12 d-flex align-items-center'>{index == 0 ? "To" : ""}</div>
                                                                                    <div style={{width: '35%', padding: '5px'}}>
                                                                                        <Select
                                                                                            name="group"
                                                                                            value={[{label: toElement.levelOrganizationName, value: toElement.levelOrganization}]}
                                                                                            isDisabled={true}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{width: '35%', padding: '5px'}}>
                                                                                        <Select
                                                                                            name="group"
                                                                                            value={[{label: toElement.groupName, value: toElement.groupId}]}
                                                                                            isDisabled={true}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{
                                                                                        width: '35%', paddingLeft: '5px', paddingTop: '5px', paddingBottom: '5px'
                                                                                    }}>
                                                                                        <Select
                                                                                            name="group"
                                                                                            value={[{label: toElement.employeeName, value: toElement.employeeNo}]}
                                                                                            isDisabled={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}

                                                                        {item?.lineApproval?.map((efElement, index) => {
                                                                            let valLength = addFieldCC.length - 1;
                                                                            return (
                                                                                <div key={index} className='mb-1'
                                                                                     style={{display: 'flex', flexDirection: 'row'}}>
                                                                                    <div style={{width: '15%'}}
                                                                                         className='font-weight-bold font-size-12 d-flex align-items-center'>{index == 0 ? "Line Approval" : ""}</div>
                                                                                    <div style={{width: '35%', padding: '5px'}}>
                                                                                        <Select
                                                                                            name="group"
                                                                                            value={{label: efElement.levelOrganizationName, value: efElement.levelOrganizationId}}
                                                                                            isDisabled={true}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{width: '35%', padding: '5px'}}>
                                                                                        <Select
                                                                                            name="group"
                                                                                            value={{label: efElement.groupName, value: efElement.groupId}}
                                                                                            isDisabled={true}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{
                                                                                        width: '35%',
                                                                                        paddingLeft: '5px', paddingTop: '5px', paddingBottom: '5px'
                                                                                    }}>
                                                                                        <Select
                                                                                            name="group"
                                                                                            value={{label: efElement.employeeName, value: efElement.employeeNo}}
                                                                                            isDisabled={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}

                                                                        {item?.cc?.map((ccElement, index) => {
                                                                            let valLength = addFieldCC.length - 1;
                                                                            return (
                                                                                <div key={index} className='mb-1'
                                                                                     style={{display: 'flex', flexDirection: 'row'}}>
                                                                                    <div style={{width: '15%'}}
                                                                                         className='font-weight-bold font-size-12 d-flex align-items-center'>{index == 0 ? "CC" : ""}</div>
                                                                                    <div style={{width: '35%', padding: '5px'}}>
                                                                                        <Select
                                                                                            name="group"
                                                                                            value={{label: ccElement.levelOrganizationName, value: ccElement.levelOrganizationId}}
                                                                                            isDisabled={true}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{width: '35%', padding: '5px'}}>
                                                                                        <Select
                                                                                            name="group"
                                                                                            value={{label: ccElement.groupName, value: ccElement.groupId}}
                                                                                            isDisabled={true}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{
                                                                                        width: '35%', paddingLeft: '5px', paddingTop: '5px', paddingBottom: '5px'
                                                                                    }}>
                                                                                        <Select
                                                                                            name="group"
                                                                                            value={{label: ccElement.employeeName, value: ccElement.employeeNo}}
                                                                                            isDisabled={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}

                                                                        {item?.directSupervisor?.map((screenerElement, index) => {
                                                                            let valLength = addFieldScreener.length - 1;
                                                                            return (
                                                                                <div key={index} className='mb-1'
                                                                                     style={{display: 'flex', flexDirection: 'row'}}>
                                                                                    <div style={{width: '15%'}}
                                                                                         className='font-weight-bold font-size-12 d-flex align-items-center'>{index == 0 ? "Direct Supervisor" : ""}</div>
                                                                                    <div style={{width: '35%', padding: '5px'}}>
                                                                                        <Select
                                                                                            name="group"
                                                                                            value={{label: screenerElement.levelOrganizationName, value: screenerElement.levelOrganizationId}}
                                                                                            isDisabled={true}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{width: '35%', padding: '5px'}}>
                                                                                        <Select
                                                                                            name="group"
                                                                                            value={{label: screenerElement.groupName, value: screenerElement.groupId}}
                                                                                            isDisabled={true}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{
                                                                                        width: '35%', paddingLeft: '5px', paddingTop: '5px', paddingBottom: '5px'
                                                                                    }}>
                                                                                        <Select
                                                                                            name="group"
                                                                                            value={{label: screenerElement.employeeName, value: screenerElement.employeeNo}}
                                                                                            isDisabled={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}

                                                                    </div>

                                                                    <div className={"row"} style={{
                                                                        paddingLeft: "28px",
                                                                        paddingRight: "28px",
                                                                        paddingBottom: "16px",
                                                                        paddingTop: "16px"
                                                                    }}>
                                                                        <Editor
                                                                            apiKey="ofyv4jj2oacut7go4bv4c112ro9uuupgfqcuj3docuzk1ib1"
                                                                            onInit={(evt, editor) => editorRef.current = editor}
                                                                            // initialValue={initialValue}
                                                                            value={item.content}
                                                                            disabled={true}
                                                                            // onEditorChange={(e) => setContentTemplate(e)}
                                                                            // disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                                                                            init={{
                                                                                height: 400,
                                                                                menubar: true,
                                                                                paste_data_images: true,
                                                                                forced_root_block_attrs: {
                                                                                    'style': 'font-size: 10pt;'
                                                                                },
                                                                                forced_root_block: 'div',
                                                                                force_br_newlines: true,
                                                                                force_p_newlines: false,
                                                                                force_div_newlines: false,
                                                                                table_row_advtab: true,
                                                                                table_cell_advtab: true,
                                                                                formats: {
                                                                                    bold: {inline: 'b'},
                                                                                    italic: {inline: 'i'},
                                                                                    underline: {inline: 'u'}
                                                                                },
                                                                                plugins: [
                                                                                    'advlist autolink lists link image charmap print preview anchor ',
                                                                                    'searchreplace visualblocks fullscreen ',
                                                                                    'insertdatetime media table paste code help wordcount hr ',
                                                                                    'anchor autolink charmap codesample ',
                                                                                    'emoticons image link lists media table visualblocks ',
                                                                                    'wordcount ',
                                                                                    'autosave tablesplitcells tablemergecells tablecellborderstyle ',
                                                                                    'tablecellprops tablerowprops'
                                                                                ],
                                                                                toolbar: 'undo redo | fontsizeselect |  formatselect | ' +
                                                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                                    ' help hr' +
                                                                                    'blocks fontfamily fontsize | underline strikethrough | link image media table mergetags | ' +
                                                                                    'align lineheight | checklist numlist | ' +
                                                                                    'emoticons charmap | removeformat | tablesplitcells tablemergecells tablecellborderstyle tablecellprops tablerowprops',
                                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12pt }',
                                                                                table_border_styles: [
                                                                                    {title: 'Solid', value: 'solid'},
                                                                                    {title: 'Dotted', value: 'dotted'},
                                                                                    {title: 'Dashed', value: 'dashed'},
                                                                                    {title: 'Double', value: 'double'},
                                                                                    {title: 'Groove', value: 'groove'},
                                                                                    {title: 'Ridge', value: 'ridge'},
                                                                                    {title: 'Inset', value: 'inset'},
                                                                                    {title: 'Outset', value: 'outset'},
                                                                                    {title: 'None', value: 'none'},
                                                                                    {title: 'Hidden', value: 'hidden'}
                                                                                ]
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>


                                                                {/*</Collapse>*/}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div style={{marginRight: "20px", marginBottom: "20px"}}
                                 className={"d-flex justify-content-end"}>
                                <Button
                                    disabled={(isSelectedTemplate.filter(it => it.isSelected == 1).length > 0) ? false : true}
                                    type="submit" style={{
                                    width: "142px",
                                    backgroundColor: (isSelectedTemplate.filter(it => it.isSelected == 1).length > 0) ? "#043B72" : "#C2C2C2",
                                    color: "#FFF",
                                    borderColor: "#FFFFFF00"
                                }} className={"btn ml-1"} onClick={() => {
                                    pressSaveReplaceTemplate()
                                }}><ReplaceIcon/> Replace</Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                                <div style={{display: 'flex', paddingLeft: "24px"}}>
                                    <span className="mt-3 mx-2">Show</span>
                                    <span>
                    <FormControl variant="standard" sx={{m: 1, minWidth: 50}}>
                      <SelectMui
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={sizeTemplate}
                          onChange={handleChange}
                          label="Limit">
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </SelectMui>
                    </FormControl>
                  </span>
                                    <span className="mt-3 mx-2">
                    Entries
                  </span>
                                </div>
                            </div>
                            <div className="col-lg-5 col-sm-12 col-xs-12 my-2"
                                 style={{display: 'flex', justifyContent: 'end', paddingRight: "24px"}}>
                                <Pagination count={pageTotalTemplate} color="primary" page={pageTemplate}
                                            showFirstButton showLastButton onChange={handleChangePage}/>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    const viewModalSuccess = () => {
        return (
            <Modal isOpen={modalSuccessTemplate} size="md" centered={true}>
                <ModalBody>
                    <Col className={"pl-5 pr-5 pb-4 pt-4"}>
                        <Row className={"mb-2"}>
                            <span className={"d-flex justify-content-center"}><SuccessReplaceIllustration/></span>
                        </Row>
                        <Row className={"mb-2"}>
                            <span className={"d-flex justify-content-center font-family-spoqa"}
                                  style={{fontSize: "24px", fontStyle: "bold"}}>Replace File Succeed</span>
                        </Row>
                        <Row>
                            <button onClick={() => {
                                setModalSuccessTemplate(false)
                            }} className='btn btn-primary'>Back
                            </button>
                        </Row>
                    </Col>
                </ModalBody>
            </Modal>
        )
    }

    const viewUseAsTemplate = () => {
        return (
            <Modal isOpen={modalUseAsTemplate} size="lg" centered={true} toggle={pressUseAsTemplate}>
                <ModalHeader toggle={pressUseAsTemplate}>
                    <div className="font-size-18 font-family-spoqa">Use As Template</div>
                    <div className="font-size-12 font-family-spoqa">Select template document you want to create</div>
                </ModalHeader>
                <ModalBody>
                    <div className="ml-3 mr-3 mt-3 font-family-spoqa">
                        <div className="card">
                            <div className={"d-flex ml-4"}>
                                <div className={"d-flex d-inline mr-4"}>Template Access Level</div>
                                <Row className="d-flex d-inline">
                                    <Col style={{maxWidth:"26px"}}>
                                        {rbAccessLevelId == false? <RadioSelectIcon/> :  <RadioUnselectIcon style={{cursor:"pointer"}} onClick={()=> {
                                            setRbAccessLevelId(!rbAccessLevelId)
                                            setRbAccessLevelValue("PRIVATE")
                                        }}/>}
                                    </Col>
                                    <Col style={{marginRight:"8px"}}>
                                        <div style={
                                            rbAccessLevelValue == "PRIVATE"? {maxWidth:"100px", marginTop:"2px"} : {maxWidth:"100px", marginTop:"2px", color:"#757575"}
                                        }>Private</div>
                                    </Col>
                                </Row>
                                <Row className="d-flex d-inline">
                                    <Col style={{maxWidth:"26px"}}>
                                        {rbAccessLevelId == true? <RadioSelectIcon/> :  <RadioUnselectIcon style={{cursor:"pointer"}} onClick={()=> {
                                            setRbAccessLevelId(!rbAccessLevelId)
                                            setRbAccessLevelValue("PUBLIC")
                                        }}/>}
                                    </Col>
                                    <Col>
                                        <div style={
                                            rbAccessLevelValue == "PUBLIC"? {maxWidth:"100px", marginTop:"2px"} : {maxWidth:"100px", marginTop:"2px", color:"#757575"}
                                        }>Public</div>
                                    </Col>

                                </Row>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-centered mb-0">
                                        <thead className="thead-light">
                                        <tr>
                                            <th>No.</th>
                                            <th>List Template</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            dataTemplate.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td style={{
                                                            wordBreak: 'break-all',
                                                            minWidth: '80px'
                                                        }}>{item.no}</td>
                                                        <td style={{
                                                            wordBreak: 'break-all',
                                                            minWidth: '250px'
                                                        }}>{item.title}</td>
                                                        <td style={{wordBreak: 'break-all', width: '200px'}}>
                                                            {isAdmin && <button
                                                                className='btn mr-2'
                                                                style={{
                                                                    backgroundColor: '#EEB4B0',
                                                                    borderColor: '#EEB4B0',
                                                                    color: '#CB3A31'
                                                                }}
                                                                onClick={() => pressDeleteTemplate(item)}>
                                                                <FontAwesomeIcon icon="trash"/>{' '}Delete
                                                            </button>}

                                                            {(!isAdmin && rbAccessLevelValue == "PRIVATE") && <button
                                                                className='btn mr-2'
                                                                style={{
                                                                    backgroundColor: '#EEB4B0',
                                                                    borderColor: '#EEB4B0',
                                                                    color: '#CB3A31'
                                                                }}
                                                                onClick={() => pressDeleteTemplate(item)}>
                                                                <FontAwesomeIcon icon="trash"/>{' '}Delete
                                                            </button>}

                                                            <button
                                                                className='btn mr-2'
                                                                style={{
                                                                    backgroundColor: '#F37F26',
                                                                    borderColor: '#F37F26',
                                                                    color: '#FFFFFF'
                                                                }}
                                                                onClick={() => getEditTemplate(item)}>
                                                                <FontAwesomeIcon icon="eye"/>{' '}View
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                                <div style={{display: 'flex'}}>
                                    <span className="mt-3 mx-2">Show</span>
                                    <span>
                    <FormControl variant="standard" sx={{m: 1, minWidth: 50}}>
                      <SelectMui
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={sizeTemplate}
                          onChange={handleChange}
                          label="Limit">
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </SelectMui>
                    </FormControl>
                  </span>
                                    <span className="mt-3 mx-2">
                    Entries
                  </span>
                                </div>
                            </div>
                            <div className="col-lg-5 col-sm-12 col-xs-12 my-2"
                                 style={{display: 'flex', justifyContent: 'end'}}>
                                <Pagination count={pageTotalTemplate} color="primary" page={pageTemplate}
                                            showFirstButton showLastButton onChange={handleChangePage}/>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    const viewEditTemplate = () => {
        return (
            <Modal isOpen={modalEditTemplate} size="lg" centered={true} toggle={pressEditTemplate}>
                <ModalHeader toggle={pressEditTemplate}>
                    <div className="font-size-18 font-family-spoqa">View Template</div>
                </ModalHeader>
                <ModalBody>
                    <div className="ml-2 mr-2 mt-3 font-family-spoqa">

                        <div className="row ml-2 mr-2">
                            <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                                <div className='font-weight-bold'>
                                    Title Template
                                    <span className="required" style={{color: '#ff0000'}}>*</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-10" style={{alignSelf: 'center'}}>
                                <input
                                    className="form-control"
                                    value={formDataEdit.title}
                                    onChange={(e) => setFormDataEdit({...formDataEdit, title: e.target.value})}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div id="editorStyle" className="card">
                            <div className="card-body">
                                <div className={"col p-0"} style={{border: "1px solid #ced4da", borderRadius: "8px"}}>
                                    <div className={"row p-3"}>
                                        <div className={"mb-1"} style={{display: 'flex', flexDirection: 'row'}}>
                                            <div style={{width: '10%'}}
                                                 className='font-weight-bold font-size-12 d-flex align-items-center'>Priority
                                            </div>
                                            <div style={{width: '98%', paddingBottom: '5px', paddingTop: '5px'}}>
                                                <Select
                                                    isLoading={loadingDraft}
                                                    name="group"
                                                    value={priorityDocument.filter((function (option: any) {
                                                        return option.value == formDataEdit.priority
                                                    }))}
                                                    placeholder=""
                                                    options={priorityDocument}
                                                    onChange={(e) => {
                                                        setFormDataEdit({
                                                            ...formDataEdit,
                                                            priority : e.value
                                                        })
                                                    }}
                                                    isDisabled={true}
                                                />
                                            </div>
                                        </div>

                                        <div className={"mb-1"} style={{display: 'flex', flexDirection: 'row'}}>
                                            <div style={{width: '10%'}}
                                                 className='font-weight-bold font-size-12 d-flex align-items-center'>Document
                                                Security
                                            </div>
                                            <div style={{width: '98%', paddingBottom: '5px', paddingTop: '5px'}}>
                                                <Select
                                                    isLoading={loadingDraft}
                                                    name="group"
                                                    value={documentSecurity.filter((function (option: any) {
                                                        return option.value == formDataEdit.documentSecurity
                                                    }))}
                                                    placeholder=""
                                                    options={documentSecurity}
                                                    onChange={(e) => {
                                                        setFormDataEdit({
                                                            ...formDataEdit,
                                                            documentSecurity: e.value
                                                        })
                                                    }}
                                                    isDisabled={true}
                                                />
                                            </div>
                                        </div>

                                        <div className={"mb-1"} style={{display: 'flex', flexDirection: 'row'}}>
                                            <div style={{width: '10%'}}
                                                 className='font-weight-bold font-size-12 d-flex align-items-center'>Send To
                                            </div>
                                            <div style={{width: '98%', paddingBottom: '5px', paddingTop: '5px'}}>
                                                <Select
                                                    isLoading={loadingDraft}
                                                    name="group"
                                                    value={listSendToEdit.filter((function (option: any) {
                                                        return option.value == formDataEdit.sendTo
                                                    }))}
                                                    placeholder=""
                                                    options={listSendToEdit}
                                                    onChange={(e) => {
                                                        setFormDataEdit({
                                                            ...formDataEdit,
                                                            sendTo: e.value
                                                        })
                                                    }}
                                                    isDisabled={true}
                                                />
                                            </div>
                                        </div>

                                        {addFieldToEdit?.map((toElement, index) => {
                                            let valLength = addFieldTo.length - 1;
                                            return (
                                                <div key={index} className='mb-1'
                                                     style={{display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{width: '10%'}}
                                                         className='font-weight-bold font-size-12 d-flex align-items-center'>
                                                        {index == 0 ? "To" : ""}
                                                    </div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={[{label: toElement.levelOrganizationName, value: toElement.levelOrganization}]}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={[{label: toElement.groupName, value: toElement.groupId}]}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{
                                                        width: '35%',
                                                        paddingLeft: '5px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    }}>
                                                        <Select
                                                            name="group"
                                                            value={[{label: toElement.approversName, value: toElement.approvers}]}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        {addFieldEFEdit?.map((efElement, index) => {
                                            let valLength = addFieldCC.length - 1;
                                            return (
                                                <div key={index} className='mb-1'
                                                     style={{display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{width: '10%'}}
                                                         className='font-weight-bold font-size-12 d-flex align-items-center'>{index == 0 ? "Line Approval" : ""}</div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={{label: efElement.levelOrganizationName, value: efElement.levelOrganizationId}}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={{label: efElement.groupName, value: efElement.groupId}}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{
                                                        width: '35%',
                                                        paddingLeft: '5px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    }}>
                                                        <Select
                                                            name="group"
                                                            value={{label: efElement.approversName, value: efElement.approvers}}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        {addFieldCCEdit?.map((ccElement, index) => {
                                            let valLength = addFieldCC.length - 1;
                                            return (
                                                <div key={index} className='mb-1'
                                                     style={{display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{width: '10%'}}
                                                         className='font-weight-bold font-size-12 d-flex align-items-center'>{index == 0 ? "CC" : ""}</div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={{label: ccElement.levelOrganizationName, value: ccElement.levelOrganizationId}}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={{label: ccElement.groupName, value: ccElement.groupId}}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{
                                                        width: '35%',
                                                        paddingLeft: '5px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    }}>
                                                        <Select
                                                            name="group"
                                                            value={{label: ccElement.approversName, value: ccElement.approvers}}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        {rbAccessLevelValue == "PRIVATE" &&
                                            addFieldScreenerEdit?.map((screenerElement, index) => {
                                            let valLength = addFieldScreener.length - 1;
                                            return (
                                                <div key={index} className='mb-1'
                                                     style={{display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{width: '10%'}}
                                                         className='font-weight-bold font-size-12 d-flex align-items-center'>{index == 0 ? "Direct Supervisor" : ""}</div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={{label: screenerElement.levelOrganizationName, value: screenerElement.levelOrganizationId}}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{width: '35%', padding: '5px'}}>
                                                        <Select
                                                            name="group"
                                                            value={{label: screenerElement.groupName, value: screenerElement.groupId}}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div style={{
                                                        width: '35%',
                                                        paddingLeft: '5px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    }}>
                                                        <Select
                                                            name="group"
                                                            placeholder=""
                                                            value={{label: screenerElement.approversName, value: screenerElement.approvers}}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }

                                    </div>

                                    <div className={"row pl-3 pr-3"}>
                                        <div className={"col-12 d-inline-flex align-items-center"}>
                                            <span
                                                className={"mr-2 font-weight-bold font-size-12 d-flex align-items-center"}>Regarding</span>
                                            <input
                                                className="form-control"
                                                value={formDataEdit.regarding}
                                                onChange={(e) => setFormDataEdit({...formDataEdit,regarding: e.target.value})}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>

                                    <div className={"row"} style={{
                                        paddingLeft: "28px",
                                        paddingRight: "28px",
                                        paddingBottom: "16px",
                                        paddingTop: "16px"
                                    }}>
                                            <Editor
                                                apiKey="ofyv4jj2oacut7go4bv4c112ro9uuupgfqcuj3docuzk1ib1"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                // initialValue={initialValue}
                                                value={formDataEdit.fileContent}
                                                onEditorChange={(e) => setFormDataEdit({...formDataEdit,fileContent: e})}
                                                // onEditorChange={handleUpdate}
                                                // disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                                                init={{
                                                    height: 400,
                                                    menubar: true,
                                                    paste_data_images: true,
                                                    forced_root_block_attrs: {
                                                        'style': 'font-size: 10pt;'
                                                    },
                                                    forced_root_block: 'div',
                                                    force_br_newlines: true,
                                                    force_p_newlines: false,
                                                    force_div_newlines: false,
                                                    formats: {
                                                        bold: {inline: 'b'},
                                                        italic: {inline: 'i'},
                                                        underline: {inline: 'u'}
                                                    },
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor ',
                                                        'searchreplace visualblocks fullscreen ',
                                                        'insertdatetime media table paste code help wordcount hr ',
                                                        'anchor autolink charmap codesample ',
                                                        'emoticons image link lists media table visualblocks ',
                                                        'wordcount ',
                                                        'autosave tablesplitcells tablemergecells tablecellborderstyle ',
                                                        'tablecellprops tablerowprops'
                                                    ],
                                                    toolbar: 'undo redo | fontsizeselect | code |  formatselect | ' +
                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        ' help hr' +
                                                        'blocks fontfamily fontsize | underline strikethrough | link image media table mergetags | ' +
                                                        'align lineheight | checklist numlist bullist indent outdent | ' +
                                                        'emoticons charmap | removeformat | tablesplitcells tablemergecells tablecellborderstyle tablecellprops tablerowprops',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12pt }',
                                                    table_border_styles: [
                                                        {title: 'Solid', value: 'solid'},
                                                        {title: 'Dotted', value: 'dotted'},
                                                        {title: 'Dashed', value: 'dashed'},
                                                        {title: 'Double', value: 'double'},
                                                        {title: 'Groove', value: 'groove'},
                                                        {title: 'Ridge', value: 'ridge'},
                                                        {title: 'Inset', value: 'inset'},
                                                        {title: 'Outset', value: 'outset'},
                                                        {title: 'None', value: 'none'},
                                                        {title: 'Hidden', value: 'hidden'}
                                                    ]
                                                }}
                                                disabled={true}
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {/*<Button
                        id="save-as-template" type="submit"
                        style={{backgroundColor: "#002F48", color: "#fff"}}
                        disabled={loadingTemplate}
                        onClick={() => pressEditTemplate()}>
                        <span>Back</span>
                    </Button>*/}
                    {/*<Button
                        id="save-as-template" type="submit"
                        style={{backgroundColor: "#002F48", color: "#fff"}}
                        disabled={loadingTemplate}
                        onClick={() => pressUpdateTemplate()}>
            <span>
              {loadingTemplate ? <Spinner color='#fff' size="sm" className='mr-1'/> : ""}
                Update Template
            </span>
                    </Button>*/}
                    <Button
                        id="use-a-template" type="submit"
                        style={{borderColor: "#F37F26", backgroundColor: "#F37F26", color: "#fff"}}
                        disabled={loadingTemplate}
                        onClick={() => pressUseTemplate()}>
                    <span>
                      Use Template
                    </span>
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    const viewModalMerge= () => {
        return (<Modal isOpen={modalMerge} size="md" centered={true}>
            <ModalBody>
                <Col className={"pl-5 pr-5 pb-4 pt-4"}>
                    <Row className={"mb-2"}>
                            <span className={"d-flex justify-content-center"}>
                                <MergeIllustration/>
                            </span>
                    </Row>
                    <Row className={"mb-4"}>
                            <span className={"text-center font-family-spoqa"}
                                  style={{fontSize: "16px", fontStyle: "bold"}}>
                                Your attachment file exceeds the limit 20 MB,
are you sure you want to continue without merging?
                            </span>
                    </Row>
                    <div className={"row"}>
                        <div className={"d-inline-block"}>
                            <button style={{
                                paddingRight: "16px",
                                paddingLeft: "16px",
                                float: "right",
                                height: "40px",
                                borderRadius: "6px",
                                border: "0px",
                                backgroundColor: "#F58220",
                                width: "100px",
                                color: "#FFF"
                            }} onClick={() => {
                                setModalMerge(false)
                            }}>
                                Back
                            </button>
                            <button style={{
                                paddingRight: "16px",
                                paddingLeft: "16px",
                                float: "left",
                                height: "40px",
                                borderRadius: "6px",
                                border: "2px solid #F58220",
                                width: "280px",
                                color: "#F58220",
                                backgroundColor: "#FFF"
                            }} onClick={() => {
                                dataFormData = {
                                    ...dataFormData,
                                    isMerge: "false",
                                }
                                setModalMerge(false)
                                handleNextStep()

                            }}>
                                Continue Without Merging
                            </button>
                        </div>
                    </div>
                </Col>
            </ModalBody>
        </Modal>)
    }

    return (
        <div className="container font-family-spoqa">
            <Row className="font-family-spoqa">
                <Col md="8">
                    <h2 id="documenttestApp.document.home.createOrEditLabel">
                        <Translate contentKey="create.label">Create or edit a Document</Translate>
                    </h2>
                    <p id="documenttestApp.document.home.createOrEditLabel">
                        <Translate contentKey="create.description">Create or edit a Document</Translate>
                    </p>
                </Col>
            </Row>
            <Row className="font-family-spoqa justify-content-center">
                <Col md="12">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="row mt-3 mb-4">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                                            <div className='font-weight-bold'
                                                 style={{display: 'flex', alignSelf: 'center'}}>
                                                Type
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <Select
                                                isLoading={loadingDraft}
                                                name="group"
                                                value={typeDocument.filter((function (option: any) {
                                                    return option.value == formData.type
                                                }))}
                                                // isDisabled={fileStatus == 'APPROVED_BY_REJECTOR' || fileStatus == 'REJECTED'}
                                                isDisabled={true}
                                                placeholder="Select Type Document"
                                                options={typeDocument}
                                                onChange={(e) => handleInput("type", e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                                            <div className='font-weight-bold'
                                                 style={{display: 'flex', alignSelf: 'center'}}>
                                                Priority
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <Select
                                                isLoading={loadingDraft}
                                                name="group"
                                                value={priorityDocument.filter((function (option: any) {
                                                    return option.value == formData.priority
                                                }))}
                                                isDisabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                                                placeholder="Select Priority Document"
                                                options={priorityDocument}
                                                onChange={(e) => handleInput("priority", e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                                            <div className='font-weight-bold'
                                                 style={{display: 'flex', alignSelf: 'center'}}>
                                                No File
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <Select
                                                isLoading={loadingDraft}
                                                name="group"
                                                value={groupListDocument.filter((function (option: any) {
                                                    return option.id == formDocumentNo.groupId
                                                }))}
                                                placeholder="Select Document Group"
                                                options={groupListDocument}
                                                onChange={(e) => {
                                                    // get List
                                                    // setFormData({ ...formData, approval: [], acknowledge: [] })
                                                    setFormData({...formData})

                                                    setDataLOScreener([])

                                                    setDataOrganizationScreener([])

                                                    setDataApproversScreener([])

                                                    setAddFieldScreener([{
                                                        "levelOrganization": 0,
                                                        "groupId": 0,
                                                        "approvers": ""
                                                    }])

                                                    generateDocumentNumberByGroup(e.id)
                                                    getLevelOrganizationScreener(e.id)
                                                }}
                                                isDisabled={fileStatus == 'APPROVED_BY_REJECTOR' || fileStatus == 'REJECTED'}
                                                getOptionLabel={(option: any) => option.groupName}
                                                getOptionValue={(option: any) => option.id}
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                                            {/* <div className='font-weight-bold' style={{display: 'flex', justifyContent: 'center', alignSelf: 'center'}}>
                                  No File
                            </div> */}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-3">
                                            <input className='form-control'
                                                   disabled={fileStatus == 'APPROVED_BY_REJECTOR' || fileStatus == 'REJECTED'}
                                                   type='number'
                                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputDocumentNo('documentNumber', parseInt(e.target.value) < 1 ? 1 : e.target.value)}
                                                   value={formDocumentNo.documentNumber}/>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-7">
                                            <input disabled={true} className='form-control'
                                                   value={formDocumentNo.documentCode}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-1">
                                </div>
                                <div className="col-5">
                                    {/* <span style={{ fontSize: 10 }}>The document number will only be saved after you send your document</span> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                                            <div className='font-weight-bold'
                                                 style={{display: 'flex', alignSelf: 'center'}}>
                                                Document Security
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <Select
                                                isLoading={loadingDraft}
                                                name="group"
                                                value={documentSecurity.filter((function (option: any) {
                                                    return option.value == formData.documentSecurity
                                                }))}
                                                isDisabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                                                placeholder="Select Document Security"
                                                options={documentSecurity}
                                                onChange={(e) => handleInput("documentSecurity", e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                                            <div className='font-weight-bold'
                                                 style={{display: 'flex', alignSelf: 'center'}}>
                                                Send To
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <Select
                                                isLoading={loadingDraft}
                                                name="group"
                                                value={listSendTo.filter((function (option: any) {
                                                    return option.value == formData.sendTo
                                                }))}
                                                isDisabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                                                placeholder="Select Send To"
                                                options={listSendTo}
                                                onChange={(e) => handleInput("sendTo", e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card  mb-4">
                        <div className="card-body">
                            <div className="row mt-3 mb-4">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                                            <div className='font-weight-bold' style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignSelf: 'center'
                                            }}>
                                                Date
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <DatePicker
                                                value={formData.date}
                                                disabled={true}
                                                minDate={new Date()}
                                                selected={formData.date}
                                                dateFormat="d MMMM yyyy - h:mm aa"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-2" style={{alignSelf: 'center'}}>
                                            <div className='font-weight-bold' style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignSelf: 'center'
                                            }}>
                                                Exp Date
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <DatePicker
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                timeCaption="time"
                                                // dateFormat="MMMM d, yyyy h:mm aa"
                                                dateFormat="d MMMM yyyy - h:mm aa"
                                                minDate={new Date()}
                                                selected={formData.expDate}
                                                onChange={(e) => {
                                                    console.log(e)
                                                    setFormData({
                                                        ...formData,
                                                        expDate: e
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card  mb-4">
                        <div className="card-body">
                            <div className="row mt-3 mb-4">

                                {addFieldTo.map((toElement, index) => {
                                    let valLength = addFieldTo.length - 1;
                                    return (
                                        <div key={index} className='mb-1'
                                             style={{display: 'flex', flexDirection: 'row'}}>
                                            <div style={{width: '15%', display: 'flex', alignItems: 'center'}}>
                                                <div className='font-weight-bold font-size-12'>
                                                    {index == 0 ? "To" : ""}
                                                    {index == 0 ?
                                                        <Tooltip title={tooltipMessage.to} placement="right"><span
                                                            className='mx-1'><InfoIcon/></span></Tooltip> : ""}
                                                </div>
                                            </div>
                                            <div style={{width: '22%', padding: '5px'}}>
                                                <Select
                                                    name="group"
                                                    value={dataLevelOrganization.filter((function (option: any) {
                                                        return option.value == toElement.levelOrganization
                                                    }))}
                                                    placeholder="Level Organization"
                                                    options={dataLevelOrganization}
                                                    onChange={(e) => handleSelectTo('levelOrganization', index, e)}
                                                />
                                            </div>
                                            <div style={{width: '22%', padding: '5px'}}>
                                                <Select
                                                    name="group"
                                                    value={dataOrganizationTo[index] == undefined ? null : dataOrganizationTo[index]?.filter((function (option: any) {
                                                        return option.value == toElement.groupId
                                                    }))}
                                                    placeholder="Select Group"
                                                    options={dataOrganizationTo[index]}
                                                    onChange={(e) => handleSelectTo('groupId', index, e)}
                                                />
                                            </div>
                                            <div style={{width: '22%', padding: '5px'}}>
                                                <Select
                                                    name="group"
                                                    value={dataApproversTo[index] == undefined ? null : dataApproversTo[index]?.filter((function (option: any) {
                                                        return option.value == toElement.approvers
                                                    }))}
                                                    placeholder="Select Approvers"
                                                    options={dataApproversTo[index]}
                                                    onChange={(e) => handleSelectTo('approvers', index, e)}
                                                />
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '20%',
                                                padding: '5px'
                                            }}>
                                                {
                                                    index == 0
                                                        ?
                                                        (valLength == 0
                                                                ?
                                                                <Button
                                                                    style={{
                                                                        width: '120px',
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#F37F26',
                                                                        border: '1px solid #EEB4B0'
                                                                    }}
                                                                    className="mr-2"
                                                                    onClick={() => addFormFieldsTo()}>
                                                                    <span
                                                                        style={{color: '#F5F5F5', textAlign: 'center'}}>+ add more</span>
                                                                </Button>
                                                                :
                                                                <Button
                                                                    color='#CB3A31'
                                                                    style={{
                                                                        width: '120px',
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#CB3A31',
                                                                        border: '1px solid #EEB4B0'
                                                                    }}
                                                                    className="mr-2"
                                                                    onClick={() => removeFormFieldsTo(index)}>
                                                                    <span
                                                                        style={{color: '#F5F5F5', textAlign: 'center'}}>x delete</span>
                                                                </Button>
                                                        )
                                                        :
                                                        (index != valLength
                                                                ?
                                                                <Button
                                                                    color='#CB3A31'
                                                                    style={{
                                                                        width: '120px',
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#CB3A31',
                                                                        border: '1px solid #EEB4B0'
                                                                    }}
                                                                    className="mr-2"
                                                                    onClick={() => removeFormFieldsTo(index)}>
                                                                    <span
                                                                        style={{color: '#F5F5F5', textAlign: 'center'}}>x delete</span>
                                                                </Button>
                                                                :
                                                                <div>
                                                                    <Button
                                                                        style={{
                                                                            width: '25px',
                                                                            textAlign: 'center',
                                                                            backgroundColor: '#CB3A31',
                                                                            border: '1px solid #EEB4B0'
                                                                        }}
                                                                        className="mr-2"
                                                                        onClick={() => removeFormFieldsTo(index)}>
                                                                        <span style={{
                                                                            color: '#F5F5F5',
                                                                            textAlign: 'center'
                                                                        }}>- </span>
                                                                    </Button>
                                                                    <Button
                                                                        style={{
                                                                            width: '95px',
                                                                            textAlign: 'center',
                                                                            backgroundColor: '#F37F26',
                                                                            border: '1px solid #EEB4B0'
                                                                        }}
                                                                        className="mr-2"
                                                                        onClick={() => addFormFieldsTo()}>
                                                                        <span style={{
                                                                            color: '#F5F5F5',
                                                                            textAlign: 'center'
                                                                        }}>+ add more</span>
                                                                    </Button>
                                                                </div>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    )
                                })}

                                {addFieldEF.map((efElement, index) => {
                                    let valLength = addFieldEF.length - 1;
                                    return (
                                        <div key={index} className='mb-1'
                                             style={{display: 'flex', flexDirection: 'row'}}>
                                            <div style={{width: '15%', display: 'flex', alignItems: 'center'}}>
                                                <div className='font-weight-bold font-size-12'>
                                                    {index == 0 ? "Line Approval" : ""}
                                                    {index == 0 ?
                                                        <Tooltip title={tooltipMessage.effulent} placement="right"><span
                                                            className='mx-1'><InfoIcon/></span></Tooltip> : ""}
                                                </div>
                                            </div>
                                            <div style={{width: '22%', padding: '5px'}}>
                                                <Select
                                                    name="group"
                                                    value={dataLevelOrganization.filter((function (option: any) {
                                                        return option.value == efElement.levelOrganization
                                                    }))}
                                                    placeholder="Level Organization"
                                                    options={dataLevelOrganization}
                                                    onChange={(e) => handleSelectEF('levelOrganization', index, e)}
                                                />
                                            </div>
                                            <div style={{width: '22%', padding: '5px'}}>
                                                <Select
                                                    name="group"
                                                    value={dataOrganizationEF[index] == undefined ? null : dataOrganizationEF[index]?.filter((function (option: any) {
                                                        return option.value == efElement.groupId
                                                    }))}
                                                    placeholder="Select Group"
                                                    options={dataOrganizationEF[index]}
                                                    onChange={(e) => handleSelectEF('groupId', index, e)}
                                                />
                                            </div>
                                            <div style={{width: '22%', padding: '5px'}}>
                                                <Select
                                                    name="group"
                                                    value={dataApproversEF[index] == undefined ? null : dataApproversEF[index]?.filter((function (option: any) {
                                                        return option.value == efElement.approvers
                                                    }))}
                                                    placeholder="Select Approvers"
                                                    options={dataApproversEF[index]}
                                                    onChange={(e) => handleSelectEF('approvers', index, e)}
                                                />
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '20%',
                                                padding: '5px'
                                            }}>
                                                {
                                                    index == 0
                                                        ?
                                                        (valLength == 0
                                                                ?
                                                                <Button
                                                                    style={{
                                                                        width: '120px',
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#F37F26',
                                                                        border: '1px solid #EEB4B0'
                                                                    }}
                                                                    className="mr-2"
                                                                    onClick={() => addFormFieldsEF()}>
                                                                    <span
                                                                        style={{color: '#F5F5F5', textAlign: 'center'}}>+ add more</span>
                                                                </Button>
                                                                :
                                                                <Button
                                                                    color='#CB3A31'
                                                                    style={{
                                                                        width: '120px',
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#CB3A31',
                                                                        border: '1px solid #EEB4B0'
                                                                    }}
                                                                    className="mr-2"
                                                                    onClick={() => removeFormFieldsEF(index)}>
                                                                    <span
                                                                        style={{color: '#F5F5F5', textAlign: 'center'}}>x delete</span>
                                                                </Button>
                                                        )
                                                        :
                                                        (index != valLength
                                                                ?
                                                                <Button
                                                                    color='#CB3A31'
                                                                    style={{
                                                                        width: '120px',
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#CB3A31',
                                                                        border: '1px solid #EEB4B0'
                                                                    }}
                                                                    className="mr-2"
                                                                    onClick={() => removeFormFieldsEF(index)}>
                                                                    <span
                                                                        style={{color: '#F5F5F5', textAlign: 'center'}}>x delete</span>
                                                                </Button>
                                                                :
                                                                <div>
                                                                    <Button
                                                                        style={{
                                                                            width: '25px',
                                                                            textAlign: 'center',
                                                                            backgroundColor: '#CB3A31',
                                                                            border: '1px solid #EEB4B0'
                                                                        }}
                                                                        className="mr-2"
                                                                        onClick={() => removeFormFieldsEF(index)}>
                                                                        <span style={{
                                                                            color: '#F5F5F5',
                                                                            textAlign: 'center'
                                                                        }}>- </span>
                                                                    </Button>
                                                                    <Button
                                                                        style={{
                                                                            width: '95px',
                                                                            textAlign: 'center',
                                                                            backgroundColor: '#F37F26',
                                                                            border: '1px solid #EEB4B0'
                                                                        }}
                                                                        className="mr-2"
                                                                        onClick={() => addFormFieldsEF()}>
                                                                        <span style={{
                                                                            color: '#F5F5F5',
                                                                            textAlign: 'center'
                                                                        }}>+ add more</span>
                                                                    </Button>
                                                                </div>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    )
                                })}

                                {addFieldCC.map((ccElement, index) => {
                                    let valLength = addFieldCC.length - 1;
                                    return (
                                        <div key={index} className='mb-1'
                                             style={{display: 'flex', flexDirection: 'row'}}>
                                            <div style={{width: '15%', display: 'flex', alignItems: 'center'}}>
                                                <div className='font-weight-bold font-size-12'>
                                                    {index == 0 ? "CC" : ""}
                                                    {index == 0 ?
                                                        <Tooltip title={tooltipMessage.cc} placement="right"><span
                                                            className='mx-1'><InfoIcon/></span></Tooltip> : ""}
                                                </div>
                                            </div>
                                            <div style={{width: '22%', padding: '5px'}}>
                                                <Select
                                                    name="group"
                                                    value={dataLevelOrganization.filter((function (option: any) {
                                                        return option.value == ccElement.levelOrganization
                                                    }))}
                                                    placeholder="Level Organization"
                                                    options={dataLevelOrganization}
                                                    onChange={(e) => handleSelectCC('levelOrganization', index, e)}
                                                />
                                            </div>
                                            <div style={{width: '22%', padding: '5px'}}>
                                                <Select
                                                    name="group"
                                                    value={dataOrganizationCC[index] == undefined ? null : dataOrganizationCC[index]?.filter((function (option: any) {
                                                        return option.value == ccElement.groupId
                                                    }))}
                                                    placeholder="Select Group"
                                                    options={dataOrganizationCC[index]}
                                                    onChange={(e) => handleSelectCC('groupId', index, e)}
                                                />
                                            </div>
                                            <div style={{width: '22%', padding: '5px'}}>
                                                <Select
                                                    name="group"
                                                    value={dataApproversCC[index] == undefined ? null : dataApproversCC[index]?.filter((function (option: any) {
                                                        return option.value == ccElement.approvers
                                                    }))}
                                                    placeholder="Select Approvers"
                                                    options={dataApproversCC[index]}
                                                    onChange={(e) => handleSelectCC('approvers', index, e)}
                                                />
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '20%',
                                                padding: '5px'
                                            }}>
                                                {
                                                    index == 0
                                                        ?
                                                        (valLength == 0
                                                                ?
                                                                <Button
                                                                    style={{
                                                                        width: '120px',
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#F37F26',
                                                                        border: '1px solid #EEB4B0'
                                                                    }}
                                                                    className="mr-2"
                                                                    onClick={() => addFormFieldsCC()}>
                                                                    <span
                                                                        style={{color: '#F5F5F5', textAlign: 'center'}}>+ add more</span>
                                                                </Button>
                                                                :
                                                                <Button
                                                                    color='#CB3A31'
                                                                    style={{
                                                                        width: '120px',
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#CB3A31',
                                                                        border: '1px solid #EEB4B0'
                                                                    }}
                                                                    className="mr-2"
                                                                    onClick={() => removeFormFieldsCC(index)}>
                                                                    <span
                                                                        style={{color: '#F5F5F5', textAlign: 'center'}}>x delete</span>
                                                                </Button>
                                                        )
                                                        :
                                                        (index != valLength
                                                                ?
                                                                <Button
                                                                    color='#CB3A31'
                                                                    style={{
                                                                        width: '120px',
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#CB3A31',
                                                                        border: '1px solid #EEB4B0'
                                                                    }}
                                                                    className="mr-2"
                                                                    onClick={() => removeFormFieldsCC(index)}>
                                                                    <span
                                                                        style={{color: '#F5F5F5', textAlign: 'center'}}>x delete</span>
                                                                </Button>
                                                                :
                                                                <div>
                                                                    <Button
                                                                        style={{
                                                                            width: '25px',
                                                                            textAlign: 'center',
                                                                            backgroundColor: '#CB3A31',
                                                                            border: '1px solid #EEB4B0'
                                                                        }}
                                                                        className="mr-2"
                                                                        onClick={() => removeFormFieldsCC(index)}>
                                                                        <span style={{
                                                                            color: '#F5F5F5',
                                                                            textAlign: 'center'
                                                                        }}>- </span>
                                                                    </Button>
                                                                    <Button
                                                                        style={{
                                                                            width: '95px',
                                                                            textAlign: 'center',
                                                                            backgroundColor: '#F37F26',
                                                                            border: '1px solid #EEB4B0'
                                                                        }}
                                                                        className="mr-2"
                                                                        onClick={() => addFormFieldsCC()}>
                                                                        <span style={{
                                                                            color: '#F5F5F5',
                                                                            textAlign: 'center'
                                                                        }}>+ add more</span>
                                                                    </Button>
                                                                </div>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    )
                                })}

                                {addFieldScreener.map((screenerElement, index) => {
                                    let valLength = addFieldScreener.length - 1;
                                    return (
                                        <div key={index} className='mb-1'
                                             style={{display: 'flex', flexDirection: 'row'}}>
                                            <div style={{width: '15%', display: 'flex', alignItems: 'center'}}>
                                                <div className='font-weight-bold font-size-12'>
                                                    {index == 0 ? "Direct Supervisor" : ""}
                                                    {index == 0 ?
                                                        <Tooltip title={tooltipMessage.screener} placement="right"><span
                                                            className='mx-1'><InfoIcon/></span></Tooltip> : ""}
                                                </div>
                                            </div>
                                            <div style={{width: '22%', padding: '5px'}}>
                                                <Select
                                                    name="group"
                                                    value={dataLOScreener.filter((function (option: any) {
                                                        return option.value == screenerElement.levelOrganization
                                                    }))}
                                                    placeholder="Level Organization"
                                                    options={dataLOScreener}
                                                    onChange={(e) => handleSelectScreener('levelOrganization', index, e)}
                                                />
                                            </div>
                                            <div style={{width: '22%', padding: '5px'}}>
                                                <Select
                                                    name="group"
                                                    value={dataOrganizationScreener[index] == undefined ? null : dataOrganizationScreener[index]?.filter((function (option: any) {
                                                        return option.value == screenerElement.groupId
                                                    }))}
                                                    placeholder="Select Group"
                                                    options={dataOrganizationScreener[index]}
                                                    onChange={(e) => handleSelectScreener('groupId', index, e)}
                                                />
                                            </div>
                                            <div style={{width: '22%', padding: '5px'}}>
                                                <Select
                                                    name="group"
                                                    value={dataApproversScreener[index] == undefined ? null : dataApproversScreener[index]?.filter((function (option: any) {
                                                        return option.value == screenerElement.approvers
                                                    }))}
                                                    placeholder="Select Approvers"
                                                    options={dataApproversScreener[index]}
                                                    onChange={(e) => handleSelectScreener('approvers', index, e)}
                                                />
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '20%',
                                                padding: '5px'
                                            }}>
                                                {
                                                    index == 0
                                                        ?
                                                        (valLength == 0
                                                                ?
                                                                <Button
                                                                    style={{
                                                                        width: '120px',
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#F37F26',
                                                                        border: '1px solid #EEB4B0'
                                                                    }}
                                                                    className="mr-2"
                                                                    onClick={() => addFormFieldsScreener()}>
                                                                    <span
                                                                        style={{color: '#F5F5F5', textAlign: 'center'}}>+ add more</span>
                                                                </Button>
                                                                :
                                                                <Button
                                                                    color='#CB3A31'
                                                                    style={{
                                                                        width: '120px',
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#CB3A31',
                                                                        border: '1px solid #EEB4B0'
                                                                    }}
                                                                    className="mr-2"
                                                                    onClick={() => removeFormFieldsScreener(index)}>
                                                                    <span
                                                                        style={{color: '#F5F5F5', textAlign: 'center'}}>x delete</span>
                                                                </Button>
                                                        )
                                                        :
                                                        (index != valLength
                                                                ?
                                                                <Button
                                                                    color='#CB3A31'
                                                                    style={{
                                                                        width: '120px',
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#CB3A31',
                                                                        border: '1px solid #EEB4B0'
                                                                    }}
                                                                    className="mr-2"
                                                                    onClick={() => removeFormFieldsScreener(index)}>
                                                                    <span
                                                                        style={{color: '#F5F5F5', textAlign: 'center'}}>x delete</span>
                                                                </Button>
                                                                :
                                                                <div>
                                                                    <Button
                                                                        style={{
                                                                            width: '25px',
                                                                            textAlign: 'center',
                                                                            backgroundColor: '#CB3A31',
                                                                            border: '1px solid #EEB4B0'
                                                                        }}
                                                                        className="mr-2"
                                                                        onClick={() => removeFormFieldsScreener(index)}>
                                                                        <span style={{
                                                                            color: '#F5F5F5',
                                                                            textAlign: 'center'
                                                                        }}>- </span>
                                                                    </Button>
                                                                    <Button
                                                                        style={{
                                                                            width: '95px',
                                                                            textAlign: 'center',
                                                                            backgroundColor: '#F37F26',
                                                                            border: '1px solid #EEB4B0'
                                                                        }}
                                                                        className="mr-2"
                                                                        onClick={() => addFormFieldsScreener()}>
                                                                        <span style={{
                                                                            color: '#F5F5F5',
                                                                            textAlign: 'center'
                                                                        }}>+ add more</span>
                                                                    </Button>
                                                                </div>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    )
                                })}

                                {/* {addFieldTo.map((toElement, index) => {
                  let valLength = addFieldTo.length - 1;
                  return (
                    <div key={index} className='row mb-1'>
                      <div className='col-1' style={{ paddingRight: '10px' }}>
                        <div className='font-weight-bold font-size-12'>
                          {index == 0 ? "To" : ""}
                          {index == 0 ? <Tooltip title={tooltipMessage.to} placement="right"><span className='mx-1'><InfoIcon /></span></Tooltip> : ""}
                        </div>
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataLevelOrganization.filter((function (option: any) {
                            return option.value == toElement.levelOrganization
                          }))}
                          placeholder="Level Organization"
                          options={dataLevelOrganization}
                          onChange={(e) => handleSelectTo('levelOrganization', index, e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataOrganizationTo[index] == undefined ? null : dataOrganizationTo[index]?.filter((function (option: any) {
                            return option.value == toElement.groupId
                          }))}
                          placeholder="Select Group"
                          options={dataOrganizationTo[index]}
                          onChange={(e) => handleSelectTo('groupId', index, e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataApproversTo[index] == undefined ? null : dataApproversTo[index]?.filter((function (option: any) {
                            return option.value == toElement.approvers
                          }))}
                          placeholder="Select Approvers"
                          options={dataApproversTo[index]}
                          onChange={(e) => handleSelectTo('approvers', index, e)}
                        />
                      </div>
                      <div className='col-2' style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>
                        {
                          index == 0
                            ?
                            (valLength == 0
                              ?
                              <Button
                                style={{ width: '120px', textAlign: 'center', backgroundColor: '#F37F26', border: '1px solid #EEB4B0' }}
                                className="mr-2"
                                onClick={() => addFormFieldsTo()}>
                                <span style={{ color: '#F5F5F5', textAlign: 'center' }}>+ add more</span>
                              </Button>
                              :
                              <Button
                                color='#CB3A31'
                                style={{ width: '120px', textAlign: 'center', backgroundColor: '#CB3A31', border: '1px solid #EEB4B0' }}
                                className="mr-2"
                                onClick={() => removeFormFieldsTo(index)}>
                                <span style={{ color: '#F5F5F5', textAlign: 'center' }}>x delete</span>
                              </Button>
                            )
                            :
                            (index != valLength
                              ?
                              <Button
                                color='#CB3A31'
                                style={{ width: '120px', textAlign: 'center', backgroundColor: '#CB3A31', border: '1px solid #EEB4B0' }}
                                className="mr-2"
                                onClick={() => removeFormFieldsTo(index)}>
                                <span style={{ color: '#F5F5F5', textAlign: 'center' }}>x delete</span>
                              </Button>
                              :
                              <div>
                                <Button
                                  style={{ width: '25px', textAlign: 'center', backgroundColor: '#CB3A31', border: '1px solid #EEB4B0' }}
                                  className="mr-2"
                                  onClick={() => removeFormFieldsTo(index)}>
                                  <span style={{ color: '#F5F5F5', textAlign: 'center' }}>- </span>
                                </Button>
                                <Button
                                  style={{ width: '95px', textAlign: 'center', backgroundColor: '#F37F26', border: '1px solid #EEB4B0' }}
                                  className="mr-2"
                                  onClick={() => addFormFieldsTo()}>
                                  <span style={{ color: '#F5F5F5', textAlign: 'center' }}>+ add more</span>
                                </Button>
                              </div>
                            )
                        }
                      </div>
                    </div>
                  )
                })} */}
                                {/* {addFieldCC.map((ccElement, index) => {
                  let valLength = addFieldCC.length - 1;
                  return (
                    <div className='row mb-1'>
                      <div className='col-1'>
                        <div className='font-weight-bold font-size-12'>
                          {index == 0 ? "Line Approval" : ""}
                          {index == 0 ? <Tooltip title={tooltipMessage.cc} placement="right"><span className='mx-1'><InfoIcon /></span></Tooltip> : ""}
                        </div>
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataLevelOrganization.filter((function (option: any) {
                            return option.value == ccElement.levelOrganization
                          }))}
                          placeholder="Level Organization"
                          options={dataLevelOrganization}
                          onChange={(e) => handleSelectCC('levelOrganization', index, e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataOrganizationCC[index] == undefined ? null : dataOrganizationCC[index]?.filter((function (option: any) {
                            return option.value == ccElement.groupId
                          }))}
                          placeholder="Select Group"
                          options={dataOrganizationCC[index]}
                          onChange={(e) => handleSelectCC('groupId', index, e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataApproversCC[index] == undefined ? null : dataApproversCC[index]?.filter((function (option: any) {
                            return option.value == ccElement.approvers
                          }))}
                          placeholder="Select Approvers"
                          options={dataApproversCC[index]}
                          onChange={(e) => handleSelectCC('approvers', index, e)}
                        />
                      </div>
                      <div className='col-2' style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>
                        {
                          index == 0
                            ?
                            (valLength == 0
                              ?
                              <Button
                                style={{ width: '120px', textAlign: 'center', backgroundColor: '#F37F26', border: '1px solid #EEB4B0' }}
                                className="mr-2"
                                onClick={() => addFormFieldsCC()}>
                                <span style={{ color: '#F5F5F5', textAlign: 'center' }}>+ add more</span>
                              </Button>
                              :
                              <Button
                                color='#CB3A31'
                                style={{ width: '120px', textAlign: 'center', backgroundColor: '#CB3A31', border: '1px solid #EEB4B0' }}
                                className="mr-2"
                                onClick={() => removeFormFieldsCC(index)}>
                                <span style={{ color: '#F5F5F5', textAlign: 'center' }}>x delete</span>
                              </Button>
                            )
                            :
                            (index != valLength
                              ?
                              <Button
                                color='#CB3A31'
                                style={{ width: '120px', textAlign: 'center', backgroundColor: '#CB3A31', border: '1px solid #EEB4B0' }}
                                className="mr-2"
                                onClick={() => removeFormFieldsCC(index)}>
                                <span style={{ color: '#F5F5F5', textAlign: 'center' }}>x delete</span>
                              </Button>
                              :
                              <div>
                                <Button
                                  style={{ width: '25px', textAlign: 'center', backgroundColor: '#CB3A31', border: '1px solid #EEB4B0' }}
                                  className="mr-2"
                                  onClick={() => removeFormFieldsCC(index)}>
                                  <span style={{ color: '#F5F5F5', textAlign: 'center' }}>- </span>
                                </Button>
                                <Button
                                  style={{ width: '95px', textAlign: 'center', backgroundColor: '#F37F26', border: '1px solid #EEB4B0' }}
                                  className="mr-2"
                                  onClick={() => addFormFieldsCC()}>
                                  <span style={{ color: '#F5F5F5', textAlign: 'center' }}>+ add more</span>
                                </Button>
                              </div>
                            )
                        }
                      </div>
                    </div>
                  )
                })} */}
                                {/* {addFieldEF.map((efElement, index) => {
                  let valLength = addFieldEF.length - 1;
                  return (
                    <div className='row mb-1'>
                      <div className='col-1'>
                        <div className='font-weight-bold font-size-12'>
                          {index == 0 ? "CC" : ""}
                          {index == 0 ? <Tooltip title={tooltipMessage.effulent} placement="right"><span className='mx-1'><InfoIcon /></span></Tooltip> : ""}
                        </div>
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataLevelOrganization.filter((function (option: any) {
                            return option.value == efElement.levelOrganization
                          }))}
                          placeholder="Level Organization"
                          options={dataLevelOrganization}
                          onChange={(e) => handleSelectEF('levelOrganization', index, e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataOrganizationEF[index] == undefined ? null : dataOrganizationEF[index]?.filter((function (option: any) {
                            return option.value == efElement.groupId
                          }))}
                          placeholder="Select Group"
                          options={dataOrganizationEF[index]}
                          onChange={(e) => handleSelectEF('groupId', index, e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataApproversEF[index] == undefined ? null : dataApproversEF[index]?.filter((function (option: any) {
                            return option.value == efElement.approvers
                          }))}
                          placeholder="Select Approvers"
                          options={dataApproversEF[index]}
                          onChange={(e) => handleSelectEF('approvers', index, e)}
                        />
                      </div>
                      <div className='col-2' style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>
                        {
                          index == 0
                            ?
                            (valLength == 0
                              ?
                              <Button
                                style={{ width: '120px', textAlign: 'center', backgroundColor: '#F37F26', border: '1px solid #EEB4B0' }}
                                className="mr-2"
                                onClick={() => addFormFieldsEF()}>
                                <span style={{ color: '#F5F5F5', textAlign: 'center' }}>+ add more</span>
                              </Button>
                              :
                              <Button
                                color='#CB3A31'
                                style={{ width: '120px', textAlign: 'center', backgroundColor: '#CB3A31', border: '1px solid #EEB4B0' }}
                                className="mr-2"
                                onClick={() => removeFormFieldsEF(index)}>
                                <span style={{ color: '#F5F5F5', textAlign: 'center' }}>x delete</span>
                              </Button>
                            )
                            :
                            (index != valLength
                              ?
                              <Button
                                color='#CB3A31'
                                style={{ width: '120px', textAlign: 'center', backgroundColor: '#CB3A31', border: '1px solid #EEB4B0' }}
                                className="mr-2"
                                onClick={() => removeFormFieldsEF(index)}>
                                <span style={{ color: '#F5F5F5', textAlign: 'center' }}>x delete</span>
                              </Button>
                              :
                              <div>
                                <Button
                                  style={{ width: '25px', textAlign: 'center', backgroundColor: '#CB3A31', border: '1px solid #EEB4B0' }}
                                  className="mr-2"
                                  onClick={() => removeFormFieldsEF(index)}>
                                  <span style={{ color: '#F5F5F5', textAlign: 'center' }}>- </span>
                                </Button>
                                <Button
                                  style={{ width: '95px', textAlign: 'center', backgroundColor: '#F37F26', border: '1px solid #EEB4B0' }}
                                  className="mr-2"
                                  onClick={() => addFormFieldsEF()}>
                                  <span style={{ color: '#F5F5F5', textAlign: 'center' }}>+ add more</span>
                                </Button>
                              </div>
                            )
                        }
                      </div>
                    </div>
                  )
                })} */}
                                {/* {addFieldScreener.map((screenerElement, index) => {
                  let valLength = addFieldScreener.length - 1;
                  return (
                    <div className='row mb-1'>
                      <div className='col-1'>
                        <div className='font-weight-bold font-size-12'>
                          {index == 0 ? "Direct Supervisor" : ""}
                          {index == 0 ? <Tooltip title={tooltipMessage.screener} placement="right"><span className='mx-1'><InfoIcon /></span></Tooltip> : ""}
                        </div>
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataLOScreener.filter((function (option: any) {
                            return option.value == screenerElement.levelOrganization
                          }))}
                          placeholder="Level Organization"
                          options={dataLOScreener}
                          onChange={(e) => handleSelectScreener('levelOrganization', index, e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataOrganizationScreener[index] == undefined ? null : dataOrganizationScreener[index]?.filter((function (option: any) {
                            return option.value == screenerElement.groupId
                          }))}
                          placeholder="Select Group"
                          options={dataOrganizationScreener[index]}
                          onChange={(e) => handleSelectScreener('groupId', index, e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataApproversScreener[index] == undefined ? null : dataApproversScreener[index]?.filter((function (option: any) {
                            return option.value == screenerElement.approvers
                          }))}
                          placeholder="Select Approvers"
                          options={dataApproversScreener[index]}
                          onChange={(e) => handleSelectScreener('approvers', index, e)}
                        />
                      </div>
                      <div className='col-2' style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>
                        {
                          index == 0
                            ?
                            (valLength == 0
                              ?
                              <Button
                                style={{ width: '120px', textAlign: 'center', backgroundColor: '#F37F26', border: '1px solid #EEB4B0' }}
                                className="mr-2"
                                onClick={() => addFormFieldsScreener()}>
                                <span style={{ color: '#F5F5F5', textAlign: 'center' }}>+ add more</span>
                              </Button>
                              :
                              <Button
                                color='#CB3A31'
                                style={{ width: '120px', textAlign: 'center', backgroundColor: '#CB3A31', border: '1px solid #EEB4B0' }}
                                className="mr-2"
                                onClick={() => removeFormFieldsScreener(index)}>
                                <span style={{ color: '#F5F5F5', textAlign: 'center' }}>x delete</span>
                              </Button>
                            )
                            :
                            (index != valLength
                              ?
                              <Button
                                color='#CB3A31'
                                style={{ width: '120px', textAlign: 'center', backgroundColor: '#CB3A31', border: '1px solid #EEB4B0' }}
                                className="mr-2"
                                onClick={() => removeFormFieldsScreener(index)}>
                                <span style={{ color: '#F5F5F5', textAlign: 'center' }}>x delete</span>
                              </Button>
                              :
                              <div>
                                <Button
                                  style={{ width: '25px', textAlign: 'center', backgroundColor: '#CB3A31', border: '1px solid #EEB4B0' }}
                                  className="mr-2"
                                  onClick={() => removeFormFieldsScreener(index)}>
                                  <span style={{ color: '#F5F5F5', textAlign: 'center' }}>- </span>
                                </Button>
                                <Button
                                  style={{ width: '95px', textAlign: 'center', backgroundColor: '#F37F26', border: '1px solid #EEB4B0' }}
                                  className="mr-2"
                                  onClick={() => addFormFieldsScreener()}>
                                  <span style={{ color: '#F5F5F5', textAlign: 'center' }}>+ add more</span>
                                </Button>
                              </div>
                            )
                        }
                      </div>
                    </div>
                  )
                })} */}

                            </div>
                        </div>
                    </div>
                    <div className="card  mb-4">
                        <div className="card-body">
                            <div className="row mt-3 mb-4">
                                <div className='font-weight-bold mb-3' style={{display: 'flex'}}>
                                    Regarding
                                </div>
                                <div className="col-12">
                  <textarea className='form-control' rows={2} style={{resize: 'none'}} value={formData.regarding}
                            disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                            onChange={(e) => {
                                if (e.target.value.length <= 250) {
                                    setFormData({
                                        ...formData,
                                        regarding: e.target.value
                                    })
                                } else {
                                    setFormData({
                                        ...formData,
                                        regarding: e.target.value.slice(0, 250)
                                    })
                                }
                            }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="editorStyle" className="card mb-12">
                        <div className="card-body">
                            <Editor
                                apiKey="ofyv4jj2oacut7go4bv4c112ro9uuupgfqcuj3docuzk1ib1"
                                onInit={(evt, editor) => editorRef.current = editor}
                                // initialValue={initialValue}
                                value={formData.fileContent}
                                onEditorChange={handleUpdate}
                                disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                                init={{
                                    height: 800,
                                    menubar: true,
                                    paste_data_images: true,
                                    forced_root_block_attrs: {
                                        'style': 'font-size: 10pt;'
                                    },
                                    forced_root_block: 'div',
                                    force_br_newlines: true,
                                    force_p_newlines: false,
                                    force_div_newlines: false,
                                    formats: {
                                        bold: {inline: 'b'},
                                        italic: {inline: 'i'},
                                        underline: {inline: 'u'}
                                    },
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor ',
                                        'searchreplace visualblocks fullscreen ',
                                        'insertdatetime media table paste code help wordcount hr ',
                                        'anchor autolink charmap codesample ',
                                        'emoticons image link lists media table visualblocks ',
                                        'wordcount ',
                                        'autosave tablesplitcells tablemergecells tablecellborderstyle ',
                                        'tablecellprops tablerowprops'
                                    ],
                                    toolbar: 'undo redo | fontsizeselect | code |  formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        ' help hr' +
                                        'blocks fontfamily fontsize | underline strikethrough | link image media table mergetags | ' +
                                        'align lineheight | checklist numlist bullist indent outdent | ' +
                                        'emoticons charmap | removeformat | tablesplitcells tablemergecells tablecellborderstyle tablecellprops tablerowprops',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12pt }',
                                    table_border_styles: [
                                        {title: 'Solid', value: 'solid'},
                                        {title: 'Dotted', value: 'dotted'},
                                        {title: 'Dashed', value: 'dashed'},
                                        {title: 'Double', value: 'double'},
                                        {title: 'Groove', value: 'groove'},
                                        {title: 'Ridge', value: 'ridge'},
                                        {title: 'Inset', value: 'inset'},
                                        {title: 'Outset', value: 'outset'},
                                        {title: 'None', value: 'none'},
                                        {title: 'Hidden', value: 'hidden'}
                                    ]
                                }}
                            />
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button
                            id="use-a-template"
                            type="submit"
                            style={{borderColor: "#F37F26", backgroundColor: "#F37F26", color: "#fff"}}
                            onClick={() => pressUseAsTemplate()}>
                            <IconUseAsTemplate/>
                            <span className='ml-1'>
                Use A Template
              </span>
                        </Button>
                        &nbsp;
                        <Button
                            id="save-as-template" type="submit"
                            style={{backgroundColor: "#002F48", color: "#fff"}}
                            onClick={() => pressSaveAsTemplate()}>
                            <IconSaveAsTemplate/>
                            <span className='ml-1'>
                Save As Template
              </span>
                        </Button>
                    </div>
                    <div className="bg-white rounded shadow my-4 px-4 py-4">
                        <h5>Attachment</h5>
                        <br></br>
                        <div>
                            File
                        </div>
                        {listFile.length > 0
                            ?
                            listFile.map((item, index) => {
                                return (<>
                                        <div key={`t-${index}`}>
                                            <div className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block"
                                                 style={{background: '#F5F5F5', color: '#3267E3', alignContent: 'c'}}
                                                 key={`file-${index}`}>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                        <span onClick={() => openFilePreview(item.file?.replace(/^[^,]+,/, ''), item.fileContentType)}>
                          {item.fileName} ( {bytesToSize(item.fileSize)})
                        </span>
                                                    {
                                                        item.fileAttachmentId ?
                                                            <span className="px-2 py-2 mx-2" style={{cursor: 'pointer'}}
                                                                  onClick={() => deleteFileUpload(item.fileAttachmentId)}>
                            <FontAwesomeIcon icon="trash" color="red"/>
                          </span> : <span className="px-2 py-2 mx-2" style={{cursor: 'pointer'}}
                                          onClick={() => deleteFile(index)}>
                            <FontAwesomeIcon icon="trash" color="red"/>
                          </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            :
                            (
                                loadingDraftFile == true
                                    ?
                                    <div className="text-center my-4">
                                        <Box sx={{width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                            <CircularProgress color='warning'/>
                                        </Box>
                                    </div>
                                    :
                                    <>
                                        <div className="text-center my-4">
                                            Anda Belum Memilih File
                                        </div>
                                    </>
                            )
                        }
                        {/* <div>
              Link
            </div>
            {
              listAttachment.length > 0 ? listAttachment.map((data, index) => {
                return (
                  <div key={`s-${index}`}>
                    <div className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block" style={{ background: '#F5F5F5', color: '#3267E3', alignContent: 'c' }} key={`file-${index}`}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                          {data.value}
                        </span>
                        {
                          data.id ? <span className="px-2 py-2 mx-2" style={{ cursor: 'pointer' }} onClick={() => deleteLink(data.id)}>
                            <FontAwesomeIcon icon="trash" color="red" />
                          </span> : <span className="px-2 py-2 mx-2" style={{ cursor: 'pointer' }} onClick={() => deleteLink(data.id)}>
                            <FontAwesomeIcon icon="trash" color="red" />
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                )
              }) :
                <div className="text-center my-4">
                  Anda Belum Memasukkan Link
                </div>
            } */}
                    </div>
                    <div className="bg-white rounded shadow my-4 px-4 py-4">
                        <div className="row">
                            <div className="col-12 col-lg-2 col-xl-2">
                                File
                            </div>
                            <div className="col-12 col-lg-10 col-xl-10">
                <span className="btn btn-primary btn-file">
                  <UploadFileIcon className="mx-1"/> Upload File
                  <input
                      type="file"
                      disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                      onChange={handleFileInput}
                      multiple
                  />
                </span>
                                <div className="my-2">
                                    <span style={{color: '#757575'}}>{UploadConstants}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="bg-white rounded shadow my-4 px-4 py-4">
            <h5>
              Link
            </h5>
            <div className="row mt-3">
              <div className="col-12 col-lg-2 my-2">
                Insert Google Drive Link
              </div>
              <div className="col-lg-6 col-12 col-sm-12 col-xs-12">
                <input className="form-control" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                <div className="my-2">
                  <span style={{ color: '#757575' }}>{UploadConstants}</span>
                </div>
              </div>
              <div className="col-lg-4 col-12 col-sm-12 col-xs-12">
                <button className="btn btn-primary" onClick={(e) => _handleKeyDown(e)} disabled={loadingLink}>
                  {loadingLink ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}Add Link
                </button>
              </div>
            </div>
          </div> */}
                    <div className="float-right">
                        <Button onClick={() => handleSaveAsDraft()} id="cancel-save2" type="submit" className='mr-2'
                                disabled={loadingSaveAsDraft || loadingNextStep} color="primary">
                            &nbsp;
                            <span className="d-md-inline">
                {loadingSaveAsDraft ? <Spinner color='#fff' size="sm" className='mr-1'/> :
                    <SaveDraftIcon className='mr-1'/>}
                                Save As Draft
              </span>
                        </Button>
                        &nbsp;
                        <Button onClick={() => {
                            let totalSize = 0

                            listFile.forEach(async (item: any) => {
                                totalSize += item.fileSize
                            })

                            const fileSize = totalSize / 1024 / 1024; // in MiB

                            if(fileSize >= 20 && listFile.length > 0){
                                setModalMerge(true)
                            } else if (fileSize < 20 && listFile.length > 0) {
                                setLoadingMerge(true)
                                dataFormData = {
                                    ...dataFormData,
                                    isMerge: "true",
                                }
                                handleNextStep()
                            } else {
                                dataFormData = {
                                    ...dataFormData,
                                    isMerge: "false",
                                }
                                handleNextStep()
                            }
                        }} name="next" color="primary" id="save-entity"
                                type="submit" className='pb-2' disabled={loadingSaveAsDraft || loadingNextStep}>
              <span className='mr-1'>
                {loadingNextStep ? <Spinner color='#fff' size="sm" className='mr-1'/> : <></>}
                  Next</span>
                            <ArrowRightIconWhite/>
                        </Button>
                    </div>
                </Col>
            </Row>
            {viewSaveAsTemplate()}
            {viewUseAsTemplate()}
            {viewEditTemplate()}
            {viewListReplaceTemplate()}
            {viewModalSuccess()}
            {viewModalMerge()}
            {viewModalLoadingMerge}
        </div>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    documentEntity: storeState.document.entity,
    loading: storeState.document.loading,
    loadingDepartement: storeState.document.loadingdepartement,
    loadingDetail: storeState.document.loadingdetail,
    updating: storeState.document.updating,
    updateSuccess: storeState.document.updateSuccess,
    deptList: storeState.document.deptList,
    detaildocument: storeState.document.detaildocument,
    userList: storeState.document.userList,
    approvalList: storeState.document.approvalList,
    acknowledgeList: storeState.document.acknowledgeList,
    documentListCode: storeState.document.documentListCode,
});

const mapDispatchToProps = {
    getEntity,
    updateEntity,
    createEntity,
    reset,
    getDeptList,
    getUserList,
    getApprovalList,
    getAcknowledgeList,
    getListDocumentCode,
    sendNewDocument,
    saveDocumentToDraft,
    documentPreview,
    getDocumentDetail
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCreate);