import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import moment from 'moment';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { baseUrlAPI } from './configCareer';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}
export interface IParams {
    key?: any,
}

export const JobsAdd = (props: IHeaderProps) => {
    const [formData, setFormData] = useState({
        position: '',
        employment_type: '',
        division_code: '',
        division_name: '',
        location_code: '',
        location_name: '',
        description: '',
        status_code: '',
        due_date: '',
        number_of_position: 1,
        priority: false,
        job_level_code: '',
        job_level_name: '',
        requirement: ''
    });
    let history = useHistory();
    const [checked, setChecked] = useState(false);
    const [loadingDraft, setLoadingDraft] = useState(false);
    const [loadingSaveAsDraft, setLoadingSaveAsDraft] = useState(false);
    const [loadingNextStep, setLoadingNextStep] = useState(false);
    const [dataListofDivisions, setDataListofDivisions] = useState([]);
    const [dataListofLocations, setDataListofLocations] = useState([]);
    const [dataListofJobLevel, setDataListofJobLevel] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const editorRef = useRef(null);

    const handleInput = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };

    const handleCheckboxChange = (e) => {
        setFormData({ ...formData, priority: e.target.checked });
    };

    const handleUpdate = (content) => {
        setFormData({ ...formData, description: content });
    };

    useEffect(() => {
        getDivisions();
        getLocations();
        getJobLevels();
    }, []);

    useEffect(() => {
        setFormData({
            ...formData,
            due_date: moment(new Date()).format('YYYY-MM-DD')
        });
    }, []);

    const token = localStorage.getItem('access_token');

    const TinyMCEConfig = {
        plugins: 'print preview paste importcss searchreplace autolink autosave directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
        toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help hr | blocks fontfamily fontsize | underline strikethrough | link image media table mergetags | align lineheight | emoticons charmap | removeformat | tablesplitcells tablemergecells tablecellborderstyle tablecellprops tablerowprops',
    };

    const getDivisions = () => {
        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        };

        axios.get(`${baseUrlAPI()}/division/get-list`, config)
            .then((res) => {
                const response = res.data.data;
                let newDivisions = response.map((obj) => ({ label: obj.DIVISION_NAME, value: obj.DIVISION_CODE }));
                setDataListofDivisions(newDivisions);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const getLocations = () => {
        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        };

        axios.get(`${baseUrlAPI()}/location/get-list`, config)
            .then((res) => {
                const response = res.data.data;
                let newLocations = response.map((obj) => ({ label: obj.LOCATION_NAME, value: obj.LOCATION_CODE }));
                setDataListofLocations(newLocations);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const getJobLevels = () => {
        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        };

        axios.get(`${baseUrlAPI()}/job-level/get-list`, config)
            .then((res) => {
                const response = res.data.data;
                let newJobLevel = response.map((obj) => ({ label: obj.JOB_LEVEL_NAME, value: obj.JOB_LEVEL_CODE }));
                setDataListofJobLevel(newJobLevel);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const validateFormData = () => {
        const requiredFields = [
            { field: 'position', title: 'Job Title' },
            { field: 'employment_type', title: 'Type of Employment' },
            { field: 'division_code', title: 'Division/Department' },
            { field: 'due_date', title: 'Due Date Vacancy' },
            { field: 'location_code', title: 'Working Location' },
            { field: 'status_code', title: 'Status' },
            { field: 'job_level_code', title: 'Job Level' },
            { field: 'description', title: 'Job Description' },
            { field: 'requirement', title: 'Job Requirement' }
        ];

        for (let { field, title } of requiredFields) {
            if (!formData[field]) {
                toast.error(`Please fill in the ${title}`);
                return false;
            }
        }
        return true;
    };

    const handleSaveConfirmation = () => {
        if (!validateFormData()) {
            return;
        }

        Swal.fire({
            title: 'Do you want to add new job?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                handleSave();
            }
        });
    };

    const handleSave = () => {
        setLoadingSaveAsDraft(true);

        let config = {
            mode: 'no-cors',
            method: 'POST',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        };

        axios.post(`${baseUrlAPI()}/job/add`, formData, config)
            .then(response => {
                history.push(`/career/jobs`);
                Swal.fire(
                    'Success',
                    'Add New Job',
                    'success'
                );
            })
            .catch(error => {
                console.error('Error adding job:', error);
            })
            .finally(() => {
                // setLoadingSaveAsDraft(false);
            });
    };

    const handleInputNumberOfPosition = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleClickBack = () => {
        history.push(`/career/jobs`);
    };

    const handleInputStatus = (name, selectedName, code, selectedCode) => {
        setFormData({
            ...formData,
            [name]: selectedName.label,
            [code]: selectedCode.value
        });
    };

    const dataSelectStatus = [
        { label: "Active", value: "Active" },
        { label: "Inactive", value: "Inactive" }
    ];

    const handleInputTypeOfEmployment = (name, selectedOptions) => {
        setFormData({
            ...formData,
            [name]: selectedOptions.value
        });
    };

    const dataSelectTypeOfEmployment = [
        { label: "Permanent", value: "Permanent" },
        { label: "Contract", value: "Contract" },
        { label: "Part Time", value: "Part Time" }
    ];

    const handleInputDivisions = (name, selectedName, code, selectedCode) => {
        setFormData({
            ...formData,
            [name]: selectedName.label,
            [code]: selectedCode.value
        });
    };

    const handleInputLocations = (name, selectedName, code, selectedCode) => {
        setFormData({
            ...formData,
            [name]: selectedName.label,
            [code]: selectedCode.value
        });
    };

    const handleInputJobLevel = (name, selectedName, code, selectedCode) => {
        setFormData({
            ...formData,
            [name]: selectedName.label,
            [code]: selectedCode.value
        });
    };

    return (
        <div className="container font-family-spoqa">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-start my-2">
                        <button className="btn px-0" onClick={handleClickBack}>
                            <div className="font-family-spoqa mb-0 font-size-20" style={{ color: '#f58220' }}>
                                <FontAwesomeIcon icon="arrow-left" color="#f58220" /> Back to Job List
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <Row className="font-family-spoqa">
                <Col md={8} className="mb-4">
                    <div className='font-family-spoqa mb-0 font-size-30 font-weight-bold' id="documenttestApp.document.home.createOrEditLabel">
                        Add New Job
                    </div>
                </Col>
            </Row>
            <Row className="font-family-spoqa justify-content-center">
                <Col md={12}>
                    <div className="mb-4">
                        <div className="">
                            <div className="row my-3 ">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-2" style={{ alignSelf: 'center' }}>
                                            <div className='font-weight-bold'
                                                style={{ display: 'flex', alignSelf: 'center' }}>
                                                Job Title
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <textarea className='form-control' rows={1} style={{ resize: 'none' }} value={formData.position}
                                                disabled={false}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= 250) {
                                                        setFormData({
                                                            ...formData,
                                                            position: e.target.value
                                                        });
                                                    } else {
                                                        setFormData({
                                                            ...formData,
                                                            position: e.target.value.slice(0, 250)
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10" style={{ alignSelf: 'center' }}>
                                            <div className='font-weight-bold'
                                                style={{ display: 'flex', alignSelf: 'center' }}>
                                                Type of Employment
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <Select
                                                name="employmentType"
                                                value={dataSelectTypeOfEmployment.filter(option => option.value === formData.employment_type)}
                                                isDisabled={false}
                                                placeholder="Select .."
                                                options={dataSelectTypeOfEmployment}
                                                onChange={(e) => handleInputTypeOfEmployment('employment_type', e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row my-3">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10" style={{ alignSelf: 'center' }}>
                                            <div className='font-weight-bold'
                                                style={{ display: 'flex', alignSelf: 'center' }}>
                                                Division/Department
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <Select
                                                name="division"
                                                value={dataListofDivisions.filter(option => option.value === formData.division_code)}
                                                isDisabled={false}
                                                placeholder="Select .."
                                                options={dataListofDivisions}
                                                onChange={(e) => handleInputDivisions('division_name', e, 'division_code', e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10" style={{ alignSelf: 'center' }}>
                                            <div className='font-weight-bold'
                                                style={{ display: 'flex', alignSelf: 'center' }}>
                                                Due Date Vacancy
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <DatePicker
                                                id={"selectedDate"}
                                                disabled={false}
                                                minDate={new Date()}
                                                selected={selectedDate}
                                                dateFormat="dd MMMM yyyy"
                                                scrollableYearDropdown
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                onChange={(val) => {
                                                    const date = moment(val).format("YYYY-MM-DD");
                                                    setSelectedDate(val);
                                                    setFormData({
                                                        ...formData,
                                                        due_date: date
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row my-3">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10" style={{ alignSelf: 'center' }}>
                                            <div className='font-weight-bold'
                                                style={{ display: 'flex', alignSelf: 'center' }}>
                                                Working Location
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <Select
                                                name="location"
                                                value={dataListofLocations.filter(option => option.value === formData.location_code)}
                                                isDisabled={false}
                                                placeholder="Select .."
                                                options={dataListofLocations}
                                                onChange={(e) => handleInputLocations('location_name', e, 'location_code', e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10" style={{ alignSelf: 'center' }}>
                                            <div className='font-weight-bold'
                                                style={{ display: 'flex', alignSelf: 'center' }}>
                                                Status
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <Select
                                                name="statusCode"
                                                value={dataSelectStatus.filter(option => option.value === formData.status_code)}
                                                isDisabled={false}
                                                placeholder="Select .."
                                                options={dataSelectStatus}
                                                onChange={(e) => handleInputStatus('status_name', e, 'status_code', e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row my-3">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10" style={{ alignSelf: 'center' }}>
                                            <div className='font-weight-bold'
                                                style={{ display: 'flex', alignSelf: 'center' }}>
                                                Number of Position Available
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <input className='form-control'
                                                disabled={false}
                                                type='number'
                                                onChange={(e) => handleInputNumberOfPosition('number_of_position', parseInt(e.target.value) < 1 ? 1 : parseInt(e.target.value))}
                                                value={formData.number_of_position} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10" style={{ alignSelf: 'center' }}>
                                            <div className='font-weight-bold'
                                                style={{ display: 'flex', alignSelf: 'center' }}>
                                                Job Level
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <Select
                                                name="jobLevel"
                                                value={dataListofJobLevel.filter(option => option.value === formData.job_level_code)}
                                                isDisabled={false}
                                                placeholder="Select .."
                                                options={dataListofJobLevel}
                                                onChange={(e) => handleInputJobLevel('job_level_name', e, 'job_level_code', e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row my-3">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10" style={{ alignSelf: 'center' }}>
                                            <div className='font-weight-bold'
                                                style={{ display: 'flex', alignSelf: 'center' }}>
                                                <input
                                                    className='mr-2'
                                                    type="checkbox"
                                                    checked={formData.priority}
                                                    onChange={handleCheckboxChange}
                                                />
                                                Priority Recruitment
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row my-3 mt-4">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10" style={{ alignSelf: 'center' }}>
                                            <div className="font-weight-bold" style={{ display: 'flex', alignSelf: 'center' }}>
                                                <h3>Job Description</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <Editor
                                        // apiKey=""
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        value={formData.description}
                                        onEditorChange={handleUpdate}
                                        init={TinyMCEConfig}
                                    />
                                </div>
                            </div>

                            <div className="row my-3">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10" style={{ alignSelf: 'center' }}>
                                            <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                                                <h3>Job Requirement</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <Editor
                                        // apiKey="p3ztxkfuzd1sbytlgg7x82tndij4ryhgsq039ntgj0hrckwq"
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        value={formData.requirement}
                                        onEditorChange={(content) => handleInput("requirement", content)}
                                        init={TinyMCEConfig}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="float-right">
                        <Button onClick={handleSaveConfirmation} name="next" color="primary" id="save-entity" type="submit" className='pb-2' disabled={loadingSaveAsDraft || loadingNextStep}>
                            <span className='mr-1'>
                                {loadingSaveAsDraft ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                                Save Job
                            </span>
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});

const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(JobsAdd);
